import { ADD_ADHERENT_FAIL, ADD_ADHERENT_FAIL_DESC, ADD_ADHERENT_SUCCESS, DELETE_ADHERENT, FETCH_ADHERENTS, FETCH_CURRENT_ADHERENT, UPDATE_ADHERENT_FAIL, UPDATE_ADHERENT_FAIL_DESC, UPDATE_ADHERENT_SUCCESS } from "../actions/AdherentActions";

  
  const defaultState = {};
  
  export default function (state = defaultState, action) {

    const { type, payload } = action;
    
    switch (type) {

      case FETCH_ADHERENTS:
        return {
          ...state,
          adherents: action.adherents
        };

      case FETCH_CURRENT_ADHERENT:
        return {
          ...state,
          currentAdherent: action.currentAdherent
        };

      case ADD_ADHERENT_SUCCESS:
        return {
          ...state,
          currentAdherent: action.currentAdherent
        };
      
      case ADD_ADHERENT_FAIL:
        return {
          ...state,
          addAdherentErrors: action.addAdherentErrors
        };
      
      case ADD_ADHERENT_FAIL_DESC:
        return {
          ...state,
          addAdherentErrorDesc: action.addAdherentErrorDesc
        };


      case UPDATE_ADHERENT_SUCCESS:
        return {
          ...state,
          currentAdherent: action.currentAdherent
        };

      case UPDATE_ADHERENT_FAIL:
        return {
          ...state,
          updateAdherentErrors: action.updateAdherentErrors
        };

      case UPDATE_ADHERENT_FAIL_DESC:
        return {
          ...state,
          updateAdherentErrorsDesc: action.updateAdherentErrorsDesc
        };

      case DELETE_ADHERENT:
            const adherentUpdated= state.adherents.filter(c  => c.id != action.id);
  
          return {
            ...state,
            adherents: adherentUpdated,
          };
        

      default:
        return state;
    }
  }
  