import Axios from "axios";
import authHeader from "../../helpers/auth-header";
import { ORGANISMES_API } from "../../services/config";

export const FETCH_ORGANISMES= "FETCH_ORGANISMES";
export const ADD_ORGANISME_FAIL= "ADD_ORGANISME_FAIL";
export const ADD_ORGANISME_FAIL_DESC= "ADD_ORGANISME_FAIL_DESC";
export const UPDATE_ORGANISME_FAIL= "UPDATE_ORGANISME_FAIL";
export const UPDATE_ORGANISME_FAIL_DESC= "UPDATE_ORGANISME_FAIL_DESC";

export const getOrganismes = () => (dispatch) => {

  return Axios.get(ORGANISMES_API, { headers: authHeader() }).then(
    (data) => {
      
      dispatch({
        type: FETCH_ORGANISMES,
        organismes: data.data['hydra:member']
              });
      return Promise.resolve();
    },
    (error) => {

          throw new Error(error);
     
    }    
  );
  

};

export const addOrganisme = (organisme, filelogo, filecachet) => (dispatch) => {
  
    let formData = new FormData();
    formData.append('nom', organisme.nom);
    formData.append('president', organisme.president);
    formData.append('site', organisme.site);
    formData.append('email', organisme.email);

    if(organisme.chargilyEnabled){
      if(organisme.chargilyEnabled== true || organisme.chargilyEnabled== "on" || organisme.chargilyEnabled== "true"){
        formData.append('chargilyEnabled', "true");
      } else {
        formData.append('chargilyEnabled', "false");
      }
    } else {
      formData.append('chargilyEnabled', "false");
    }

    formData.append('chargilyMode', organisme.chargilyMode);
    formData.append('chargilyPublicKey', organisme.chargilyPublicKey);
    formData.append('chargilySecretKey', organisme.chargilySecretKey);
    
    if(filelogo && filelogo !="handleDelete"){
      formData.append('filelogo', filelogo);
    }

    if(filecachet && filecachet !="handleDelete"){
      formData.append('filecachet', filecachet);
    }

  return Axios.post(ORGANISMES_API, formData,{ headers: authHeader() }).then(
    (data) => {

      dispatch({
        type: ADD_ORGANISME_FAIL,
        addOrganismeErrors: null
              });
      return Promise.resolve();
    },
    (error) => {

      const message =error.response.data;
      const {violations} = message;
      const errorDescription = message["hydra:description"];
      if(violations){

        dispatch({
          type: ADD_ORGANISME_FAIL,
          addOrganismeErrors: violations
                });
                throw new Error(error);
      } else if(errorDescription){
        dispatch({
          type: ADD_ORGANISME_FAIL_DESC,
          addOrganismeErrorDesc: errorDescription
                });
                throw new Error(error);
      } else{
        throw new Error(error)
      }
     
    }    
  );

};

export const updateOrganisme = (id, organisme, filelogo, filecachet) => (dispatch) => {

    let formData = new FormData();
    formData.append('nom', organisme.nom);
    formData.append('president', organisme.president);
    formData.append('site', organisme.site);
    formData.append('email', organisme.email);

    if(organisme.chargilyEnabled){
      if(organisme.chargilyEnabled== true || organisme.chargilyEnabled== "on" || organisme.chargilyEnabled== "true"){
        formData.append('chargilyEnabled', "true");
      } else {
        formData.append('chargilyEnabled', "false");
      }
    } else {
      formData.append('chargilyEnabled', "false");
    }

    formData.append('chargilyMode', organisme.chargilyMode);
    formData.append('chargilyPublicKey', organisme.chargilyPublicKey);
    formData.append('chargilySecretKey', organisme.chargilySecretKey);

    if(filelogo && filelogo !="handleDelete"){
      formData.append('filelogo', filelogo);
    }

    if(filecachet && filecachet !="handleDelete"){
      formData.append('filecachet', filecachet);
    }

  return Axios.post(ORGANISMES_API + "/" + id, formData,{ headers: authHeader() }).then(
    (data) => {
      
      dispatch({
        type: UPDATE_ORGANISME_FAIL,
        updateOrganismeErrors: null
              });
      return Promise.resolve();
    },
    (error) => {
  
      const message =error.response.data;
      const {violations} = message;
      const errorDescription = message["hydra:description"];
      if(violations){
  
        dispatch({
          type: UPDATE_ORGANISME_FAIL,
          updateOrganismeErrors: violations
                });
                throw new Error(error);
      } else if(errorDescription){
        dispatch({
          type: UPDATE_ORGANISME_FAIL_DESC,
          updateOrganismeErrorDesc: errorDescription
                });
                throw new Error(error);
      } else{
        throw new Error(error)
      }
     
    }    
  );
  
  };

  export const clearMsgAddOrganisme = () => (dispatch) => {

    dispatch({
      type: ADD_ORGANISME_FAIL,
      addOrganismeErrors: null
            });
  
    dispatch({
      type: ADD_ORGANISME_FAIL_DESC,
      addOrganismeErrorDesc: null
            });
  
    dispatch({
      type: UPDATE_ORGANISME_FAIL,
      updateOrganismeErrors: null
            });
        
    dispatch({
      type: UPDATE_ORGANISME_FAIL_DESC,
      updateOrganismeErrorsDesc: null
            });
  
    return Promise.resolve();
  
  };

  export const deleteImageLogoOrganisme = (organisme) => (dispatch) => {

    let formData = new FormData();
    formData.append('id', organisme);

  return Axios.post(ORGANISMES_API + "/delete_image_logo", formData,{ headers: authHeader() }).then(
    (data) => {
      return Promise.resolve();
    },
    (error) => {

        throw new Error(error)
    }    
  );

};

export const deleteImageCachetOrganisme = (organisme) => (dispatch) => {

  let formData = new FormData();
  formData.append('id', organisme);

return Axios.post(ORGANISMES_API + "/delete_image_cachet", formData,{ headers: authHeader() }).then(
  (data) => {
    return Promise.resolve();
  },
  (error) => {

      throw new Error(error)
  }    
);

};
