// @flow 
import React,{useState, useEffect, useRef, useLayoutEffect} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, useParams } from 'react-router-dom';
import { Col, Container, Row, Button, Modal  } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Field from '../../components/Forms/Field';
import swal from 'sweetalert';
import { getImagePartenaireLink } from '../../../helpers/ImageHelpers';
import { addPartenaire, updatePartenaire, clearMsgAddPartenaire, deleteImagePartenaire } from '../../../store/actions/PartenaireActions';
import { getEventById } from '../../../store/actions/EvenementActions';

const Partenaire = ({ addPartenaire, 
    updatePartenaire,
    addPartenaireErrors, 
    addPartenaireErrorsDesc, 
    updatePartenaireErrors, 
    updatePartenaireDesc,
    clearMsgAddPartenaire, 
    userId,
    getEventById,
    currentEvenement,
    deleteImagePartenaire,
    currentUser
    }) => {

    const [partenaire, setPartnaire]= useState({});
    const [editing, setEditing]= useState(false); 
    const [loading, setLoading]= useState(true);
    const [file, setFile]= useState();
    const [confirmationVisible, setConfirmationVisible]= useState();
    const [partenaireForDelete, setPartenaireForDelete]= useState();

    const [credentials, setCredentials]= useState({
        nom: "",
        remerciements: "",
        image: ""
      })

    const [errors, setErrors]= useState({
        nom: "",
        remerciements: "",
        image: ""
    });

    const params = useParams();
    let {id}= params;

    //Get types au chargement
    useEffect( () => {
        if(id){
            getEventById(id);
        }
    }, [id] )

    useEffect( () => {

        if(currentEvenement && currentEvenement.partenaire && currentEvenement.partenaire.id){
            setLoading(true);
            setCredentials(currentEvenement.partenaire);
            setPartnaire(currentEvenement.partenaire);
            setEditing(true);
            setLoading(false)
        } else {
            setEditing(false);
            setLoading(false)
        }

    }, [currentEvenement] )

    useEffect( () => {
        if(addPartenaireErrors){
               const apiErrors={};
               const violations=addPartenaireErrors;
               if(violations && Array.isArray(violations)){
                violations.forEach(({propertyPath, message}) => { 
                  apiErrors[propertyPath]= message;
                  } );
                  setErrors(apiErrors);
               }
                      
        }
      
      }, [addPartenaireErrors])

    useEffect( () => {
        if(addPartenaireErrorsDesc){
            swal("Impossible d'enregistrer le partenaire", addPartenaireErrorsDesc, "error")
        }
      
      }, [addPartenaireErrorsDesc])
 

    const onSignup = async (e) =>{
        e.preventDefault();

        setErrors({});
        clearMsgAddPartenaire();
        credentials.evenement= "/api/evenements/" + id;

            if(editing){

                try {
                    await updatePartenaire(partenaire.id, credentials, file);
                    //if response
                    setErrors({});
                    swal("Le partenaire est à jour", "success").then(() => {
                        window.location.reload();
                    });
                }   catch (err) {
                    toast.error("Une erreur est survenue !");
                }

            } else{
                try {
                    await addPartenaire(credentials, file);
                    //if response
                    setErrors({});
                    swal("Le partenaire est bien enregistré", "success").then(() => {
                        window.location.reload();
                    });
                }   catch (err) {
                    toast.error("Une erreur est survenue !");
    
                }
            }

    }

    //Gestion des champs
    const handleChangeFields = ({currentTarget}) =>{
        const {value, name}= currentTarget;
        if(name === "file"){
            setFile(document.querySelector('input[type="file"]').files[0]);
        }
        else{
            setCredentials({ ...credentials, [name]: value });
        }
    }

    useLayoutEffect(() => {
        return () => {
               setErrors({});
               clearMsgAddPartenaire();
               setEditing(false);
        }
    }, [])

    const handleDeleteImage = (item) => {
        if(currentUser.roles[0]!=="ROLE_ADMIN"){
            toast.error("Vous n'avez pas l'autorisation nécéssaire");
        }else{
            setPartenaireForDelete(partenaire.id);
            setConfirmationVisible(true);
        }
    }

    const handleDeleteImageConfirm= async () => {
        setConfirmationVisible(false)
        try {
            await deleteImagePartenaire(partenaire.id);
            swal("L'image est supprimée", "success").then(() => {
                window.location.reload();
            });
        }   catch (err) {
            toast.error("Une erreur est survenue !");
        }
    }


    return (
        <>
            <div className="row page-titles">
                <ol className="breadcrumb">
                </ol>
            </div>

            <div className="row tableRow ajoutInvite">
                <div className="col-lg-12">

                    <div className="card">

                        <div className="card-header">
                            <h4 className="card-title">
                                Veuillez fournir les informations concernant l'organisme d'accueil
                             </h4>

                        </div>

                    </div>

                </div>

                <div className='eventPage'>

        <div className="row">
                        <div className="col-lg-12">
                            <div className="card">

                                <div className="eventPageFragment">
                                    {!loading &&
                                    <Container>

                                        <Row>

                                            <Col md="12">
                                            <form onSubmit={onSignup}>
                                                
                                                <Row>

                                                <Col md="6">
                                                    <Field
                                                        name="nom"
                                                        value={credentials.nom}
                                                        onChange={handleChangeFields}
                                                        label= "Nom court (Sans Le/La/L'....) - visible sur la section 2 du badge"
                                                        placeholder="Nom"
                                                        error={errors.nom}
                                                        type="text" 
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    <Field
                                                        name="remerciements"
                                                        value={credentials.remerciements}
                                                        onChange={handleChangeFields}
                                                        label= "Remerciements"
                                                        placeholder="Remerciements"
                                                        error={errors.remerciements}
                                                        type="text"
                                                        />
                                                </Col>

                                                <Col md="6">
                                                <div class="uploader">
              

                                                    <label for="file-upload" id="file-drag">
                                                        <div id="start">
                                                        {credentials.image &&
                                                            <>
                                                                <Link to={"#"} className="btn btn-danger shadow btn-md sharp me-1"
                                                                onClick={() => handleDeleteImage()}>
                                                                <i className="fa fa-trash fa-lg"></i>
                                                                </Link>
                                                                <img src={getImagePartenaireLink(credentials.image)} className="svgImage" />
                                                            </>
                                                        }
                                                        
                                                            <div className="importText">
                                                                {!credentials.image ? <p>Importez le logo</p>
                                                                :
                                                                <>
                                                                    <p>Cliquez sur choisir un fichier pour changer de logo</p>
                                                                </>
                                                                }
                                                            </div>
                                                                <input 
                                                                type="file" 
                                                                id="file" 
                                                                name="file" 
                                                                accept="image/*" 
                                                                onChange={handleChangeFields} />

                                                        </div>
                                                    </label>
                                                 </div>
                                                </Col>

                                                </Row>

                                                <div className="text-center">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary btn-lg">
                                                        Enregistrer
                                                    </button>
                                                </div>
                                            </form>
                                            </Col>

                                        </Row>

                                    </Container>
                                    }

                                    {/* <!-- Modal --> */}
                                    <Modal className="fade" show={confirmationVisible}>
                                    <Modal.Header>
                                        <Modal.Title>Supression de l'image</Modal.Title>
                                        <Button
                                            variant=""
                                            className="close"
                                        >
                                            <span>&times;</span>
                                        </Button>
                                    </Modal.Header>
                                    <Modal.Body>êtes-vous sûr de vouloir continuer ?</Modal.Body>
                                    <Modal.Footer>
                                        <Button
                                            onClick={() => setConfirmationVisible(false)}
                                            variant="danger light"
                                        >
                                            Annuler
                                        </Button>
                                        <Button variant="primary"  onClick={() => handleDeleteImageConfirm(partenaireForDelete)}>
                                            Continuer
                                        </Button>
                                    </Modal.Footer>
                                    </Modal>
                                    
                                    
                                </div>

                            </div>
                        </div>
        </div>

        </div>

            </div>

        </>
    );
};

const mapStateToProps = (state) => {
    return {
        currentEvenement: state.evenementState.currentEvenement,
        addPartenaireErrors: state.partenaireState.addPartenaireErrors,
        addPartenaireErrorsDesc: state.partenaireState.addPartenaireErrorsDesc,
        userId: state.auth.user.id,
        currentUser: state.auth.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {addPartenaire, 
        updatePartenaire, 
        clearMsgAddPartenaire,
        getEventById,
        deleteImagePartenaire},
        dispatch,
    );
};


export default connect(mapStateToProps, mapDispatchToProps)(Partenaire); 