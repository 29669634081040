// @flow 
import React,{useState, useEffect, useRef, useLayoutEffect} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Navigate, Link, useParams, useLocation } from 'react-router-dom';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import {getAdherentById, addAdherent, updateAdherent, clearMsgAddAdherent} from '../../../store/actions/AdherentActions';
import { toast } from 'react-toastify';
import Field from '../../components/Forms/Field';
import swal from 'sweetalert';
import { formatDateJs } from '../../../helpers/FormatHelpers';

const Adherent = ({addAdherent, 
    updateAdherent, 
    clearMsgAddAdherent, 
    getAdherentById,
    userId,
    currentAdherent,
    addAdherentErrors,
    addAdherentErrorsDesc,
    updateAdherentErrors,
    updateAdherentErrorsDesc
    }) => {

    const [editing, setEditing]= useState(false); 
    const [loading, setLoading]= useState(true);

    const [credentials, setCredentials]= useState({
        nom: "",
        prenom: "",
        tel: null,
        email: "",
        adresse: "",
        montantCotisation: null,
        dateDebutAdhesion: null,
        dateFinAdhesion: null,
      })

    const [errors, setErrors]= useState({
        nom: "",
        prenom: "",
        tel: "",
        email: "",
        adresse: "",
        montantCotisation: "",
        dateDebutAdhesion: "",
        dateFinAdhesion: ""
    });

    const params = useParams();
    let {id}= params;
    let {idAdherent= "new"}= params;

    //Get types au chargement
    useEffect( () => {
        if(idAdherent != "new"){
            setLoading(true);
            setEditing(true);
            getAdherentById(idAdherent);
            setLoading(false);
        }else{
            setEditing(false);
            setLoading(false);
        }
    }, [idAdherent] )

    useEffect( () => {

        const fetchData = async () => 
        {
            if(currentAdherent && idAdherent != "new"){
                setLoading(true);
                setCredentials({...currentAdherent,
                    dateDebutAdhesion: currentAdherent.date_debut_adhesion ? formatDateJs(currentAdherent.date_debut_adhesion): null,
                    dateFinAdhesion: currentAdherent.date_fin_adhesion ? formatDateJs(currentAdherent.date_fin_adhesion): null,
                    montantCotisation: currentAdherent.montant_cotisation ? currentAdherent.montant_cotisation : null});
            }
            setLoading(false);
        }

        fetchData();
        
    }, [currentAdherent] )

    useEffect( () => {
        if(addAdherentErrors){
               const apiErrors={};
               const violations=addAdherentErrors;
               if(violations && Array.isArray(violations)){
                violations.forEach(({propertyPath, message}) => { 
                  apiErrors[propertyPath]= message;
                  } );
                  setErrors(apiErrors);
               }
                      
        }
      
      }, [addAdherentErrors])

    useEffect( () => {
        if(addAdherentErrorsDesc){
            swal("Impossible d'ajouter l'adherent", addAdherentErrorsDesc, "error")
        }
      
      }, [addAdherentErrorsDesc])

    useEffect( () => {
        if(updateAdherentErrors){
               const apiErrors={};
               const violations=updateAdherentErrors;
               if(violations && Array.isArray(violations)){
                violations.forEach(({propertyPath, message}) => { 
                  apiErrors[propertyPath]= message;
                  } );
                  setErrors(apiErrors);
               }
                      
        }
      
      }, [updateAdherentErrors])

    useEffect( () => {
        if(updateAdherentErrorsDesc){
            swal("Impossible de mettre à jour l'adherent", updateAdherentErrorsDesc, "error")
        }
      
      }, [updateAdherentErrorsDesc])
 
    const onSignup = async (e) =>{
        e.preventDefault();

        setErrors({});
        clearMsgAddAdherent();

            if(editing){

                try {

                    let adherent= credentials;
                    await updateAdherent(adherent.id, adherent);
                    //if response
                    setErrors({});
                    swal("L'adherent est à jour", "success");
                }   catch (err) {
                    toast.error("Une erreur est survenue !");
                }

            } else{
                try {

                    let adherent= credentials;
                    await addAdherent(adherent);
                    //if response
                    setErrors({});
                    swal("L'adherent est bien enregistré", "success");
                }   catch (err) {
                    toast.error("Une erreur est survenue !");
    
                }
            }

    }

    //Gestion des champs
    const handleChangeFields = ({currentTarget}) =>{
        const {value, name}= currentTarget;
        setCredentials({ ...credentials, [name]: value });
    }

    useLayoutEffect(() => {
        return () => {
               setErrors({});
               clearMsgAddAdherent();
               setEditing(false);
        }
    }, [])


    return (
        <>
            <div className="row page-titles">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active"><Link to={"/" + id + "/admin/adherents"}> Adherents</Link></li>
                    <li className="breadcrumb-item active"><Link to={"#"}> {editing ? "Modification": "Ajout"} </Link></li>
                </ol>
            </div>

            <div className="row tableRow ajoutInvite">
                <div className="col-lg-12">

                    <div className="card">

                        <div className="card-header">
                            <h4 className="card-title">
                            {editing ? "Modification | "
                            : "Ajout d'adherent"}

                            {editing && !loading && currentAdherent &&
                            currentAdherent.nom}

                             </h4>

                                <Link to={"/" + id + "/admin/adherents"} className="btn btn-primary">
                                    <i class="fas fa-undo"  style={{ marginRight: "5px",}}></i>  
                                    Retour à la liste
                                </Link>

                        </div>

                    </div>

                </div>

                <div className='eventPage'>

        <div className="row">
                        <div className="col-lg-12">
                            <div className="card">

                                <div className="eventPageFragment">
                                    {!loading &&
                                    <Container>

                                        <Row>

                                            <Col md="12">
                                            <form onSubmit={onSignup}>
                                                
                                                <Row>

                                                <Col md="6">
                                                    <Field
                                                        name="prenom"
                                                        value={credentials.prenom}
                                                        onChange={handleChangeFields}
                                                        label= "Prénom *"
                                                        placeholder="Prénom"
                                                        error={errors.prenom}
                                                        type="text"
                                                        required="true" 
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    <Field
                                                        name="nom"
                                                        value={credentials.nom}
                                                        onChange={handleChangeFields}
                                                        label= "Nom *"
                                                        placeholder="Nom"
                                                        error={errors.nom}
                                                        type="text"
                                                        required="true" 
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    <Field
                                                        name="tel"
                                                        value={credentials.tel}
                                                        onChange={handleChangeFields}
                                                        label= "Tel"
                                                        placeholder="Tel"
                                                        error={errors.tel}
                                                        type="numeric"
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    <Field
                                                        name="email"
                                                        value={credentials.email}
                                                        onChange={handleChangeFields}
                                                        label= "Email"
                                                        placeholder="Email"
                                                        error={errors.email}
                                                        type="text"
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    <Field
                                                        name="adresse"
                                                        value={credentials.adresse}
                                                        onChange={handleChangeFields}
                                                        label= "Adresse"
                                                        placeholder="Adresse"
                                                        error={errors.adresse}
                                                        type="text"
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    <Field
                                                        name="dateDebutAdhesion"
                                                        value={credentials.dateDebutAdhesion}
                                                        onChange={handleChangeFields}
                                                        label= "Date de début adhésion"
                                                        placeholder="Date de début d'adhésion"
                                                        error={errors.dateDebutAdhesion}
                                                        type="date" 
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    <Field
                                                        name="dateFinAdhesion"
                                                        value={credentials.dateFinAdhesion}
                                                        onChange={handleChangeFields}
                                                        label= "Date fin adhésion"
                                                        placeholder="Date din d'adhésion"
                                                        error={errors.dateFinAdhesion}
                                                        type="date" 
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    <Field
                                                        name="montantCotisation"
                                                        value={credentials.montantCotisation}
                                                        onChange={handleChangeFields}
                                                        label= "Dernier montant cotisation"
                                                        placeholder="Dernier montant cotisation"
                                                        error={errors.montantCotisation}
                                                        type="numeric"
                                                        />
                                                </Col>

                                                </Row>

                                                <div className="text-center">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary btn-lg"
                                                    >
                                                        {editing ? "Enregistrer" : "Ajouter"}
                                                    </button>
                                                </div>
                                            </form>
                                            </Col>

                                        </Row>

                                    </Container>
                                    }
                                    
                                    
                                </div>

                            </div>
                        </div>
        </div>

        </div>

            </div>

        </>
    );
};

const mapStateToProps = (state) => {
    return {
        addAdherentErrors: state.adherentState.addAdherentErrors,
        addAdherentErrorsDesc: state.adherentState.addAdherentErrorsDesc,
        updateAdherentErrors: state.adherentState.updateAdherentErrors,
        updateAdherentErrorsDesc: state.adherentState.updateAdherentErrorsDesc,
        userId: state.auth.user.id,
        currentAdherent: state.adherentState.currentAdherent
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {addAdherent, 
        updateAdherent, 
        clearMsgAddAdherent, 
        getAdherentById},
        dispatch,
    );
};


export default connect(mapStateToProps, mapDispatchToProps)(Adherent); 