// @flow 
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { Link, Navigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import logo from "../../../../images/logo-forum.png";
import Field from '../../../components/Forms/Field';
import Select from "../../../components/Forms/Select";
import listeTitres from "../../../../helpers/Titre_medecin.json";  
import HeaderNoAuth from '../../../components/HeaderNoAuth';
import RecaptchaSection from '../../../components/RecaptchaSection';
import swal from 'sweetalert';
import FooterPublic from '../../../layouts/FooterPublic';
import checkoutImage from "../../../../images/chekout-now.svg";
import axios from 'axios';
import { CREATE_CHECKOUT_API } from '../../../../services/config';

const CheckoutProcess = () => {

    const [loading, setLoading]= useState(true);
    const [credentials, setCredentials]= useState({
        emailCheckout: "",
        acceptedTerms: true
      })

    const params = useParams();
    let {email}= params;
    let {id}= params;

    useEffect( () => {
        if(email){
            setCredentials({...credentials,email});    
            setLoading(false);                  
        }
      
    }, [email])

    const handleCheckout = async (e) =>{
        e.preventDefault();

        if(!credentials.acceptedTerms){
            toast.error("Vous devez accepter les termes et conditions pour procéder.");
        } else if(!credentials.email){
            toast.error("Veuillez saisir votre adresse e-mail pour continuer");
        } else {
            try {
                // Faire l'appel API à l'URL de checkout
                const response = await axios.post(CREATE_CHECKOUT_API, { email: credentials.email, idEvenement: id });
        
                // Extraire l'URL à partir de la réponse JSON
                const checkoutUrl = response.data.url;
        
                // Rediriger l'utilisateur vers l'URL de checkout
                window.location.href = checkoutUrl;
            } catch (error) {
                let errorDetails= "";
                if(error && error.response.data && error.response.data.detail){
                    errorDetails= error.response.data.detail;
                    toast.error("Une erreur s'est produite lors du traitement de la commande. " + errorDetails);
                } else {
                    toast.error("Une erreur s'est produite lors du traitement de la commande. Veuillez réessayer plus tard.");
                }
            }
        }
    }

    //Gestion des champs
    const handleChangeFields = ({currentTarget}) =>{
        const {value, name}= currentTarget;

        if(name === "acceptedTerms") {
            setCredentials({ ...credentials, [name]: !credentials[name] });
        } else {
            setCredentials({ ...credentials, [name]: value });
        }
    }

    return (
        <>
            <HeaderNoAuth />

            <div className='checkoutPage eventPage'>

                <div className="row">

                            <div className="col-lg-12">
                                <div className="card">

                                    <div className="card-header">
                                        <h3 className="card-title" style={{textAlign: "center", width: "100%"}}> Payez maintenant </h3>
                                    </div>

                                    <div className="eventPageFragment">
                                            {!loading &&
                                            <Container>

                                                <Row id="containerCheckoutImg">
                                                    <img src={checkoutImage} alt="" className="checkoutImage" id="checkoutImage" />
                                                    <div id="animationChekout">
                                                    <div class="dotCheckout"></div>
                                                    <div class="dotCheckout"></div>
                                                    <div class="dotCheckout"></div>
                                                    </div>
                                                </Row>  

                                                <Row>

                                                    <Col md="6" style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
                                                        <form onSubmit={handleCheckout}>
                                                            
                                                            <Row>
                                                            <Col md="12">
                                                                <Field
                                                                    name="email"
                                                                    value={credentials.email}
                                                                    onChange={handleChangeFields}
                                                                    type="text" 
                                                                    required="true" 
                                                                    />
                                                            </Col>

                                                            <Col md="10" className="acceptanceCheckbox" style={{ display: "flex", flexDirection: "row", width: "90%"}}>
                                                                <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                name="acceptedTerms"
                                                                checked= {credentials.acceptedTerms}
                                                                onChange={handleChangeFields}
                                                                id="acceptedTerms"
                                                                style={{ width: "5%"}}
                                                                />
                                                                <label className="form-check-label" htmlFor="acceptedTerms" style={{ width: "100%", marginLeft: "0"}}>
                                                                    J'accèpte l'enregistrement de mon email sur ce site Web pour la validation du paiement
                                                                </label>
                                                            </Col>
                                                            
                                                            </Row>

                                                            <div className="text-center mt-2">
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-primary btn-lg"
                                                                >
                                                                    Payer
                                                                </button>
                                                            </div>

                                                        </form>
                                                    </Col>

                                                </Row>  

                                            </Container> 
                                            } 
                                    </div>
                                </div>
                            </div>
                </div>

            </div>

            <FooterPublic />

        </>
    );
};

export default CheckoutProcess;