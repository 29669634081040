import { ADD_INSCRIPTION_FAIL, ADD_INSCRIPTION_SUCCESS, UPDATE_INSCRIPTION_FAIL } from "../actions/InscriptionActions";

  
  const defaultState = {};
  
  export default function (state = defaultState, action) {

    const { type, payload } = action;
    
    switch (type) {

      case ADD_INSCRIPTION_SUCCESS:
        return {
          ...state,
          currentInscription: action.currentInscription
        };

      case ADD_INSCRIPTION_FAIL:
        return {
          ...state,
          addInscriptionErrors: action.addInscriptionErrors
        };

      case UPDATE_INSCRIPTION_FAIL:
        return {
          ...state,
          updateInscriptionErrors: action.updateInscriptionErrors
        };


      default:
        return state;
    }
  }
  