// @flow 
import React,{useState, useEffect, useRef, useLayoutEffect} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Navigate, Link, useParams, useLocation } from 'react-router-dom';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { getEventById } from '../../../store/actions/EvenementActions';
import logo from "../../../images/logo-forum.png";
import Field from '../Forms/Field';
import Select from "../Forms/Select";
import { addPresse, updatePresse, clearMsgAddPresse, getPresseById } from '../../../store/actions/PresseActions';
import WilayaOfAlgeria from "../../../helpers/Wilaya_Of_Algeria.json";
import listeSpecialites from "../../../helpers/Liste_specialites.json"; 
import listeTitres from "../../../helpers/Titre_medecin.json";  
import HeaderNoAuth from '../HeaderNoAuth';
import swal from 'sweetalert';
import { printPrePdf, printPrePdfBrowser, downloadPrePdf } from '../../../store/actions/PresseActions';

const Presse = ({getEventById, 
    addPresse, 
    updatePresse, 
    clearMsgAddPresse, 
    getPresseById,
    currentEvenement, 
    userId,
    printPrePdf,
    printPrePdfBrowser,
    downloadPrePdf,
    currentPresse,
    addPresseErrors,
    addPresseErrorsDesc,
    updatePresseErrors,
    updatePresseErrorsDesc
    }) => {

    const [event, setEvent]= useState({});
    const [editing, setEditing]= useState(false); 
    const [loading, setLoading]= useState(true);
    const [isPrintable, setIsprintable]= useState(false);

    const [credentials, setCredentials]= useState({
        nom: "",
        nombreBadges: 1
      })

    const [errors, setErrors]= useState({
        nom: "",
        nombreBadges: ""
    });

    const params = useParams();
    let {id}= params;
    let {idPresse= "new"}= params;

    //Get types au chargement
    useEffect( () => {
        if(idPresse != "new"){
            setEditing(true);
            getPresseById(idPresse);
            setIsprintable(true);
        }else{
            setEditing(false);
        }
    }, [idPresse] )

    useEffect( () => {

        const fetchData = async () => 
        {
            if(currentPresse && idPresse != "new"){
                setLoading(true);
                currentPresse.nombreBadges= currentPresse.nombre_badges;
                setCredentials(currentPresse);
            }
            setLoading(false);
        }

        fetchData();
        
    }, [currentPresse] )

    useEffect( () => {
        if(addPresseErrors){
               const apiErrors={};
               const violations=addPresseErrors;
               if(violations && Array.isArray(violations)){
                violations.forEach(({propertyPath, message}) => { 
                  apiErrors[propertyPath]= message;
                  } );
                  setErrors(apiErrors);
               }
                      
        }
      
      }, [addPresseErrors])

    useEffect( () => {
        if(addPresseErrorsDesc){
            swal("Impossible de vous inscrire", addPresseErrorsDesc, "error")
        }
      
      }, [addPresseErrorsDesc])

    useEffect( () => {
        if(updatePresseErrors){
               const apiErrors={};
               const violations=updatePresseErrors;
               if(violations && Array.isArray(violations)){
                violations.forEach(({propertyPath, message}) => { 
                  apiErrors[propertyPath]= message;
                  } );
                  setErrors(apiErrors);
               }
                      
        }
      
      }, [updatePresseErrors])

    useEffect( () => {
        if(updatePresseErrorsDesc){
            swal("Impossible de vous inscrire", updatePresseErrorsDesc, "error")
        }
      
      }, [updatePresseErrorsDesc])
 
    useEffect( () => {  

        if(currentEvenement){
               setEvent(currentEvenement); 
        } 

     }, [currentEvenement])

    //Récupération d'un event
    const fetchEvent= async id  => {
        try {
               await getEventById(id);
               if(!editing){
                setLoading(false);
               }
        } catch (error) {
               toast.error("Impossible de charger l'évenement");  
               Navigate("/");
        }
    }

    // Chargement de l'event au chargement du composent ou changement de l'id  
    useEffect( () => {
        setLoading(true);
        fetchEvent(id);
    }, [id])

    const onSignup = async (e) =>{
        e.preventDefault();

        setErrors({});
        clearMsgAddPresse();

            if(editing){

                try {

                    let presse= credentials;
                    await updatePresse(idPresse, presse, event, userId);
                    //if response
                    setErrors({});
                    swal("La presse est à jour", "success");
                }   catch (err) {
                    toast.error("Une erreur est survenue !");
                }

            } else{
                try {

                    let presse= credentials;
                    await addPresse(presse, event, userId);
                    //if response
                    setErrors({});
                    swal("La presse est bien enregistrée", "success");
                    setIsprintable(true);
                }   catch (err) {
                    toast.error("Une erreur est survenue !");
    
                }
            }

    }

    //Gestion des champs
    const handleChangeFields = ({currentTarget}) =>{
        const {value, name}= currentTarget;
        setCredentials({ ...credentials, [name]: value });
    }

    useLayoutEffect(() => {
        return () => {
               setErrors({});
               clearMsgAddPresse();
               setEditing(false);
        }
    }, [])

    const handlePrint = async () => {
        try {
                await printPrePdf(currentPresse.id, currentPresse.evenement.id);
        } catch(err){
            toast.error("Erreur lors du chargement du pdf");
        }
        
    };

    const handlePrintBrowser = async () => {
        try {
                await printPrePdfBrowser(currentPresse.id, currentPresse.evenement.id);
        } catch(err){
            toast.error("Erreur lors du chargement du pdf");
        }
    };

    const handleDownload = async () => {
        try {
                await downloadPrePdf(currentPresse.id, currentPresse.evenement.id);
        } catch(err){
            toast.error("Erreur lors du chargement du pdf");
        }
    };

    return (
        <>
            <div className="row page-titles">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active"><Link to={"/" + id + "/admin/presses"}> Presses</Link></li>
                    <li className="breadcrumb-item active"><Link to={"#"}> {editing ? "Modification": "Ajout"} </Link></li>
                </ol>
            </div>

            <div className="row tableRow ajoutInvite">
                <div className="col-lg-12">

                    <div className="card">

                        <div className="card-header">
                            <h4 className="card-title">
                            {editing ? "Modification | "
                            : "Ajout de presse"}

                            {editing && !loading && currentPresse &&
                            currentPresse.nom}

                             </h4>

                                {isPrintable &&
                                <div className="printBtn">
                                    <Link className="btn shadow btn-md sharp me-1 btn-success"
                                    onClick={() => handlePrint()}>
                                        <i class="fas fa-print fa-lg"></i>
                                    </Link>
                                    <Link className="btn btn-primary shadow btn-md sharp me-1" 
                                    onClick={() => handlePrintBrowser()}>
                                        <i class="fas fa-file-pdf fa-lg"></i>
                                    </Link>

                                    <Link className="btn btn-info shadow btn-md sharp me-1" 
                                    onClick={() => handleDownload()}>
                                        <i class="fas fa-file-download fa-lg"></i>
                                    </Link>
                                </div>
                                }

                                <Link to={"/" + id + "/admin/presses"} className="btn btn-primary">
                                    <i class="fas fa-undo"  style={{ marginRight: "5px",}}></i>  
                                    Retour à la liste
                                </Link>

                        </div>

                    </div>

                </div>

                <div className='eventPage'>

        <div className="row">
                        <div className="col-lg-12">
                            <div className="card">

                                <div className="eventPageFragment">
                                    {!loading &&
                                    <Container>

                                        <Row>

                                            <Col md="12">
                                            <form onSubmit={onSignup}>
                                                
                                                <Row>

                                                <Col md="6">
                                                    <Field
                                                        name="nom"
                                                        value={credentials.nom}
                                                        onChange={handleChangeFields}
                                                        label= "Dénomination *"
                                                        placeholder="Dénomination"
                                                        error={errors.nom}
                                                        type="text"
                                                        required="true" 
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    <Field
                                                        name="nombreBadges"
                                                        value={credentials.nombreBadges}
                                                        onChange={handleChangeFields}
                                                        label= "Nombre de badges *"
                                                        placeholder="Nombre de badges"
                                                        error={errors.nombreBadges}
                                                        type="number"
                                                        required="true" 
                                                        />
                                                </Col>

                                                </Row>

                                                <div className="text-center">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary btn-lg"
                                                    >
                                                        {editing ? "Enregistrer" : "Ajouter"}
                                                    </button>
                                                </div>
                                            </form>
                                            </Col>

                                        </Row>

                                    </Container>
                                    }
                                    
                                    
                                </div>

                            </div>
                        </div>
        </div>

        </div>

            </div>

        </>
    );
};

const mapStateToProps = (state) => {
    return {
        evenements: state.evenementState.evenements,
        currentEvenement: state.evenementState.currentEvenement,
        addPresseErrors: state.presseState.addPresseErrors,
        addPresseErrorsDesc: state.presseState.addPresseErrorsDesc,
        updatePresseErrors: state.presseState.updatePresseErrors,
        updatePresseErrorsDesc: state.presseState.updatePresseErrorsDesc,
        userId: state.auth.user.id,
        currentPresse: state.presseState.currentPresse
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        { getEventById, 
        addPresse, 
        updatePresse, 
        clearMsgAddPresse, 
        getPresseById,
        printPrePdf,
        printPrePdfBrowser,
        downloadPrePdf },
        dispatch,
    );
};


export default connect(mapStateToProps, mapDispatchToProps)(Presse); 