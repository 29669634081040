// @flow 
import React,{useState, useEffect, useRef} from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import { toast } from 'react-toastify';
import Pagination from '../PaginationTable';

import WilayaOfAlgeria from "../../../helpers/Wilaya_Of_Algeria.json";
import listeSpecialites from "../../../helpers/Liste_specialites.json";
import { Badge, Button, Col, Container, Modal, Row } from 'react-bootstrap';
import Select from '../Forms/Select';
import { formatDateTime } from '../../../helpers/FormatHelpers';
import { downloadPdf, printPdf, printPdfBrowser } from '../../../store/actions/PdfActions';
import { getSponsors, deleteSponsor } from '../../../store/actions/SponsorActions';
import { printSpoPdf, printSpoPdfBrowser, downloadSpoPdf } from '../../../store/actions/SponsorActions';

const Sponsors = ({getSponsors, 
    sponsors, 
    printSpoPdf, 
    printSpoPdfBrowser, 
    downloadSpoPdf,
    currentPdf, 
    deleteSponsor,
    currentUser
    }) => {

    const [currentPage, setCurrentPage]= useState(1);
    const [search, setSearch]= useState("");
    const [loading, setLoading]= useState(true);
    const [sponsorsListe, setSponsorsListe]= useState([]);
    const [error, setError]= useState();
    const [confirmationVisible, setConfirmationVisible]= useState();
    const [sponsorForDelete, setSponsorForDelete]= useState();
    const [sponsorForDeleteInfo, setSponsorForDeleteInfo]= useState();

    const optionsWilaya = WilayaOfAlgeria;
    const optionsSpecialite= listeSpecialites;

    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    let {id}= params;


    //Get invites
    const fetchSponsors = async () => {
        try {
            getSponsors(id);
            setLoading(false);
        } catch (error) {
            toast.error("Erreur lors du chargement des sponsors");
        }
    }


    useEffect(() => {
        fetchSponsors();
     }, []);

    //Get invites au chargement
    useEffect( () => {
        if(sponsors){
            if(! sponsors.length==0){
                setSponsorsListe(sponsors);
            }
        }
        

    }, [sponsors] )


    //Change current page
    const handleChangePage = page => setCurrentPage(page);
    
    //Recherche
    const handleSearch = ({currentTarget}) => { 
        const value= currentTarget.value;
        setSearch(value);
        setCurrentPage(1);
    }

    // Rénitialiser les filtres
    const resetFilters= () => {
        setSearch("");
    }

    const itemsPerPage =10;
    
    //Filtrage des types en fonction de la recherche
    let filtredSponsors;

    if(sponsorsListe && sponsorsListe.length >0){         
        filtredSponsors= sponsorsListe.filter(
            t =>
            (t.id && t.id.toString().includes(search)) ||
            (t.nom && t.nom.toLowerCase().includes(search.toLowerCase()))
        );
    }

    // Au cas ou le résultat de la recherche serait null
    useEffect( () => {
        if(filtredSponsors && filtredSponsors.length == 0 && search!= ""){
            setError("Désolé, aucun résultat n'a été trouvé");
        }else if (filtredSponsors && filtredSponsors.length>0 ){
            setError();
        }
    }, [filtredSponsors] )
    
    //Pagination
    let paginatedSponsors;
    if (filtredSponsors && filtredSponsors.length >0){
        paginatedSponsors= Pagination.getData(filtredSponsors, currentPage, itemsPerPage);
    }

    const handlePrint = async (idSponsors, idEvenement) => {
        try {
            await printSpoPdf(idSponsors, idEvenement);
        } catch(err){
            toast.error("Erreur lors du chargement du pdf");
        }
    };

    const handlePrintBrowser = async (idSponsors, idEvenement) => {
        try {
            await printSpoPdfBrowser(idSponsors, idEvenement);
        } catch(err){
            toast.error("Erreur lors du chargement du pdf");
        }
    };

    const handleDownload = async (idSponsors, idEvenement) => {
        try {
            await downloadSpoPdf(idSponsors, idEvenement);
        } catch(err){
            toast.error("Erreur lors du chargement du pdf");
        }
    };

    const handleDelete = (item) => {
        if(currentUser.roles[0]!=="ROLE_ADMIN"){
            toast.error("Vous n'avez pas l'autorisation nécéssaire");
        }else{
            setSponsorForDelete(item.id);
            setSponsorForDeleteInfo(item.nom);
            setConfirmationVisible(true);
        }
    }

    const handleDeleteConfirm = async id => {
        setConfirmationVisible(false)
        try {
            await deleteSponsor(id);
            toast.success("Le sponsor a bien été supprimé");
        } catch (error) {
            toast.error("Une erreur est survenue");
        }

    }

    return (
        <>

            <div className="row page-titles">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active"><Link to={"#"}> sponsors</Link></li>
                </ol>
            </div>

            <div className="row tableRow">
                <div className="col-lg-12">

                    <div className="card">

                        <div className="card-header">
                            <h4 className="card-title">Liste des sponsors</h4>
                            <Link to={location.pathname + "/new"} className="btn btn-primary">Ajouter</Link>
                        </div>
                        
                        <div className="card-body">
                            <div className="table-responsive ticket-table">
                                <div id="ticket_wrapper" className="dataTables_wrapper no-footer">
                                    
                                    <Container>
                                        <Row>
                                            <Col md={11}>
                                                <div className="col-12 d-flex align-items-center">
                                                    <label className="me-2 mt-2">Recherche:</label>
                                                    <input 
                                                        className="form-control"
                                                        type="search" 
                                                        placeholder="Dénomination" 
                                                        onChange={handleSearch}
                                                        value={search}
                                                    />
                                                </div>
                                            </Col>

                                            <Col md={1}>

                                                <Button className="btn btn-primary shadow btn-md sharp me-1 restartBtn" onClick={resetFilters}>
                                                    <i class="fas fa-undo"></i>                                                
                                                </Button>
                                            </Col>

                                        </Row>
                                    </Container>
                                        

                                    <table id="example" className="display dataTablesCard table-responsive-xl dataTable no-footer w-100">
                                        
                                    { (paginatedSponsors && paginatedSponsors.length>0) &&
                                        <thead>
                                            <tr>
                                                <th></th>
												<th>Sponsor</th>
												<th>Nom</th>
												<th>Nombre de badges</th>
												<th>Visible sur le badge</th>
												<th>Actions</th>                                          
                                            </tr>
                                        </thead>
                                    }

                                        {!loading && (
                                        <tbody>
                                            {paginatedSponsors &&  paginatedSponsors.map((item, index)=>(
                                                <tr key={index}>

                                                    <td>
                                                        {!(item.nombre_badges == 0) && 
                                                            <Link className="btn shadow btn-md sharp me-1 btn-success"
                                                            onClick={() => handlePrint(item.id, item.evenement.id)}>
                                                                <i class="fas fa-print fa-2x"></i>
                                                            </Link>
                                                        }
                                                    </td>

                                                    <td>
                                                        <span className="badge badge-success badge-lg">{item.id}</span>
                                                    </td>
                                                    
                                                    <td>						
                                                            <span className="badge light badge-success">
                                                                <Link to={"#"} className="h5">{item.nom}</Link>
                                                            </span>
                                                        
                                                    </td>
                                                    <td>{item.nombre_badges}</td>
                                                    <td>
                                                        <a className="btn shadow btn-md sharp me-1 btn-success">
                                                            {item.isVisibleBadge ? 
                                                                item.isVisibleBadge== "true" ?
                                                                <p>Oui</p> :
                                                                <p>Non</p>
                                                            : 
                                                            <p>Non</p>
                                                            }
                                                            
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex">
                                                            { !(item.nombre_badges == 0) && 
                                                                <>
                                                                    <Link className="btn btn-primary shadow btn-md sharp me-1" 
                                                                    onClick={() => handlePrintBrowser(item.id, item.evenement.id)}>
                                                                        <i class="fas fa-file-pdf fa-lg"></i>
                                                                    </Link>

                                                                    <Link className="btn btn-info shadow btn-md sharp me-1" 
                                                                    onClick={() => handleDownload(item.id, item.evenement.id)}>
                                                                        <i class="fas fa-file-download fa-lg"></i>
                                                                    </Link>
                                                                </>
                                                            }

                                                            <Link to={location.pathname + "/" + item.id} className="btn btn-warning shadow btn-md sharp me-1"
                                                            >
                                                                <i className="fas fa-pencil-alt fa-lg"></i>
                                                            </Link>

                                                            <Link to={"#"} className="btn btn-danger shadow btn-md sharp me-1"
                                                            onClick={() => handleDelete(item)}>
                                                                <i className="fa fa-trash fa-lg"></i>
                                                            </Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                            
                                        </tbody>
                                        )}

                                        
                                        
                                    </table>

                                    {filtredSponsors ? filtredSponsors.length > itemsPerPage && ( 
                                        <Pagination currentPage={currentPage} 
                                        itemsPerPage={itemsPerPage} 
                                        length={filtredSponsors.length} 
                                        onPageChanged={handleChangePage} />) 
                                        : null}

                                    {/* <!-- Modal --> */}
                                    <Modal className="fade" show={confirmationVisible}>
                                    <Modal.Header>
                                        <Modal.Title>Supression du sponsor {sponsorForDeleteInfo}</Modal.Title>
                                        <Button
                                            variant=""
                                            className="close"
                                        >
                                            <span>&times;</span>
                                        </Button>
                                    </Modal.Header>
                                    <Modal.Body>êtes-vous sûr de vouloir continuer ?</Modal.Body>
                                    <Modal.Footer>
                                        <Button
                                            onClick={() => setConfirmationVisible(false)}
                                            variant="danger light"
                                        >
                                            Annuler
                                        </Button>
                                        <Button variant="primary"  onClick={() => handleDeleteConfirm(sponsorForDelete)}>
                                            Continuer
                                        </Button>
                                    </Modal.Footer>
                                    </Modal>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: "",
        sponsors: state.sponsorState.sponsors,
        currentPdf: state.pdfState.currentPdf,
        currentUser: state.auth.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {   getSponsors, 
            printSpoPdf, 
            printSpoPdfBrowser, 
            downloadSpoPdf, 
            deleteSponsor},
        dispatch,
    );
};


export default connect(mapStateToProps, mapDispatchToProps)(Sponsors); 