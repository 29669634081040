import { ADD_PARTENAIRE_FAIL, ADD_PARTENAIRE_FAIL_DESC, UPDATE_PARTENAIRE_FAIL, UPDATE_PARTENAIRE_FAIL_DESC } from "../actions/PartenaireActions";

  
  const defaultState = {};
  
  export default function (state = defaultState, action) {

    const { type, payload } = action;
    
    switch (type) {
      
      case ADD_PARTENAIRE_FAIL:
        return {
          ...state,
          addPartenaireErrors: action.addPartenaireErrors
        };
      
      case ADD_PARTENAIRE_FAIL_DESC:
        return {
          ...state,
          addPartenaireErrorDesc: action.addPartenaireErrorDesc
        };

      case UPDATE_PARTENAIRE_FAIL:
        return {
          ...state,
          updatePartenaireErrors: action.updatePartenaireErrors
        };
      
      case UPDATE_PARTENAIRE_FAIL_DESC:
        return {
          ...state,
          updatePartenaireErrorDesc: action.updatePartenaireErrorDesc
        };


      default:
        return state;
    }
  }