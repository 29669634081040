// @flow 
import React, { Fragment, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import PageTitle from '../layouts/PageTitle';

import defaultImg from "../../images/imgEvenetDefault-c.jpg";
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getEvents, selectEvent } from '../../store/actions/EvenementActions';
import { isAuthenticated } from '../../store/selectors/AuthSelectors';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { formatDate, formatDateEvent } from '../../helpers/FormatHelpers';
import { getImageLink } from '../../helpers/ImageHelpers';
import Pagination from '../components/Pagination';
import HeaderNoAuth from '../components/HeaderNoAuth';
import { Navigate, useNavigate } from 'react-router-dom';
import { checkAutoLogin } from '../../services/AuthService';

const EventSelection = ({getEvents, selectEvent, evenements, selectedEventId, isAuthenticated}) => {
    
    const [currentPage, setCurrentPage]= useState(1);
    const [search, setSearch]= useState("");
    const [loading, setLoading]= useState(true);
    const [evenementsListe, setEvenementsListe]= useState([]);
    const [error, setError]= useState();
    const [isCheckedLogin, setIsCheckedLogin]= useState(false);
    const [isDisplayEvent, setIsDisplayEvent]= useState(true);
    const navigate = useNavigate();

    //Get types chambres
    const fetchEvents = async () => {
        try {
            getEvents();
            setLoading(false);
        } catch (error) {
            toast.error("Erreur lors du chargement des événements");
        }
    }


    useEffect(() => {
        fetchEvents();
     }, []);

      //Get types au chargement
    useEffect( () => {
        
        if(evenements){
            if(evenements.length==0){
                setIsDisplayEvent(false);
            }else{
                setEvenementsListe(evenements);
            }
        }
        

    }, [evenements] )

     //Change current page
     const handleChangePage = page => setCurrentPage(page);
    
     //Recherche
     const handleSearch = ({currentTarget}) => { 
         const value= currentTarget.value;
         setSearch(value);
         setCurrentPage(1);
     }
 
     const itemsPerPage =8;
     
     //Filtrage des types en fonction de la recherche
     let filtredEvents;
 
     if(evenementsListe && evenementsListe.length >0){         
          filtredEvents= evenementsListe.filter(
             t =>
             (t.id && t.id.toString().includes(search)) ||
             (t.nom && t.nom.toLowerCase().includes(search.toLowerCase())) ||
             (t.description && t.description.toLowerCase().includes(search.toLowerCase())) ||
             (t.type && t.type.toLowerCase().includes(search.toLowerCase()))
                 
         );
     }
 
     // Au cas ou le résultat de la recherche serait null
     useEffect( () => {
         if(filtredEvents && filtredEvents.length == 0 && search!= ""){
             setError("Désolé, aucun résultat n'a été trouvé");
         }else if (filtredEvents && filtredEvents.length>0 ){
             setError();
         }
     }, [filtredEvents] )
     
     //Pagination
     let paginatedEvents;
     if (filtredEvents && filtredEvents.length >0){
     paginatedEvents= Pagination.getData(filtredEvents, currentPage, itemsPerPage);
     }

     const handleSelect = async (id) => {

        try{
            await selectEvent(id);
        }catch{
            toast.error("Une erreur est survenue !");
        }
     }

    useEffect( () => {
        
        if(selectedEventId){
            navigate("/" + selectedEventId + "/admin");
        }

    }, [selectedEventId] )

    const dispatch = useDispatch();

    useEffect(() => {
        checkAutoLogin(dispatch, navigate);
        setIsCheckedLogin(true);
     }, []);

    useEffect( () => {

        if(isCheckedLogin && !isAuthenticated){
            navigate("/login");
        }

    }, [isCheckedLogin] )

    const handleCreateEvent = () => {
            window.location.href="/new/admin/events/new";
    }

    return (
        <>
        <HeaderNoAuth />
        <div className='eventPage'>

        <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                
                                <div className="card-header">
                                    <h2 className="card-title">
                                        {isDisplayEvent ? 
                                            "Veuillez sélectionner un événement"
                                            :
                                            "Commencez par créer un événement"
                                        }
                                    </h2>
                                </div>

                                <div className="eventPageFragment">
                                    {isDisplayEvent ? 
                                    <Fragment>

                                        <Row>
                                        {!loading && (
                                            paginatedEvents && paginatedEvents.map( event =>(
                                                <Col xl='3'>
                                                    <Card className='mb-3'>
                                                        <img
                                                        className='card-img-top img-fluid'
                                                        src={event.image ? getImageLink(event.image) : defaultImg}
                                                        alt='Card cap'
                                                        />
                                                        <Card.Header>
                                                        <Card.Title>{event.nom}</Card.Title>
                                                        </Card.Header>
                                                        <Card.Body>
                                                        <Card.Text>
                                                            Lieu: {event.lieu}
                                                        </Card.Text>
                                                        <Card.Text className=' text-dark'>
                                                            {formatDateEvent(event.date_debut, event.date_fin)}
                                                        </Card.Text>
                                                        <div className='cardActions mt-3'>
                                                            <Button className="btn btn-primary" onClick={() => handleSelect(event.id)}>Sélectionner</Button>
                                                        </div>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            )
                                            )
                                            



                                        )}

                                        </Row>

                                        {/* {loading && (<TableLoader></TableLoader>) } */}

                                        {filtredEvents ? filtredEvents.length > itemsPerPage && ( 
                                        <Pagination currentPage={currentPage} 
                                        itemsPerPage={itemsPerPage} 
                                        length={filtredEvents.length} 
                                        onPageChanged={handleChangePage} />) 
                                        : null}

                                    </Fragment>
                                    :
                                    <div className='createEventContainer'>
                                        <Button className="btn btn-primary btn-md" onClick={() => handleCreateEvent()}>Créer un événement</Button>
                                    </div>
                                    }
                                </div>

                            </div>
                        </div>
        </div>

        </div>
        </>

    );
};

Pagination.getData= (items, currentPage, itemsPerPage) => { 
    // D'ou l'ont part (start) pendant combien (itemsperpages)
    const start = currentPage * itemsPerPage -itemsPerPage;
    return items.slice(start, start + itemsPerPage);
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: isAuthenticated(state),
        evenements: state.evenementState.evenements,
        selectedEventId: state.evenementState.selectedEventId,

    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        { getEvents, selectEvent },
        dispatch,
    );
};


export default connect(mapStateToProps, mapDispatchToProps)(EventSelection); 
