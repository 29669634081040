import { ADD_ATELIER_FAIL, ADD_ATELIER_FAIL_DESC, ADD_ATELIER_SUCCESS, DELETE_ATELIER, FETCH_ATELIERS, FETCH_CURRENT_ATELIER, UPDATE_ATELIER_FAIL, UPDATE_ATELIER_FAIL_DESC, UPDATE_ATELIER_SUCCESS } from "../actions/AtelierActions";

  
  const defaultState = {};
  
  export default function (state = defaultState, action) {

    const { type, payload } = action;
    
    switch (type) {

      case FETCH_ATELIERS:
        return {
          ...state,
          ateliers: action.ateliers
        };

      case FETCH_CURRENT_ATELIER:
        return {
          ...state,
          currentAtelier: action.currentAtelier
        };

      case ADD_ATELIER_SUCCESS:
        return {
          ...state,
          currentAtelier: action.currentAtelier
        };
      
      case ADD_ATELIER_FAIL:
        return {
          ...state,
          addAtelierErrors: action.addAtelierErrors
        };
      
      case ADD_ATELIER_FAIL_DESC:
        return {
          ...state,
          addAtelierErrorDesc: action.addAtelierErrorDesc
        };


      case UPDATE_ATELIER_SUCCESS:
        return {
          ...state,
          currentAtelier: action.currentAtelier
        };

      case UPDATE_ATELIER_FAIL:
        return {
          ...state,
          updateAtelierErrors: action.updateAtelierErrors
        };

      case UPDATE_ATELIER_FAIL_DESC:
        return {
          ...state,
          updateAtelierErrorsDesc: action.updateAtelierErrorsDesc
        };

      case DELETE_ATELIER:
            const atelierUpdated= state.ateliers.filter(c  => c.id != action.id);
  
          return {
            ...state,
            ateliers: atelierUpdated,
          };
        

      default:
        return state;
    }
  }
  