import { applyMiddleware, combineReducers, compose,createStore,} from 'redux';
import PostsReducer from './reducers/PostsReducer';
import thunk from 'redux-thunk';
import { AuthReducer } from './reducers/AuthReducer';
import todoReducers from './reducers/Reducers';
import EvenementsReducer from './reducers/EvenementsReducer';
import InvitesReducer from './reducers/InvitesReducer';
import PdfReducer from './reducers/PdfReducer';
import InscriptionReducer from './reducers/InscriptionReducer';
import OrganisateursReducer from './reducers/OrganisateursReducer';
import SponsorsReducer from './reducers/SponsorsReducer';
import PressesReducer from './reducers/PressesReducer';
import StatisticsReducer from './reducers/StatisticsReducer';
import usersReducer from './reducers/usersReducer';
import PartenaireReducer from './reducers/PartenaireReducer';
import OrganismeReducer from './reducers/OrganismeReducer';
import AtelierReducer from './reducers/AtelierReducer';
import AttestationReducer from './reducers/AttestationReducer';
import AdherentReducer from './reducers/AdherentReducer';
//import { reducer as reduxFormReducer } from 'redux-form';
const middleware = applyMiddleware(thunk);

const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
    posts: PostsReducer,
    auth: AuthReducer,
	todoReducers,
	evenementState: EvenementsReducer,
    inviteState: InvitesReducer,
    organisateurState: OrganisateursReducer,
    pdfState: PdfReducer,
    inscriptionState: InscriptionReducer,
    sponsorState: SponsorsReducer,
    presseState: PressesReducer,
    statisticState: StatisticsReducer,
    partenaireState: PartenaireReducer,
    organismeState: OrganismeReducer,
    atelierState: AtelierReducer,
    attestationState: AttestationReducer,
    adherentState: AdherentReducer,
    userState: usersReducer

	//form: reduxFormReducer,	
	
});

//const store = createStore(rootReducers);

export const store = createStore(reducers,  composeEnhancers(middleware));
