// @flow 
import React,{useState, useEffect, useRef, useLayoutEffect} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Navigate, Link, useParams, useLocation } from 'react-router-dom';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { getEventById } from '../../../store/actions/EvenementActions';
import {addAttestation, updateAttestation, clearMsgAddAttestation, getAttestationById, printAttesPdf, printAttesPdfBrowser, downloadAttesPdf, sendAttesPdf} from '../../../store/actions/AttestationActions';
import { getOrganisateurs } from '../../../store/actions/OrganisateurActions';
import Field from '../Forms/Field';
import Select from "../Forms/Select";
import swal from 'sweetalert';

const Attestation = ({getEventById, 
    addAttestation, 
    updateAttestation, 
    clearMsgAddAttestation, 
    getAttestationById,
    currentEvenement, 
    userId,
    printAttesPdf,
    printAttesPdfBrowser,
    downloadAttesPdf,
    currentAttestation,
    addAttestationErrors,
    addAttestationErrorsDesc,
    updateAttestationErrors,
    updateAttestationErrorsDesc,
    getOrganisateurs,
    organisateurs,
    sendAttesPdf
    }) => {

    const [event, setEvent]= useState({});
    const [editing, setEditing]= useState(false); 
    const [loading, setLoading]= useState(true);
    const [isPrintable, setIsprintable]= useState(false);
    const [orateursListe, setOrateursListe]= useState([]);
    const [isSubmited, setIsSubmited]= useState(false);

    const [credentials, setCredentials]= useState({
        intitule: "",
        type: "",
        orateur: ""
      })

    const [errors, setErrors]= useState({
        intitule: "",
        type: "",
        orateur: ""
    });

    const params = useParams();
    let {id}= params;
    let {idAttestation= "new"}= params;

    //Get types au chargement
    useEffect( () => {
        if(idAttestation != "new"){
            setEditing(true);
            getAttestationById(idAttestation);
            setIsprintable(true);
        }else{
            setEditing(false);
        }
    }, [idAttestation] )

    useEffect( () => {

        const fetchData = async () => 
        {
            if(currentAttestation && idAttestation != "new"){
                setLoading(true);
                let attestation= currentAttestation;
                if(attestation.orateur && attestation.orateur.id){
                    attestation.orateur= attestation.orateur.id;
                }
                setCredentials(attestation);
            }
            setLoading(false);
        }

        fetchData();
        
    }, [currentAttestation] )

    useEffect( () => {
        if(addAttestationErrors){
               const apiErrors={};
               const violations=addAttestationErrors;
               if(violations && Array.isArray(violations)){
                violations.forEach(({propertyPath, message}) => { 
                  apiErrors[propertyPath]= message;
                  } );
                  setErrors(apiErrors);
               }
                      
        }
      
      }, [addAttestationErrors])

    useEffect( () => {
        if(addAttestationErrorsDesc){
            swal("Impossible de vous inscrire", addAttestationErrorsDesc, "error")
        }
      
      }, [addAttestationErrorsDesc])

    useEffect( () => {
        if(updateAttestationErrors){
               const apiErrors={};
               const violations=updateAttestationErrors;
               if(violations && Array.isArray(violations)){
                violations.forEach(({propertyPath, message}) => { 
                  apiErrors[propertyPath]= message;
                  } );
                  setErrors(apiErrors);
               }
                      
        }
      
      }, [updateAttestationErrors])

    useEffect( () => {
        if(updateAttestationErrorsDesc){
            swal("Impossible de vous inscrire", updateAttestationErrorsDesc, "error")
        }
      
      }, [updateAttestationErrorsDesc])
 
    useEffect( () => {  

        if(currentEvenement){
               setEvent(currentEvenement); 
        } 

     }, [currentEvenement])

    //Récupération d'un event
    const fetchEvent= async id  => {
        try {
               await getEventById(id);
               if(!editing){
                setLoading(false);
               }
        } catch (error) {
               toast.error("Impossible de charger l'évenement");  
               Navigate("/");
        }
    }

    //Get invites au chargement
    useEffect( () => {
        if(organisateurs){
            if(! organisateurs.length==0){
                let tmpOrganisateur= organisateurs.filter(item => item.type === "orateur");
                setOrateursListe(tmpOrganisateur);
            }
        }
        

    }, [organisateurs] )

     //Get orateurs
     const fetchOrateurs = async id => {
        try {
            await getOrganisateurs(id);
            setLoading(false);
        } catch (error) {
            toast.error("Erreur lors du chargement des orateurs");
        }
    }

    // Chargement de l'event au chargement du composent ou changement de l'id  
    useEffect( () => {
        setLoading(true);
        fetchEvent(id);
        fetchOrateurs(id);
    }, [id])

    const onSignup = async (e) =>{
        e.preventDefault();

        setErrors({});
        clearMsgAddAttestation();

        let attestation= credentials;
        attestation.orateur= "/api/organisateurs/" + attestation.orateur;

            if(editing){

                try {
                    await updateAttestation(idAttestation, attestation, event, userId);
                    //if response
                    setErrors({});
                    swal("L'attestation est à jour", "success");
                }   catch (err) {
                    toast.error("Une erreur est survenue !");
                }

            } else{
                try {
                    await addAttestation(attestation, event, userId);
                    //if response
                    setErrors({});
                    swal("L'attestation est bien enregistrée", "success");
                    setIsprintable(true);
                    setIsSubmited(true);
                }   catch (err) {
                    toast.error("Une erreur est survenue !");
    
                }
            }

    }

    //Gestion des champs
    const handleChangeFields = ({currentTarget}) =>{
        const {value, name}= currentTarget;
        setCredentials({ ...credentials, [name]: value });
    }

    useLayoutEffect(() => {
        return () => {
               setErrors({});
               clearMsgAddAttestation();
               setEditing(false);
        }
    }, [])

    const handlePrint = async () => {
        try {
                await printAttesPdf(currentAttestation.id, currentAttestation.evenement.id);
        } catch(err){
            toast.error("Erreur lors du chargement du pdf");
        }
        
    };

    const handlePrintBrowser = async () => {
        try {
                await printAttesPdfBrowser(currentAttestation.id, currentAttestation.evenement.id);
        } catch(err){
            toast.error("Erreur lors du chargement du pdf");
        }
    };

    const handleDownload = async () => {
        try {
                await downloadAttesPdf(currentAttestation.id, currentAttestation.evenement.id);
        } catch(err){
            toast.error("Erreur lors du chargement du pdf");
        }
    };

    const handleSend = async () => {
        if(editing){
            if(currentAttestation.orateur){

                const tmpOrateur = orateursListe.find(orateur => orateur.id === currentAttestation.orateur);
                if(tmpOrateur && tmpOrateur.email) {
                    try {
                        await sendAttesPdf(currentAttestation.id, currentAttestation.evenement.id);
                        toast.success("Attestation envoyée par email à l'adresse " + tmpOrateur.email);
                    } catch(err){
                        toast.error("Erreur lors de l'envoi de l'émail");
                    }
                } else {
                    toast.error("Veuillez renseigner l'adresse e-mail de l'orateur " + (tmpOrateur.id ? tmpOrateur.id : null) + " dans l'onglet orateurs");
                }
                
                
            } else {
                toast.error("Erreur lors de l'envoi de l'émail");
            }
        } else {
            if(currentAttestation.orateur && currentAttestation.orateur.email){
            
                try {
                    await sendAttesPdf(currentAttestation.id, currentAttestation.evenement.id);
                    toast.success("Attestation envoyée par email à l'adresse " + currentAttestation.orateur.email);
                } catch(err){
                    toast.error("Erreur lors de l'envoi de l'émail");
                }
                
                } else {
                    toast.error("Veuillez renseigner l'adresse e-mail de l'orateur " + (currentAttestation.orateur.id ? currentAttestation.orateur.id : null) + " dans l'onglet orateurs");
                }
        }
    };

    const reloadPage = () => {
        window.location.reload();
    }

    return (
        <>
            <div className="row page-titles">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active"><Link to={"/" + id + "/admin/attestations"}> Attestations</Link></li>
                    <li className="breadcrumb-item active"><Link to={"#"}> {editing ? "Modification": "Ajout"} </Link></li>
                </ol>
            </div>

            <div className="row tableRow ajoutInvite">
                <div className="col-lg-12">

                    <div className="card">

                        <div className="card-header">
                            <h4 className="card-title">
                            {editing ? "Modification d'attestation"
                            : "Ajout d'attestation"}

                             </h4>

                                {isPrintable &&
                                <div className="printBtn">
                                    <Link className="btn shadow btn-md sharp me-1 btn-success"
                                    onClick={() => handlePrint()}>
                                        <i class="fas fa-print fa-lg"></i>
                                    </Link>
                                    <Link className="btn btn-primary shadow btn-md sharp me-1" 
                                    onClick={() => handlePrintBrowser()}>
                                        <i class="fas fa-file-pdf fa-lg"></i>
                                    </Link>
                                    <Link className="btn btn-success shadow btn-md sharp me-1" 
                                    onClick={() => handleSend()}>
                                        <i class="fas fa-envelope fa-lg"></i>
                                    </Link>
                                    <Link className="btn btn-info shadow btn-md sharp me-1" 
                                    onClick={() => handleDownload()}>
                                        <i class="fas fa-file-download fa-lg"></i>
                                    </Link>

                                    {!editing && isSubmited &&
                                    <Link className="btn btn-secondary" style={{ marginRight: "10px"}} onClick={() => reloadPage()}>
                                        <i class="fas fa-spinner"  style={{ marginRight: "5px"}}></i>  
                                        Actualiser
                                    </Link>
                                    }
                                    
                                </div>
                                }

                                <Link to={"/" + id + "/admin/attestations"} className="btn btn-primary">
                                    <i class="fas fa-undo"  style={{ marginRight: "5px",}}></i>  
                                    Retour à la liste
                                </Link>

                        </div>

                    </div>

                </div>

                <div className='eventPage'>

        <div className="row">
                        <div className="col-lg-12">
                            <div className="card">

                                <div className="eventPageFragment">
                                    {!loading &&
                                    <Container>

                                        <Row>

                                            <Col md="12">
                                            <form onSubmit={onSignup}>
                                                
                                                <Row>

                                                <Col md="6">
                                                    <Field
                                                        name="intitule"
                                                        value={credentials.intitule}
                                                        onChange={handleChangeFields}
                                                        label= "Intitulé *"
                                                        placeholder="Intitulé"
                                                        error={errors.intitule}
                                                        type="text"
                                                        required="true" 
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    <Select name="type"
                                                    value={credentials.type}
                                                    label="Type *"
                                                    onChange={handleChangeFields}
                                                    required="true"
                                                    >
                                                    <option value="" defaultChecked>
                                                        Veuillez sélectionner un type
                                                    </option> 

                                                    <option value="affichee">
                                                        Affichée
                                                    </option>

                                                    <option value="orale">
                                                        Orale
                                                    </option>

                                                    <option value="atelier">
                                                        Atelier
                                                    </option>

                              
                                                    </Select>
                                                </Col>

                                                <Col md="6">
                                                    <Select name="orateur"
                                                        value={credentials.orateur}
                                                        label="Orateur *"
                                                        onChange={handleChangeFields}
                                                        required
                                                        >
                                                    <option value="" defaultChecked>
                                                        Veuillez sélectionner un orateur
                                                    </option> 

                                                    {orateursListe
                                                    .map(orateur  =>  (
                                                    <option key={orateur.id} value={orateur.id} autoCorrect>
                                                        {orateur.prenom + " " + orateur.nom}
                                                        </option> 
                                                        ))}
                                                    </Select>
                                                </Col>

                                                </Row>

                                                <div className="text-center">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary btn-lg"
                                                        disabled= {!editing && isSubmited}
                                                    >
                                                        {editing ? "Enregistrer" : "Ajouter"}
                                                    </button>
                                                </div>
                                            </form>
                                            </Col>

                                        </Row>

                                    </Container>
                                    }
                                    
                                    
                                </div>

                            </div>
                        </div>
        </div>

        </div>

            </div>

        </>
    );
};

const mapStateToProps = (state) => {
    return {
        evenements: state.evenementState.evenements,
        organisateurs: state.organisateurState.organisateurs,
        currentEvenement: state.evenementState.currentEvenement,
        addAttestationErrors: state.attestationState.addAttestationErrors,
        addAttestationErrorsDesc: state.attestationState.addAttestationErrorsDesc,
        updateAttestationErrors: state.attestationState.updateAttestationErrors,
        updateAttestationErrorsDesc: state.attestationState.updateAttestationErrorsDesc,
        userId: state.auth.user.id,
        currentAttestation: state.attestationState.currentAttestation
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        { getEventById, 
        getOrganisateurs,
        addAttestation, 
        updateAttestation, 
        clearMsgAddAttestation, 
        getAttestationById,
        printAttesPdf,
        printAttesPdfBrowser,
        sendAttesPdf, 
        downloadAttesPdf },
        dispatch,
    );
};


export default connect(mapStateToProps, mapDispatchToProps)(Attestation); 