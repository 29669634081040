// @flow 
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { Link, Navigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import logo from "../../../../images/logo-forum.png";
import Field from '../../../components/Forms/Field';
import Select from "../../../components/Forms/Select";
import listeTitres from "../../../../helpers/Titre_medecin.json";  
import HeaderNoAuth from '../../../components/HeaderNoAuth';
import RecaptchaSection from '../../../components/RecaptchaSection';
import swal from 'sweetalert';
import FooterPublic from '../../../layouts/FooterPublic';
import checkoutImage from "../../../../images/checkout-accepted.svg";
import checkoutRefusedImage from "../../../../images/checkout-refused.svg";
import axios from 'axios';
import { CREATE_CHECKOUT_API } from '../../../../services/config';


const CheckoutDone = () => {

    const [isCheckoutSuccess, setIsCheckoutSuccess]= useState("");

    const params = useParams();
    let {status}= params;

    useEffect( () => {
        if(status){
            if(status == "success"){
                setIsCheckoutSuccess(status);    
            }
        }
      
    }, [status])

    return (
        <>
        <HeaderNoAuth />

        <div className='checkoutPage eventPage'>

            <div className="row">

                        <div className="col-lg-12">
                            <div className="card">

                                <div className="card-header">
                                    <h3 className="card-title" style={{textAlign: "center", width: "100%", fontSize: "16px"}}>
                                        {isCheckoutSuccess ? 
                                         "Veuillez conserver votre reçu de paiement, il vous sera nécessaire en cas de réclamation" 
                                        :
                                         "Veuillez réessayer ultérieurement en utilisant le lien reçu par e-mail" 
                                        }
                                    </h3>
                                </div>

                                <div className="eventPageFragment">
                                        <Container>

                                            <Row id="containerCheckoutAcceptedImg">
                                                <img src={isCheckoutSuccess ? checkoutImage : checkoutRefusedImage} alt="" id="checkoutAcceptedImage"/>
                                            </Row>  

                                            <Row>


                                                        <div className="text-center mt-4">
                                                            <Link
                                                                type="submit"
                                                                className={isCheckoutSuccess ? "btn btn-success btn-md": "btn btn-danger btn-md"}
                                                                to="/"
                                                            >
                                                                Retourner aux événements
                                                            </Link>
                                                        </div>


                                            </Row>  

                                        </Container> 
                                </div>
                            </div>
                        </div>
            </div>

        </div>

        <FooterPublic />
        </>
    );
};

export default CheckoutDone