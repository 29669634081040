// @flow 
import React,{useState, useEffect, useRef} from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import { toast } from 'react-toastify';
import Pagination from '../PaginationTable';

import WilayaOfAlgeria from "../../../helpers/Wilaya_Of_Algeria.json";
import listeSpecialites from "../../../helpers/Liste_specialites.json";
import { Badge, Button, Col, Container, Modal, Row } from 'react-bootstrap';
import Select from '../Forms/Select';
import { formatDateTime } from '../../../helpers/FormatHelpers';
import { downloadPdf, printPdf, printPdfBrowser } from '../../../store/actions/PdfActions';
import { updateCheckInscription } from '../../../store/actions/InscriptionActions';
import { getOrganisateurs, deleteOrganisateur } from '../../../store/actions/OrganisateurActions';
import { printOrgPdf, printOrgPdfBrowser, downloadOrgPdf, updateCheckInOrg } from '../../../store/actions/OrganisateurActions';

const Organisateurs = ({getOrganisateurs, 
    organisateurs, 
    printOrgPdf,
    printOrgPdfBrowser, 
    downloadOrgPdf, 
    currentPdf, 
    deleteOrganisateur,
    updateCheckInOrg,
    currentUser
    }) => {

    const [currentPage, setCurrentPage]= useState(1);
    const [search, setSearch]= useState("");
    const [searchWilaya, setSearchWilaya]= useState("");
    const [searchSpecialite, setSearchSpecialite]= useState("");
    const [loading, setLoading]= useState(true);
    const [organisateursListe, setOrganisateursListe]= useState([]);
    const [error, setError]= useState();
    const [confirmationVisible, setConfirmationVisible]= useState();
    const [organisateurForDelete, setOrganisateurForDelete]= useState();
    const [organisateurForDeleteInfo, setOrganisateurForDeleteInfo]= useState();
    const [organisateurForCheck, setOrganisateurForCheck]= useState();
    const [confirmationCheckVis, setConfirmationCheckVis]= useState();
    const organisateurState = useSelector(state => state.organisateurState);


    const optionsWilaya = WilayaOfAlgeria;
    const optionsSpecialite= listeSpecialites;

    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    let {id}= params;


    //Get invites
    const fetchInvites = async () => {
        try {
            getOrganisateurs(id);
            setLoading(false);
        } catch (error) {
            toast.error("Erreur lors du chargement des organisateurs");
        }
    }


    useEffect(() => {
        fetchInvites();
     }, []);

    //Get invites au chargement
    useEffect( () => {
        if(organisateurs){
            if(! organisateurs.length==0){
                let tmpOrganisateur= organisateurs.filter(item => item.type === "organisateur");
                setOrganisateursListe(tmpOrganisateur);
            }
        }
        

    }, [organisateurs] )

    // Detecter le changement dans le store redux pour mettre à jour le componsant - Checkin
    useEffect( () => {
        if(organisateurState.organisateurs){
            let tmpOrganisateur= organisateurState.organisateurs.filter(item => item.type === "organisateur");
            setOrganisateursListe(tmpOrganisateur);
        }
        
    }, [organisateurState] )

    //Change current page
    const handleChangePage = page => setCurrentPage(page);
    
    //Recherche
    const handleSearch = ({currentTarget}) => { 
        const value= currentTarget.value;
        setSearch(value);
        setCurrentPage(1);
    }

    //Recherche Wilaya
    const handleSearchWilaya = ({currentTarget}) => { 
        const value= currentTarget.value;
        setSearchWilaya(value);
        setCurrentPage(1);
    }

    //Recherche Spécialité
    const handleSearchSpecialite = ({currentTarget}) => { 
        const value= currentTarget.value;
        setSearchSpecialite(value);
        setCurrentPage(1);
    }

    // Rénitialiser les filtres
    const resetFilters= () => {
        setSearch("");
        setSearchSpecialite("");
        setSearchWilaya("");
    }

    const itemsPerPage =10;
    
    //Filtrage des types en fonction de la recherche
    let filtredOrganisateurs;

    if(organisateursListe && organisateursListe.length >0){         
        filtredOrganisateurs= organisateursListe.filter(
            t =>
            (t.id && t.id.toString().includes(search)) ||
            (t.nom && t.nom.toLowerCase().includes(search.toLowerCase())) ||
            (t.prenom && t.prenom.toLowerCase().includes(search.toLowerCase())) ||
            (t.email && t.email.toLowerCase().includes(search.toLowerCase())) ||
            (t.tel && t.tel.toLowerCase().includes(search.toLowerCase())) ||
            (t.adresse && t.adresse.toLowerCase().includes(search.toLowerCase()))
        );
    }

    if(searchWilaya != ""){
        filtredOrganisateurs= filtredOrganisateurs.filter(
            c =>
            (c.wilaya && c.wilaya.toLowerCase().includes(searchWilaya.toLowerCase()))
        )
    };

    if(searchSpecialite != ""){
        filtredOrganisateurs= filtredOrganisateurs.filter(
            c =>
            (c.specialite && searchSpecialite != "" && c.specialite.toLowerCase().includes(searchSpecialite.toLowerCase()))
        );
    }

    // Au cas ou le résultat de la recherche serait null
    useEffect( () => {
        if(filtredOrganisateurs && filtredOrganisateurs.length == 0 && search!= ""){
            setError("Désolé, aucun résultat n'a été trouvé");
        }else if (filtredOrganisateurs && filtredOrganisateurs.length>0 ){
            setError();
        }
    }, [filtredOrganisateurs] )
    
    //Pagination
    let paginatedOrganisateurs;
    if (filtredOrganisateurs && filtredOrganisateurs.length >0){
        paginatedOrganisateurs= Pagination.getData(filtredOrganisateurs, currentPage, itemsPerPage);
    }

    const handlePrint = async (idOrganisateur, idEvenement) => {
        try {
            await printOrgPdf(idOrganisateur, idEvenement);
        } catch(err){
            toast.error("Erreur lors du chargement du pdf");
        }
    };

    const handlePrintBrowser = async (idOrganisateur, idEvenement) => {
        try {
            await printOrgPdfBrowser(idOrganisateur, idEvenement);
        } catch(err){
            toast.error("Erreur lors du chargement du pdf");
        }
    };

    const handleDownload = async (idOrganisateur, idEvenement) => {
        try {
            await downloadOrgPdf(idOrganisateur, idEvenement);
        } catch(err){
            toast.error("Erreur lors du chargement du pdf");
        }
    };

    const handleDelete = (item) => {
        if(currentUser.roles[0]!=="ROLE_ADMIN"){
            toast.error("Vous n'avez pas l'autorisation nécéssaire");
        }else{
        setOrganisateurForDelete(item.id);
        setOrganisateurForDeleteInfo(item.prenom + " " + item.nom);
        setConfirmationVisible(true);
        }
    }

    const handleDeleteConfirm = async id => {
        setConfirmationVisible(false)
        try {
            await deleteOrganisateur(id);
            toast.success("L'organisateur a bien été supprimé");
        } catch (error) {
            toast.error("Une erreur est survenue");
        }

    }

    const handleCheckOrganisateur = (idOrganisateur) => {
       setOrganisateurForCheck(idOrganisateur);
       setConfirmationCheckVis(true);
    }

    const handleCheckOrganisateurConfirm= async (idOrganisateur) => {
        setConfirmationCheckVis(false)
        try {
            await updateCheckInOrg(idOrganisateur, true);
            toast.success("Chek-in validé");
        } catch (error) {
            toast.error("Une erreur est survenue");
        }
    }

    return (
        <>

            <div className="row page-titles">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active"><Link to={"#"}> organisteurs</Link></li>
                </ol>
            </div>

            <div className="row tableRow">
                <div className="col-lg-12">

                    <div className="card">

                        <div className="card-header">
                            <h4 className="card-title">Liste des organisateurs</h4>
                            <Link to={location.pathname + "/new"} className="btn btn-primary">Ajouter</Link>
                        </div>
                        
                        <div className="card-body">
                            <div className="table-responsive ticket-table">
                                <div id="ticket_wrapper" className="dataTables_wrapper no-footer">
                                    
                                    <Container>
                                        <Row>
                                            <Col md={7}>
                                                <div className="col-12 d-flex align-items-center">
                                                    <label className="me-2 mt-2">Recherche:</label>
                                                    <input 
                                                        className="form-control"
                                                        type="search" 
                                                        placeholder="Nom, prénom, email, adresse ..." 
                                                        onChange={handleSearch}
                                                        value={search}
                                                    />
                                                </div>
                                            </Col>

                                            <Col md={2} className="divSelect">
                                                <Select name="wilaya"
                                                    //label="Wilaya"
                                                    onChange={handleSearchWilaya}
                                                    value={searchWilaya}
                                                    >
                                                <option value="" defaultChecked>
                                                    Localisation
                                                </option> 

                                                {optionsWilaya
                                                .map(wilaya  =>  (
                                                <option key={wilaya.id} value={wilaya.name}>
                                                    {wilaya.name + " - " + wilaya.code + " - " + wilaya.ar_name}
                                                    </option> 
                                                    ))}
                                                
                                                </Select>
                                            </Col>

                                            <Col md={2} className="divSelect">
                                                <Select name="specialite"
                                                    onChange={handleSearchSpecialite}
                                                    value={searchSpecialite}
                                                    >
                                                <option value="" defaultChecked>
                                                    Spécialité
                                                </option> 

                                                {optionsSpecialite
                                                .sort(function(a, b) {
                                                    if(a.toLowerCase() < b.toLowerCase()) return -1;
                                                    if(a.toLowerCase() > b.toLowerCase()) return 1;
                                                    return 0;
                                                    })
                                                .map(specialite  =>  (
                                                <option key={specialite} value={specialite}>
                                                    {specialite}
                                                    </option> 
                                                    ))}
                                                
                                                </Select>
                                            </Col>

                                            <Col md={1}>

                                                <Button className="btn btn-primary shadow btn-md sharp me-1 restartBtn" onClick={resetFilters}>
                                                    <i class="fas fa-undo"></i>                                                
                                                </Button>
                                            </Col>

                                        </Row>
                                    </Container>
                                        

                                    <table id="example" className="display dataTablesCard table-responsive-xl dataTable no-footer w-100">
                                        
                                    { (paginatedOrganisateurs && paginatedOrganisateurs.length>0) &&
                                        <thead>
                                            <tr>
                                                <th></th>
												<th>Organisateur</th>
												<th>Nom</th>
												<th>Email</th>
												<th>Tel</th>
												<th>Adresse</th>
												<th>Wilaya</th>
												<th>Spécialité</th>
												<th>Titre</th>
												<th>Inscrit le:</th>
												<th>État:</th>
												<th>Actions</th>                                          
                                            </tr>
                                        </thead>
                                    }

                                        {!loading && (
                                        <tbody>
                                            {paginatedOrganisateurs &&  paginatedOrganisateurs.map((item, index)=>(
                                                <tr key={index}>

                                                    <td>
                                                        <Link className={item.checked ? "btn shadow btn-md sharp me-1 btn-success" : "btn shadow btn-md sharp me-1 btn-danger"} 
                                                        onClick={() => handlePrint(item.id, item.evenement.id)}>
                                                            <i class="fas fa-print fa-2x"></i>
                                                        </Link>
                                                    </td>

                                                    <td>
                                                        {item.checked ?
                                                                <span className="badge badge-success badge-lg">{item.id}</span>
                                                            :
                                                                <span className="badge badge-danger badge-lg">{item.id}</span>
                                                            }
                                                    </td>
                                                    
                                                    <td>						
                                                            <span className="badge light badge-success">
                                                                <Link to={"#"} className="h5">{item.prenom + " " + item.nom}</Link>
                                                            </span>
                                                        
                                                    </td>
                                                    <td>{item.email}</td>
                                                    <td>{item.tel}</td>
                                                    <td>{item.adresse}</td>
                                                    <td>{item.wilaya}</td>
                                                    <td>{item.specialite}</td>
                                                    <td>{item.titre}</td>
                                                    <td>{item.date_inscription && formatDateTime(item.date_inscription)}</td>
                                                    <td>
                                                        {item.checked ?
                                                            <p className="badge badge-success badge-lg">Validé</p>
                                                        :
                                                            <Link className="badge badge-primary badge-xl" onClick={() => handleCheckOrganisateur(item.id ,item.id)}>Valider</Link>
                                                        }
                                                    </td>
                                                    
                                                    <td>
                                                        <div className="d-flex">

                                                            <Link className="btn btn-primary shadow btn-md sharp me-1" 
                                                            onClick={() => handlePrintBrowser(item.id, item.evenement.id)}>
                                                                <i class="fas fa-file-pdf fa-lg"></i>
                                                            </Link>

                                                            <Link className="btn btn-info shadow btn-md sharp me-1" 
                                                            onClick={() => handleDownload(item.id, item.evenement.id)}>
                                                                <i class="fas fa-file-download fa-lg"></i>
                                                            </Link>

                                                            <Link to={location.pathname + "/" + item.id} className="btn btn-warning shadow btn-md sharp me-1"
                                                            >
                                                                <i className="fas fa-pencil-alt fa-lg"></i>
                                                            </Link>

                                                            <Link to={"#"} className="btn btn-danger shadow btn-md sharp me-1"
                                                            onClick={() => handleDelete(item)}>
                                                                <i className="fa fa-trash fa-lg"></i>
                                                            </Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                            
                                        </tbody>
                                        )}

                                        
                                        
                                    </table>

                                    {filtredOrganisateurs ? filtredOrganisateurs.length > itemsPerPage && ( 
                                        <Pagination currentPage={currentPage} 
                                        itemsPerPage={itemsPerPage} 
                                        length={filtredOrganisateurs.length} 
                                        onPageChanged={handleChangePage} />) 
                                        : null}

                                    {/* <!-- Modal --> */}
                                    <Modal className="fade" show={confirmationVisible}>
                                    <Modal.Header>
                                        <Modal.Title>Supression de l'organisateur {organisateurForDeleteInfo}</Modal.Title>
                                        <Button
                                            variant=""
                                            className="close"
                                        >
                                            <span>&times;</span>
                                        </Button>
                                    </Modal.Header>
                                    <Modal.Body>êtes-vous sûr de vouloir continuer ?</Modal.Body>
                                    <Modal.Footer>
                                        <Button
                                            onClick={() => setConfirmationVisible(false)}
                                            variant="danger light"
                                        >
                                            Annuler
                                        </Button>
                                        <Button variant="primary"  onClick={() => handleDeleteConfirm(organisateurForDelete)}>
                                            Continuer
                                        </Button>
                                    </Modal.Footer>
                                    </Modal>

                                    {/* <!-- Modal --> */}
                                    <Modal className="fade" show={confirmationCheckVis}>
                                    <Modal.Header>
                                        <Modal.Title>Check-in</Modal.Title>
                                        <Button
                                            variant=""
                                            className="close"
                                        >
                                            <span>&times;</span>
                                        </Button>
                                    </Modal.Header>
                                    <Modal.Body>êtes-vous sûr de vouloir continuer ?</Modal.Body>
                                    <Modal.Footer>
                                        <Button
                                            onClick={() => setConfirmationCheckVis(false)}
                                            variant="danger light"
                                        >
                                            Annuler
                                        </Button>
                                        <Button variant="primary"  onClick={() => handleCheckOrganisateurConfirm(organisateurForCheck)}>
                                            Continuer
                                        </Button>
                                    </Modal.Footer>
                                    </Modal>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: "",
        organisateurs: state.organisateurState.organisateurs,
        currentPdf: state.pdfState.currentPdf,
        currentUser: state.auth.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        { getOrganisateurs, 
            printOrgPdf, 
            printOrgPdfBrowser, 
            downloadOrgPdf, 
            deleteOrganisateur,
            updateCheckInOrg },
        dispatch,
    );
};


export default connect(mapStateToProps, mapDispatchToProps)(Organisateurs); 