import { ADD_ORGANISME_FAIL, ADD_ORGANISME_FAIL_DESC, FETCH_ORGANISMES, UPDATE_ORGANISME_FAIL, UPDATE_ORGANISME_FAIL_DESC } from "../actions/OrganismeActions";

  
  const defaultState = {};
  
  export default function (state = defaultState, action) {

    const { type, payload } = action;
    
    switch (type) {

      case FETCH_ORGANISMES:
        return {
          ...state,
          organismes: action.organismes
        };
      
      case ADD_ORGANISME_FAIL:
        return {
          ...state,
          addOrganismeErrors: action.addOrganismeErrors
        };
      
      case ADD_ORGANISME_FAIL_DESC:
        return {
          ...state,
          addOrganismeErrorDesc: action.addOrganismeErrorDesc
        };

      case UPDATE_ORGANISME_FAIL:
        return {
          ...state,
          updateOrganismeErrors: action.updateOrganismeErrors
        };
      
      case UPDATE_ORGANISME_FAIL_DESC:
        return {
          ...state,
          updateOrganismeErrorDesc: action.updateOrganismeErrorDesc
        };


      default:
        return state;
    }
  }