import { ADD_EVENEMENT_FAIL, ADD_EVENEMENT_FAIL_DESC, DELETE_EVENEMENT, FETCH_CURRENT_EVENEMENT, FETCH_EVENEMENTS, FETCH_SELECTED_EVENEMENT, UPDATE_EVENEMENT_FAIL, UPDATE_EVENEMENT_FAIL_DESC } from "../actions/EvenementActions";

  
  const defaultState = {};
  
  export default function (state = defaultState, action) {

    const { type, payload } = action;
    
    switch (type) {
      
      case FETCH_EVENEMENTS:
        return {
          ...state,
          evenements: action.evenements
        };

      case FETCH_CURRENT_EVENEMENT:
        return {
          ...state,
          currentEvenement: action.currentEvenement
        };

      case FETCH_SELECTED_EVENEMENT:
        return {
          ...state,
          selectedEvent: action.selectedEvent,
          selectedEventId: action.selectedEventId,
        };

      case ADD_EVENEMENT_FAIL:
        return {
          ...state,
          addEvenementErrors: action.addEvenementErrors
        };
      
      case ADD_EVENEMENT_FAIL_DESC:
        return {
          ...state,
          addEvenementErrorDesc: action.addEvenementErrorDesc
        };

      case UPDATE_EVENEMENT_FAIL:
        return {
          ...state,
          updateEvenementErrors: action.updateEvenementErrors
        };
      
      case UPDATE_EVENEMENT_FAIL_DESC:
        return {
          ...state,
          updateEvenementErrorDesc: action.updateEvenementErrorDesc
        };

      case DELETE_EVENEMENT:
            const evenementsUpdated= state.evenements.filter(c  => c.id != action.id);
  
          return {
            ...state,
            evenements: evenementsUpdated,
          };


      default:
        return state;
    }
  }
  