import React from "react";
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import carteCib from "../../images/carteCib.png";
import carteEdahabia from "../../images/AlgeriePoste.png";
import logoFacebook from "../../images/logoFacebook.png";
import logoEnveloppe from "../../images/logoEnveloppe.png";
import logoWebsite from "../../images/logoWebsite.png";
import { Link } from "react-router-dom";

const FooterPublic = () => {
  var d = new Date();
  return (
    <div className="footer footerPublic">
      
      <Container>

      <Row>

        <Col md="4" className="footerSections">
          <h4>Nous contacter</h4>
          <div className="text-center">
            <a href="https://www.facebook.com/cardiologiebejaia"> <img src={logoFacebook} alt="" className="socialLogos"/> </a>
            <a href="mailto:?to=fcbejaia@yahoo.com"> <img src={logoEnveloppe} alt="" className="socialLogos" id="logoEnveloppe"/> </a>
          </div>
        </Col>  

        <Col md="4" className="footerSections">
          <h4>Moyens de paiement</h4>
          <div className="acceptedCard text-center">
            <img src={carteCib} alt=""/>
            <img src={carteEdahabia} alt=""/>
          </div>
        </Col> 

        <Col md="4" className="footerSections">
          <h4>Découvrez également</h4>
          <div className="text-center">
            <a href="https://forum-cardio.com/"> <img src={logoWebsite} alt="" id="logoWebsite"/> </a>
          </div>
          </Col>  

      </Row>  
      
      </Container>  

      <div className="copyright">
        <p>
          Copyright © Powered by{" "}
          <a href="https://divhart.com/" target="_blank"  rel="noreferrer">
            Divhart
          </a>{" "}
          {d.getFullYear()}
        </p>
      </div>
    </div>
  );
};

export default FooterPublic;
