import axios from 'axios';
import swal from "sweetalert";
import { LOGIN_API } from './config';
import {
    loginConfirmedAction,
    Logout,
} from '../store/actions/AuthActions';

export function signUp(email, password) {
    //axios call
    const postData = {
        email,
        password,
        returnSecureToken: true,
    };
    return axios.post(
        `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
        postData,
    );
}

export function login(username, password) {
    return axios
      .post(LOGIN_API , { username, password }) 
      .then((response) => {
        return response;
      });
  }

export function formatError(errorResponse) {
    switch (errorResponse) {
        case "Invalid credentials.":
            swal("Non autorisé", "Veuillez utilisez un identifiant et un mot de passe valide", "error");
            break;
        case 'EMAIL_NOT_FOUND':
            //return 'Email not found';
           swal("Oops", "Email not found", "error",{ button: "Try Again!",});
           break;
        case 'INVALID_PASSWORD':
            //return 'Invalid Password';
            swal("Oops", "Invalid Password", "error",{ button: "Try Again!",});
            break;
        case 'USER_DISABLED':
            return 'User Disabled';

        default:
            return '';
    }
}

const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };

export function saveTokenInLocalStorage(tokenDetails) {
    const decodedJwt = parseJwt(tokenDetails.token);
    tokenDetails.expireDate= decodedJwt.exp * 1000;
    localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
    localStorage.setItem('userId', decodedJwt.id);
    
}

export function runLogoutTimer(dispatch, timer, navigate) {
    setTimeout(() => {
        //dispatch(Logout(history));
        dispatch(Logout(navigate));
    }, timer);
}

export function checkAutoLogin(dispatch, navigate) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    let tokenDetails = '';
    if (!tokenDetailsString) {
        dispatch(Logout(navigate));
		return;
    }

    tokenDetails = JSON.parse(tokenDetailsString);
    let expireDate = tokenDetails.expireDate;
    let todaysDate = Date.now();
    if (todaysDate > expireDate) {
        dispatch(Logout(navigate));
        return;
    }

    let decodedJwt= parseJwt(tokenDetails.token);
    let user= decodedJwt;

    dispatch(loginConfirmedAction(tokenDetails, user));
	
    const timer = expireDate - todaysDate;
    runLogoutTimer(dispatch, timer, navigate);
}
