// @flow 
import React,{useState, useEffect, useRef, useLayoutEffect} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Navigate, Link, useParams, useLocation } from 'react-router-dom';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { getEventById } from '../../../store/actions/EvenementActions';
import { addOrateur, updateOrganisateur, clearMsgAddOrg, getOrganisateurById } from '../../../store/actions/OrganisateurActions';
import { updateCheckInscription } from '../../../store/actions/InscriptionActions';
import logo from "../../../images/logo-forum.png";
import Field from '../../components/Forms/Field';
import Select from "../../components/Forms/Select";
import WilayaOfAlgeria from "../../../helpers/Wilaya_Of_Algeria.json";
import listeSpecialites from "../../../helpers/Liste_specialites.json"; 
import listeTitres from "../../../helpers/Titre_medecin.json";  
import HeaderNoAuth from '../../components/HeaderNoAuth';
import swal from 'sweetalert';
import { printOrgPdf, printOrgPdfBrowser, downloadOrgPdf } from '../../../store/actions/OrganisateurActions';

const Orateur = ({getEventById, 
    addOrateur, 
    updateOrganisateur,
    //updateCheckInscription,
    currentEvenement, 
    addOrganisateurErrors, 
    addOrganisateurErrorsDesc, 
    updateOrganisateurErrors,
    updateOrganisateurErrorsDesc,
    clearMsgAddOrg, 
    userId,
    getOrganisateurById,
    currentOrganisateur,
    printOrgPdf,
    printOrgPdfBrowser,
    downloadOrgPdf,
    }) => {

    const [event, setEvent]= useState({});
    const [editing, setEditing]= useState(false); 
    const [loading, setLoading]= useState(true);
    const [isPrintable, setIsprintable]= useState(false);

    const [credentials, setCredentials]= useState({
        nom: "",
        prenom: "",
        email: "",
        emailConfirm: "",
        tel: "",
        adresse: "",
        wilaya: "Béjaïa",
        titre: "",
        specialite: "",
        checked: "true"
      })

    const [errors, setErrors]= useState({
        nom: "",
        prenom: "",
        email: "",
        emailConfirm: "",
        tel: "",
        adresse: "",
        wilaya: "",
        titre: "",
        specialite: "",
        checked: ""
    });

    const optionsWilaya = WilayaOfAlgeria;
    const params = useParams();
    let {id}= params;
    let {idOrateur= "new"}= params;

    //Get types au chargement
    useEffect( () => {
        if(idOrateur != "new"){
            setEditing(true);
            getOrganisateurById(idOrateur);
            setIsprintable(true);
        }else{
            setEditing(false);
        }
    }, [idOrateur] )

    useEffect( () => {

        const fetchData = async () => 
        {
            if(currentOrganisateur && idOrateur != "new"){
                setLoading(true);
                setCredentials(currentOrganisateur);
    
                if(currentOrganisateur.email){

                    if(currentOrganisateur.checked){
                        setCredentials({...currentOrganisateur, emailConfirm: currentOrganisateur.email, checked: "true"});
                    }else{
                        setCredentials({...currentOrganisateur, emailConfirm: currentOrganisateur.email, checked: "false"});
                    }
                    
                } else{
                    if(currentOrganisateur.checked){
                        setCredentials({...currentOrganisateur, email: "", emailConfirm: "", checked: "true"});
                    }else {
                        setCredentials({...currentOrganisateur, email: "", emailConfirm: "", checked: "false"});
                    }
                }
            }
            setLoading(false);
        }

        fetchData();
        
    }, [currentOrganisateur] )

    useEffect( () => {
        if(addOrganisateurErrors){
               const apiErrors={};
               const violations=addOrganisateurErrors;
               if(violations && Array.isArray(violations)){
                violations.forEach(({propertyPath, message}) => { 
                  apiErrors[propertyPath]= message;
                  } );
                  setErrors(apiErrors);
               }
                      
        }
      
      }, [addOrganisateurErrors])

    useEffect( () => {
        if(addOrganisateurErrorsDesc){
            swal("Impossible de vous inscrire", addOrganisateurErrorsDesc, "error")
        }
      
      }, [addOrganisateurErrorsDesc])

    useEffect( () => {
        if(updateOrganisateurErrors){
               const apiErrors={};
               const violations=updateOrganisateurErrors;
               if(violations && Array.isArray(violations)){
                violations.forEach(({propertyPath, message}) => { 
                  apiErrors[propertyPath]= message;
                  } );
                  setErrors(apiErrors);
               }
                      
        }
      
      }, [updateOrganisateurErrors])

    useEffect( () => {
        if(updateOrganisateurErrorsDesc){
            swal("Impossible de vous inscrire", updateOrganisateurErrorsDesc, "error")
        }
      
      }, [updateOrganisateurErrorsDesc])
 
    useEffect( () => {  

        if(currentEvenement){
               setEvent(currentEvenement); 
        } 

     }, [currentEvenement])

    //Récupération d'un event
    const fetchEvent= async id  => {
        try {
               await getEventById(id);
               if(!editing){
                setLoading(false);
               }
        } catch (error) {
               toast.error("Impossible de charger l'évenement");  
               Navigate("/");
        }
    }

    // Chargement de l'event au chargement du composent ou changement de l'id  
    useEffect( () => {
        setLoading(true);
        fetchEvent(id);
    }, [id])

    const onSignup = async (e) =>{
        e.preventDefault();

        setErrors({});
        clearMsgAddOrg();

        if(credentials.email !== credentials.emailConfirm){
                toast.error("Une erreur est survenue !");
                setErrors({errors, emailConfirm: "Les deux adresses email doivent être identiques"});
        }else{

            if(editing){

                try {

                    let organisateur= credentials;

                    if(currentOrganisateur.checked.toString() !== organisateur.checked){

                        if(organisateur.checked === "true"){
                            organisateur.checked= true;
                        } else {
                            organisateur.checked= false;
                        }

                    } else{
                        if(organisateur.checked === "true"){
                            organisateur.checked= true;
                        } else {
                            organisateur.checked= false;
                        }
                    }

                    await updateOrganisateur(idOrateur, organisateur, event);
                    //if response
                    setErrors({});
                    swal("L'organisateur est à jour", "success");
                }   catch (err) {
                    toast.error("Une erreur est survenue !");
                }

            } else{
                try {

                    let organisateur= credentials;

                    if(organisateur.checked === "true"){
                        organisateur.checked= true;
                    } else {
                        organisateur.checked= false;
                    }

                    await addOrateur(organisateur, event, userId);
                    //if response
                    setErrors({});
                    swal("L'orateur est bien enregistré", "success");
                    setIsprintable(true);
                }   catch (err) {
                    toast.error("Une erreur est survenue !");
    
                }
            }

        }
            
    }

    //Gestion des champs
    const handleChangeFields = ({currentTarget}) =>{
        const {value, name}= currentTarget;
        setCredentials({ ...credentials, [name]: value });
    }

    useLayoutEffect(() => {
        return () => {
               setErrors({});
               clearMsgAddOrg();
               setEditing(false);
        }
    }, [])

    const handlePrint = async () => {
        try {
                await printOrgPdf(currentOrganisateur.id, currentOrganisateur.evenement.id);
        } catch(err){
            toast.error("Erreur lors du chargement du pdf");
        }
        
    };

    const handlePrintBrowser = async () => {
        try {
                await printOrgPdfBrowser(currentOrganisateur.id, currentOrganisateur.evenement.id);
        } catch(err){
            toast.error("Erreur lors du chargement du pdf");
        }
    };

    const handleDownload = async () => {
        try {
                await downloadOrgPdf(currentOrganisateur.id, currentOrganisateur.evenement.id);
        } catch(err){
            toast.error("Erreur lors du chargement du pdf");
        }
    };

    return (
        <>
            <div className="row page-titles">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active"><Link to={"/" + id + "/admin/orateurs"}> Orateurs</Link></li>
                    <li className="breadcrumb-item active"><Link to={"#"}> {editing ? "Modification": "Ajout"} </Link></li>
                </ol>
            </div>

            <div className="row tableRow ajoutInvite">
                <div className="col-lg-12">

                    <div className="card">

                        <div className="card-header">
                            <h4 className="card-title">
                            {editing ? "Modification | "
                            : "Ajout de l'orateur"}

                            {editing && !loading && currentOrganisateur &&
                            currentOrganisateur.prenom + " " + currentOrganisateur.nom}

                             </h4>

                                {isPrintable &&
                                <div className="printBtn">
                                    <Link className="btn shadow btn-md sharp me-1 btn-success"
                                    onClick={() => handlePrint()}>
                                        <i class="fas fa-print fa-lg"></i>
                                    </Link>
                                    <Link className="btn btn-primary shadow btn-md sharp me-1" 
                                    onClick={() => handlePrintBrowser()}>
                                        <i class="fas fa-file-pdf fa-lg"></i>
                                    </Link>

                                    <Link className="btn btn-info shadow btn-md sharp me-1" 
                                    onClick={() => handleDownload()}>
                                        <i class="fas fa-file-download fa-lg"></i>
                                    </Link>
                                </div>
                                }

                                <Link to={"/" + id + "/admin/orateurs"} className="btn btn-primary">
                                    <i class="fas fa-undo"  style={{ marginRight: "5px",}}></i>  
                                    Retour à la liste
                                </Link>

                        </div>

                    </div>

                </div>

                <div className='eventPage'>

        <div className="row">
                        <div className="col-lg-12">
                            <div className="card">

                                <div className="eventPageFragment">
                                    {!loading &&
                                    <Container>

                                        <Row>

                                            <Col md="12">
                                            <form onSubmit={onSignup}>
                                                
                                                <Row>

                                                <Col md="6">
                                                    <Field
                                                        name="prenom"
                                                        value={credentials.prenom}
                                                        onChange={handleChangeFields}
                                                        label= "Prénom *"
                                                        placeholder="Prénom"
                                                        error={errors.prenom}
                                                        type="text" 
                                                        required="true" 
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    <Field
                                                        name="nom"
                                                        value={credentials.nom}
                                                        onChange={handleChangeFields}
                                                        label= "Nom *"
                                                        placeholder="Nom"
                                                        error={errors.nom}
                                                        type="text"
                                                        required="true" 
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    <Field
                                                        name="email"
                                                        value={credentials.email}
                                                        onChange={handleChangeFields}
                                                        label= "Email *"
                                                        placeholder="Email *"
                                                        error={errors.email}
                                                        type="email" 
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    <Field
                                                        name="emailConfirm"
                                                        value={credentials.emailConfirm}
                                                        onChange={handleChangeFields}
                                                        label= "Confirmez l'email *"
                                                        placeholder="Confirmez l'email *"
                                                        error={errors.emailConfirm}
                                                        type="email" 
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    <Field
                                                        name="tel"
                                                        value={credentials.tel}
                                                        onChange={handleChangeFields}
                                                        label= "Téléphone *"
                                                        placeholder="Téléphone *"
                                                        error={errors.tel}
                                                        type="tel"
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    <Field
                                                        name="adresse"
                                                        value={credentials.adresse}
                                                        onChange={handleChangeFields}
                                                        label= "Adresse professionnelle *"
                                                        placeholder="Adresse professionnelle"
                                                        error={errors.adresse}
                                                        type="text"
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    <Select name="wilaya"
                                                    value={credentials.wilaya}
                                                    label="Wilaya *"
                                                    onChange={handleChangeFields}
                                                    required="true"
                                                    >
                                                    <option value="" defaultChecked>
                                                        Veuillez sélectionner une wilaya
                                                    </option> 

                                                    {optionsWilaya.map(wilaya  =>  (
                                                    <option key={wilaya.id} value={wilaya.name}>
                                                        {wilaya.name + " - " + wilaya.code + " - " + wilaya.ar_name}
                                                        </option> 
                                                        ))}
                              
                                                    </Select>
                                                </Col>

                                                <Col md="6">
                                                    <Select name="specialite"
                                                        value={credentials.specialite}
                                                        label="Spécialité"
                                                        onChange={handleChangeFields}
                                                        >
                                                    <option value="" defaultChecked>
                                                        Veuillez sélectionner une spécialité
                                                    </option> 

                                                    {listeSpecialites
                                                    .sort(function(a, b) {
                                                        if(a.toLowerCase() < b.toLowerCase()) return -1;
                                                        if(a.toLowerCase() > b.toLowerCase()) return 1;
                                                        return 0;
                                                        })
                                                    .map(specialite  =>  (
                                                    <option key={specialite} value={specialite}>
                                                        {specialite}
                                                        </option> 
                                                        ))}

                                                    <option value="autre">
                                                        Autre
                                                    </option> 
                                
                                                    </Select>
                                                </Col>

                                                <Col md="6">
                                                    <Select name="titre"
                                                        value={credentials.titre}
                                                        label="Titre professionnel"
                                                        onChange={handleChangeFields}
                                                        >

                                                    <option value="">
                                                        Autre
                                                    </option>

                                                    <option key="Dr" value="Dr">
                                                        Dr
                                                    </option>

                                                    <option key="Pr" value="Pr">
                                                        Pr
                                                    </option>

                                                    </Select>
                                                </Col>

                                                <Col md="6">
                                                    <Select name="checked"
                                                        value={credentials.checked}
                                                        label="Validé à l'entrée"
                                                        onChange={handleChangeFields}
                                                        required="true"
                                                        >

                                                    <option value="">
                                                        Veuillez sélectionner une option
                                                    </option> 

                                                    <option value="true" defaultChecked>
                                                        Oui
                                                    </option> 

                                                    <option value="false">
                                                        Non
                                                    </option> 
                                
                                                    </Select>
                                                </Col>


                                                </Row>

                                                <div className="text-center">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary btn-lg"
                                                    >
                                                        {editing ? "Enregistrer" : "Ajouter"}
                                                    </button>
                                                </div>
                                            </form>
                                            </Col>

                                        </Row>

                                    </Container>
                                    }
                                    
                                    
                                </div>

                            </div>
                        </div>
        </div>

        </div>

            </div>

        </>
    );
};

const mapStateToProps = (state) => {
    return {
        evenements: state.evenementState.evenements,
        currentEvenement: state.evenementState.currentEvenement,
        addOrganisateurErrors: state.organisateurState.addOrganisateurErrors,
        addOrganisateurErrorsDesc: state.organisateurState.addOrganisateurErrorsDesc,
        updateOrganisateurErrors: state.organisateurState.updateOrganisateurErrors,
        updateOrganisateurErrorsDesc: state.organisateurState.updateOrganisateurErrorsDesc,
        userId: state.auth.user.id,
        currentOrganisateur: state.organisateurState.currentOrganisateur
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        { getEventById, 
        addOrateur, 
        updateOrganisateur, 
        clearMsgAddOrg, 
        getOrganisateurById,
        printOrgPdf,
        printOrgPdfBrowser,
        downloadOrgPdf },
        dispatch,
    );
};


export default connect(mapStateToProps, mapDispatchToProps)(Orateur); 