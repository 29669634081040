import React from 'react';
import ReactDOM from 'react-dom/client';
//import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {Provider} from 'react-redux';
import {store} from './store/store';
import  ThemeContext  from "./context/ThemeContext"; 
import Login from './jsx/pages/Login';
import  EvenementsListe from './jsx/components-public/Evenements/EvenementsListe';
import Inscription from './jsx/components-public/Evenements/Inscription';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EventSelection from './jsx/components/EventSelection';
import CheckoutProcess from './jsx/components-public/Evenements/checkout/CheckoutProcess';
import CheckoutDone from './jsx/components-public/Evenements/checkout/CheckoutDone';
import Event from './jsx/components/evenements/Event';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store = {store}>
      <BrowserRouter>
        <ThemeContext>
        <Routes>
        <Route path='/login/*' element={<Login />} />
        <Route path='/admin/create-event' element={<Event />} />
        <Route path='/admin' element={<EventSelection />} />
        <Route path='/:id/admin/*' element={<App />} />
        <Route path='/' element={<EvenementsListe />} />
        <Route path='/:id/checkout/:email' element={<CheckoutProcess />} />
        <Route path='/checkout/:status?' element={<CheckoutDone />} />
        <Route path='/:id' element={<Inscription />} />

      </Routes>
        </ThemeContext>
      </BrowserRouter>
      <ToastContainer position={toast.POSITION.BOTTOM_LEFT}/>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
