import Axios from "axios";
import authHeader from "../../helpers/auth-header";
import { PARTENAIRES_API } from "../../services/config";

export const ADD_PARTENAIRE_FAIL= "ADD_PARTENAIRE_FAIL";
export const ADD_PARTENAIRE_FAIL_DESC= "ADD_PARTENAIRE_FAIL_DESC";
export const UPDATE_PARTENAIRE_FAIL= "UPDATE_PARTENAIRE_FAIL";
export const UPDATE_PARTENAIRE_FAIL_DESC= "UPDATE_PARTENAIRE_FAIL_DESC";


export const addPartenaire = (partenaire, file) => (dispatch) => {

    let formData = new FormData();
    formData.append('nom', partenaire.nom);
    formData.append('remerciements', partenaire.remerciements);
    formData.append('evenement', partenaire.evenement);
    
    if(file && file !="handleDelete"){
      formData.append('file', file);
    }

  return Axios.post(PARTENAIRES_API, formData,{ headers: authHeader() }).then(
    (data) => {

      dispatch({
        type: ADD_PARTENAIRE_FAIL,
        addPartenaireErrors: null
              });
      return Promise.resolve();
    },
    (error) => {

      const message =error.response.data;
      const {violations} = message;
      const errorDescription = message["hydra:description"];
      if(violations){

        dispatch({
          type: ADD_PARTENAIRE_FAIL,
          addPartenaireErrors: violations
                });
                throw new Error(error);
      } else if(errorDescription){
        dispatch({
          type: ADD_PARTENAIRE_FAIL_DESC,
          addPartenaireErrorDesc: errorDescription
                });
                throw new Error(error);
      } else{
        throw new Error(error)
      }
     
    }    
  );

};

export const updatePartenaire = (id, partenaire, file) => (dispatch) => {

    let formData = new FormData();
    formData.append('nom', partenaire.nom);
    formData.append('remerciements', partenaire.remerciements);
    formData.append('evenement', partenaire.evenement);

    if(file && file !="handleDelete"){
      formData.append('file', file);
    }

  return Axios.post(PARTENAIRES_API + "/" + id, formData,{ headers: authHeader() }).then(
    (data) => {
      
      dispatch({
        type: UPDATE_PARTENAIRE_FAIL,
        updatePartenaireErrors: null
              });
      return Promise.resolve();
    },
    (error) => {
  
      const message =error.response.data;
      const {violations} = message;
      const errorDescription = message["hydra:description"];
      if(violations){
  
        dispatch({
          type: UPDATE_PARTENAIRE_FAIL,
          updatePartenaireErrors: violations
                });
                throw new Error(error);
      } else if(errorDescription){
        dispatch({
          type: UPDATE_PARTENAIRE_FAIL_DESC,
          updatePartenaireErrorDesc: errorDescription
                });
                throw new Error(error);
      } else{
        throw new Error(error)
      }
     
    }    
  );
  
  };

  export const clearMsgAddPartenaire = () => (dispatch) => {

    dispatch({
      type: ADD_PARTENAIRE_FAIL,
      addPartenaireErrors: null
            });
  
    dispatch({
      type: ADD_PARTENAIRE_FAIL_DESC,
      addPartenaireErrorDesc: null
            });
  
    dispatch({
      type: UPDATE_PARTENAIRE_FAIL,
      updatePartenaireErrors: null
            });
        
    dispatch({
      type: UPDATE_PARTENAIRE_FAIL_DESC,
      updatePartenaireErrorsDesc: null
            });
  
    return Promise.resolve();
  
  };

  export const deleteImagePartenaire = (partenaire) => (dispatch) => {

    let formData = new FormData();
    formData.append('id', partenaire);

  return Axios.post(PARTENAIRES_API + "/delete_image", formData,{ headers: authHeader() }).then(
    (data) => {
      return Promise.resolve();
    },
    (error) => {

        throw new Error(error)
    }    
  );

};
