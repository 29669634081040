// @flow 
import React,{useState, useEffect, useRef} from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getInvites } from '../../../store/actions/InviteActions';
import { toast } from 'react-toastify';
import Pagination from '../PaginationTable';

import WilayaOfAlgeria from "../../../helpers/Wilaya_Of_Algeria.json";
import listeSpecialites from "../../../helpers/Liste_specialites.json";
import { Badge, Button, Col, Container, Dropdown, Modal, Row } from 'react-bootstrap';
import Select from '../Forms/Select';
import { formatDateTime } from '../../../helpers/FormatHelpers';
import { downloadPdf, printPdf, printPdfBrowser } from '../../../store/actions/PdfActions';
import { deleteInvite } from '../../../store/actions/InviteActions';
import { updateCheckInscription } from '../../../store/actions/InscriptionActions';
import * as XLSX from 'xlsx/xlsx.mjs';

const Invites = ({getInvites, 
    invites, 
    printPdf,
    printPdfBrowser, 
    downloadPdf, 
    currentPdf, 
    deleteInvite,
    updateCheckInscription,
    currentUser}) => {

    const [currentPage, setCurrentPage]= useState(1);
    const [search, setSearch]= useState("");
    const [searchWilaya, setSearchWilaya]= useState("");
    const [searchSpecialite, setSearchSpecialite]= useState("");
    const [loading, setLoading]= useState(true);
    const [invitesListe, setInvitesListe]= useState([]);
    const [error, setError]= useState();
    const [confirmationVisible, setConfirmationVisible]= useState();
    const [inviteForDelete, setInviteForDelete]= useState();
    const [inviteForDeleteInfo, setInviteForDeleteInfo]= useState();
    const [inscriptionForCheck, setInscriptionForCheck]= useState();
    const [inviteForCheck, setInviteForCheck]= useState();
    const [confirmationCheckVis, setConfirmationCheckVis]= useState();
    const inviteState = useSelector(state => state.inviteState);


    const optionsWilaya = WilayaOfAlgeria;
    const optionsSpecialite= listeSpecialites;

    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    let {id}= params;


    //Get invites
    const fetchInvites = async () => {
        try {
            getInvites(id);
            setLoading(false);
        } catch (error) {
            toast.error("Erreur lors du chargement des invités");
        }
    }


    useEffect(() => {
        fetchInvites();
     }, []);

    //Get invites au chargement
    useEffect( () => {
        if(invites){
            if(invites.length==0){
            }else{
                setInvitesListe(invites);
            }
        }
        

    }, [invites] )

    // Detecter le changement dans le store redux pour mettre à jour le componsant - Checkin
    useEffect( () => {
        setInvitesListe(inviteState.invites);
    }, [inviteState] )

    //Change current page
    const handleChangePage = page => setCurrentPage(page);
    
    //Recherche
    const handleSearch = ({currentTarget}) => { 
        const value= currentTarget.value;
        setSearch(value);
        setCurrentPage(1);
    }

    //Recherche Wilaya
    const handleSearchWilaya = ({currentTarget}) => { 
        const value= currentTarget.value;
        setSearchWilaya(value);
        setCurrentPage(1);
    }

    //Recherche Spécialité
    const handleSearchSpecialite = ({currentTarget}) => { 
        const value= currentTarget.value;
        setSearchSpecialite(value);
        setCurrentPage(1);
    }

    // Rénitialiser les filtres
    const resetFilters= () => {
        setSearch("");
        setSearchSpecialite("");
        setSearchWilaya("");
    }

    const itemsPerPage =10;
    
    //Filtrage des types en fonction de la recherche
    let filtredInvites;

    if(invitesListe && invitesListe.length >0){         
        filtredInvites= invitesListe.filter(
            t =>
            (t.id && t.id.toString().includes(search)) ||
            (t.nom && t.nom.toLowerCase().includes(search.toLowerCase())) ||
            (t.prenom && t.prenom.toLowerCase().includes(search.toLowerCase())) ||
            (t.email && t.email.toLowerCase().includes(search.toLowerCase())) ||
            (t.tel && t.tel.toLowerCase().includes(search.toLowerCase())) ||
            (t.adresse && t.adresse.toLowerCase().includes(search.toLowerCase()))
        );
    }

    if(searchWilaya != ""){
        filtredInvites= filtredInvites.filter(
            c =>
            (c.wilaya && c.wilaya.toLowerCase().includes(searchWilaya.toLowerCase()))
        )
    };

    if(searchSpecialite != ""){
        filtredInvites= filtredInvites.filter(
            c =>
            (c.specialite && searchSpecialite != "" && c.specialite.toLowerCase().includes(searchSpecialite.toLowerCase()))
        );
    }

    // Au cas ou le résultat de la recherche serait null
    useEffect( () => {
        if(filtredInvites && filtredInvites.length == 0 && search!= ""){
            setError("Désolé, aucun résultat n'a été trouvé");
        }else if (filtredInvites && filtredInvites.length>0 ){
            setError();
        }
    }, [filtredInvites] )
    
    //Pagination
    let paginatedInvites;
    if (filtredInvites && filtredInvites.length >0){
        paginatedInvites= Pagination.getData(filtredInvites, currentPage, itemsPerPage);
    }

    const handlePrint = async (idInscription, idEvenement, idInvite) => {
        try {
            await printPdf(idInscription, idEvenement, idInvite);
        } catch(err){
            toast.error("Erreur lors du chargement du pdf");
        }
    };

    const handlePrintBrowser = async (idInscription, idEvenement, idInvite) => {
        try {
            await printPdfBrowser(idInscription, idEvenement, idInvite);
        } catch(err){
            toast.error("Erreur lors du chargement du pdf");
        }
    };

    const handleDownload = async (idInscription, idEvenement, idInvite) => {
        try {
            await downloadPdf(idInscription, idEvenement, idInvite);
        } catch(err){
            toast.error("Erreur lors du chargement du pdf");
        }
    };

    const handleDelete = (item) => {
        if(currentUser.roles[0]!=="ROLE_ADMIN"){
            toast.error("Vous n'avez pas l'autorisation nécéssaire");
        }else{
        setInviteForDelete(item.id);
        setInviteForDeleteInfo(item.prenom + " " + item.nom);
        setConfirmationVisible(true);
        }
    }

    const handleDeleteConfirm = async id => {
        setConfirmationVisible(false)
        try {
            await deleteInvite(id);
            toast.success("L'invité a bien été supprimé");
        } catch (error) {
            toast.error("Une erreur est survenue");
        }

    }

    const handleCheckInscription = (idInvite, idInscription) => {
       setInviteForCheck(idInvite);
       setInscriptionForCheck(idInscription);
       setConfirmationCheckVis(true);
    }

    const handleCheckInscriptionConfirm= async (idInvite, idInscription) => {
        setConfirmationCheckVis(false)
        try {
            await updateCheckInscription(idInvite, idInscription, true);
            toast.success("Chek-in validé");
        } catch (error) {
            toast.error("Une erreur est survenue");
        }
    }

    const exportAll = () => {
        if (invitesListe && invitesListe.length > 0) {
            // Filtrer et formater les données
            const filteredData = invitesListe.map(item => {
                const inscription = item.inscriptions && item.inscriptions.length > 0 ? item.inscriptions[0] : {};
                const id_inscription = inscription.id || '';
                const isChecked = inscription.checked ? "Validé" : "Non validé";
                const isPaid = inscription.paid ? "Payé" : "Non payé";
                const date_inscription = formatDateTime(inscription.date_inscription) || '';
                const { nom, prenom, email, tel, adresse, wilaya, specialite, titre } = item;
                const ateliers = item.inscriptions && item.inscriptions.length > 0 && item.inscriptions[0].ateliers ? item.inscriptions[0].ateliers.length : 0;
                return { "id": id_inscription, "Validation": isChecked, "Paiement": isPaid, prenom, nom, email, tel, adresse, wilaya, specialite, titre, "Inscrit Le": date_inscription, "Ateliers": ateliers };
            });
    
            // Créer une feuille de calcul à partir des données filtrées
            const worksheet = XLSX.utils.json_to_sheet(filteredData);
    
            // Créer un classeur et ajouter la feuille de calcul
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Feuille 1');
    
            // Écrire le classeur dans un fichier Excel
            XLSX.writeFile(workbook, 'liste_invites.xlsx');
        } else {
            toast.error("La liste des invités est vide.");
        }
    };

    const exportChecked = () => {
        if (invitesListe && invitesListe.length > 0) {
            const filteredData = invitesListe.filter(item => {
                const inscription = item.inscriptions && item.inscriptions.length > 0 ? item.inscriptions[0] : {};
                return inscription.checked;
            }).map(item => {
                const inscription = item.inscriptions && item.inscriptions.length > 0 ? item.inscriptions[0] : {};
                const id_inscription = inscription.id || '';
                const isChecked = inscription.checked ? "Validé" : "Non validé";
                const isPaid = inscription.paid ? "Payé" : "Non payé";
                const date_inscription = formatDateTime(inscription.date_inscription) || '';
                const { nom, prenom, email, tel, adresse, wilaya, specialite, titre } = item;
                const ateliers = item.inscriptions && item.inscriptions.length > 0 && item.inscriptions[0].ateliers ? item.inscriptions[0].ateliers.length : 0;
                return { "id": id_inscription, "Validation": isChecked, "Paiement": isPaid, prenom, nom, email, tel, adresse, wilaya, specialite, titre, "Inscrit Le": date_inscription, "Ateliers": ateliers };
            });
    
            const worksheet = XLSX.utils.json_to_sheet(filteredData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Feuille 1');
            XLSX.writeFile(workbook, 'liste_invites_valides.xlsx');
        } else {
            toast.error("La liste des invités validés est vide.");
        }
    };

    const exportPaid = () => {
        if (invitesListe && invitesListe.length > 0) {
            const filteredData = invitesListe.filter(item => {
                const inscription = item.inscriptions && item.inscriptions.length > 0 ? item.inscriptions[0] : {};
                return inscription.paid;
            }).map(item => {
                const inscription = item.inscriptions && item.inscriptions.length > 0 ? item.inscriptions[0] : {};
                const id_inscription = inscription.id || '';
                const isChecked = inscription.checked ? "Validé" : "Non validé";
                const isPaid = inscription.paid ? "Payé" : "Non payé";
                const date_inscription = formatDateTime(inscription.date_inscription) || '';
                const { nom, prenom, email, tel, adresse, wilaya, specialite, titre } = item;
                const ateliers = item.inscriptions && item.inscriptions.length > 0 && item.inscriptions[0].ateliers ? item.inscriptions[0].ateliers.length : 0;
                return { "id": id_inscription, "Validation": isChecked, "Paiement": isPaid, prenom, nom, email, tel, adresse, wilaya, specialite, titre, "Inscrit Le": date_inscription, "Ateliers": ateliers };
            });
    
            const worksheet = XLSX.utils.json_to_sheet(filteredData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Feuille 1');
            XLSX.writeFile(workbook, 'liste_invites_payes.xlsx');
        } else {
            toast.error("La liste des invités payés est vide.");
        }
    };

    return (
        <>

            <div className="row page-titles">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active"><Link to={"#"}> Visiteurs</Link></li>
                </ol>
            </div>

            <div className="row tableRow">
                <div className="col-lg-12">

                    <div className="card">

                        <div className="card-header">
                            <div style={{ display: "flex"}}>
                                <h4 className="card-title">Liste des visiteurs</h4>
                                {currentUser && currentUser.roles[0]=="ROLE_ADMIN" ? 
                                <Dropdown style={{ marginLeft: "5px", marginTop: "-5px"}}>
                                    <Dropdown.Toggle variant="success" id="exportDropdown">
                                        <i className="fas fa-file-export" style={{ marginRight: "5px" }}></i> Exporter
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => exportAll()}>Exporter tous</Dropdown.Item>
                                        <Dropdown.Item onClick={() => exportChecked()}>Exporter visiteurs validés</Dropdown.Item>
                                        <Dropdown.Item onClick={() => exportPaid()}>Exporter visiteurs payés</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                :
                                null
                                }
                            </div>
                            <Link to={location.pathname + "/new"} className="btn btn-primary">Ajouter</Link>
                        </div>
                        
                        <div className="card-body">
                            <div className="table-responsive ticket-table">
                                <div id="ticket_wrapper" className="dataTables_wrapper no-footer">
                                    
                                    <Container>
                                        <Row>
                                            <Col md={7}>
                                                <div className="col-12 d-flex align-items-center">
                                                    <label className="me-2 mt-2">Recherche:</label>
                                                    <input 
                                                        className="form-control"
                                                        type="search" 
                                                        placeholder="Nom, prénom, email, adresse ..." 
                                                        onChange={handleSearch}
                                                        value={search}
                                                    />
                                                </div>
                                            </Col>

                                            <Col md={2} className="divSelect">
                                                <Select name="wilaya"
                                                    //label="Wilaya"
                                                    onChange={handleSearchWilaya}
                                                    value={searchWilaya}
                                                    >
                                                <option value="" defaultChecked>
                                                    Localisation
                                                </option> 

                                                {optionsWilaya
                                                .map(wilaya  =>  (
                                                <option key={wilaya.id} value={wilaya.name}>
                                                    {wilaya.name + " - " + wilaya.code + " - " + wilaya.ar_name}
                                                    </option> 
                                                    ))}
                                                
                                                </Select>
                                            </Col>

                                            <Col md={2} className="divSelect">
                                                <Select name="specialite"
                                                    onChange={handleSearchSpecialite}
                                                    value={searchSpecialite}
                                                    >
                                                <option value="" defaultChecked>
                                                    Spécialité
                                                </option> 

                                                {optionsSpecialite
                                                .sort(function(a, b) {
                                                    if(a.toLowerCase() < b.toLowerCase()) return -1;
                                                    if(a.toLowerCase() > b.toLowerCase()) return 1;
                                                    return 0;
                                                    })
                                                .map(specialite  =>  (
                                                <option key={specialite} value={specialite}>
                                                    {specialite}
                                                    </option> 
                                                    ))}
                                                
                                                </Select>
                                            </Col>

                                            <Col md={1}>

                                                <Button className="btn btn-primary shadow btn-md sharp me-1 restartBtn" onClick={resetFilters}>
                                                    <i class="fas fa-undo"></i>                                                
                                                </Button>
                                            </Col>

                                        </Row>
                                    </Container>
                                        

                                    <table id="example" className="display dataTablesCard table-responsive-xl dataTable no-footer w-100">
                                        
                                    { (paginatedInvites && paginatedInvites.length>0) &&
                                        <thead>
                                            <tr>
                                                <th></th>
												<th>Inscription</th>
												<th>Paiement</th>
												<th>Nom</th>
												<th>Email</th>
												<th>Tel</th>
												<th>Adresse</th>
												<th>Wilaya</th>
												<th>Spécialité</th>
												<th>Titre</th>
												<th>Inscrit le:</th>
												<th>État:</th>
												<th>Actions</th>  
												<th>Ateliers</th>
                                            </tr>
                                        </thead>
                                    }

                                        {!loading && (
                                        <tbody>
                                            {paginatedInvites &&  paginatedInvites.map((item, index)=>(
                                                <tr key={index}>

                                                    <td>
                                                        <Link className={item.inscriptions[0] && item.inscriptions[0].checked ? "btn shadow btn-md sharp me-1 btn-success" : "btn shadow btn-md sharp me-1 btn-danger"} 
                                                        onClick={() => handlePrint(item.inscriptions[0].id, item.evenement.id, item.id)}>
                                                            <i class="fas fa-print fa-2x"></i>
                                                        </Link>
                                                    </td>

                                                    <td>
                                                        {item.inscriptions[0] && 
                                                            (item.inscriptions[0].checked ?
                                                                <span className="badge badge-success badge-lg">{item.inscriptions[0].id}</span>
                                                            :
                                                                <span className="badge badge-danger badge-lg">{item.inscriptions[0].id}</span>
                                                            )
                                                            }
                                                    </td>

                                                    <td>
                                                        {item.inscriptions[0] && item.inscriptions[0].paid ?
                                                                <span className="badge badge-success badge-lg">Payé</span>
                                                            :
                                                                <span className="badge badge-danger badge-lg">Non payé</span>
                                                            }
                                                    </td>
                                                    
                                                    <td>						
                                                            <span className="badge light badge-success">
                                                                <Link to={"#"} className="h5">{item.prenom + " " + item.nom}</Link>
                                                            </span>
                                                        
                                                    </td>
                                                    <td>{item.email}</td>
                                                    <td>{item.tel}</td>
                                                    <td>{item.adresse}</td>
                                                    <td>{item.wilaya}</td>
                                                    <td>{item.specialite}</td>
                                                    <td>{item.titre}</td>
                                                    <td>{item.inscriptions[0] && item.inscriptions[0].date_inscription && formatDateTime(item.inscriptions[0].date_inscription)}</td>
                                                    
                                                    
                                                    <td>
                                                        {item.inscriptions[0] && item.inscriptions[0].checked ?
                                                            <p className="badge badge-success badge-lg">Validé</p>
                                                        :
                                                            <Link className="badge badge-primary badge-xl" onClick={() => handleCheckInscription(item.id ,item.inscriptions[0].id)}>Valider</Link>
                                                        }
                                                    </td>
                                                    
                                                    <td>
                                                        <div className="d-flex">

                                                            <Link className="btn btn-primary shadow btn-md sharp me-1" 
                                                            onClick={() => handlePrintBrowser(item.inscriptions[0].id, item.evenement.id, item.id)}>
                                                                <i class="fas fa-file-pdf fa-lg"></i>
                                                            </Link>

                                                            <Link className="btn btn-info shadow btn-md sharp me-1" 
                                                            onClick={() => handleDownload(item.inscriptions[0].id, item.evenement.id, item.id)}>
                                                                <i class="fas fa-file-download fa-lg"></i>
                                                            </Link>

                                                            <Link to={location.pathname + "/" + item.id} className="btn btn-warning shadow btn-md sharp me-1"
                                                            >
                                                                <i className="fas fa-pencil-alt fa-lg"></i>
                                                            </Link>

                                                            <Link to={"#"} className="btn btn-danger shadow btn-md sharp me-1"
                                                            onClick={() => handleDelete(item)}>
                                                                <i className="fa fa-trash fa-lg"></i>
                                                            </Link>
                                                        </div>
                                                    </td>

                                                    <td className="text-center">
                                                        <span className="badge badge-info badge-lg">
                                                            {item.inscriptions[0] && 
                                                            item.inscriptions[0].ateliers && 
                                                            item.inscriptions[0].ateliers.length > 0 ?
                                                            item.inscriptions[0].ateliers.length
                                                            :
                                                            0 }
                                                        </span>
                                                    </td>

                                                </tr>
                                            ))}
                                            
                                        </tbody>
                                        )}

                                        
                                        
                                    </table>

                                    {filtredInvites ? filtredInvites.length > itemsPerPage && ( 
                                        <Pagination currentPage={currentPage} 
                                        itemsPerPage={itemsPerPage} 
                                        length={filtredInvites.length} 
                                        onPageChanged={handleChangePage} />) 
                                        : null}

                                    {/* <!-- Modal --> */}
                                    <Modal className="fade" show={confirmationVisible}>
                                    <Modal.Header>
                                        <Modal.Title>Supression de l'invité {inviteForDeleteInfo}</Modal.Title>
                                        <Button
                                            variant=""
                                            className="close"
                                        >
                                            <span>&times;</span>
                                        </Button>
                                    </Modal.Header>
                                    <Modal.Body>êtes-vous sûr de vouloir continuer ?</Modal.Body>
                                    <Modal.Footer>
                                        <Button
                                            onClick={() => setConfirmationVisible(false)}
                                            variant="danger light"
                                        >
                                            Annuler
                                        </Button>
                                        <Button variant="primary"  onClick={() => handleDeleteConfirm(inviteForDelete)}>
                                            Continuer
                                        </Button>
                                    </Modal.Footer>
                                    </Modal>

                                    {/* <!-- Modal --> */}
                                    <Modal className="fade" show={confirmationCheckVis}>
                                    <Modal.Header>
                                        <Modal.Title>Check-in</Modal.Title>
                                        <Button
                                            variant=""
                                            className="close"
                                        >
                                            <span>&times;</span>
                                        </Button>
                                    </Modal.Header>
                                    <Modal.Body>êtes-vous sûr de vouloir continuer ?</Modal.Body>
                                    <Modal.Footer>
                                        <Button
                                            onClick={() => setConfirmationCheckVis(false)}
                                            variant="danger light"
                                        >
                                            Annuler
                                        </Button>
                                        <Button variant="primary"  onClick={() => handleCheckInscriptionConfirm(inviteForCheck, inscriptionForCheck)}>
                                            Continuer
                                        </Button>
                                    </Modal.Footer>
                                    </Modal>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: "",
        invites: state.inviteState.invites,
        currentPdf: state.pdfState.currentPdf,
        currentUser: state.auth.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        { getInvites, printPdf, printPdfBrowser, downloadPdf, deleteInvite, updateCheckInscription },
        dispatch,
    );
};


export default connect(mapStateToProps, mapDispatchToProps)(Invites); 