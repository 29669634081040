import { ADD_ATTESTATION_FAIL, ADD_ATTESTATION_FAIL_DESC, ADD_ATTESTATION_SUCCESS, DELETE_ATTESTATION, FETCH_ATTESTATIONS, FETCH_CURRENT_ATTESTATION, UPDATE_ATTESTATION_FAIL, UPDATE_ATTESTATION_FAIL_DESC, UPDATE_ATTESTATION_SUCCESS } from "../actions/AttestationActions";

  
  const defaultState = {};
  
  export default function (state = defaultState, action) {

    const { type, payload } = action;
    
    switch (type) {

      case FETCH_ATTESTATIONS:
        return {
          ...state,
          attestations: action.attestations
        };

      case FETCH_CURRENT_ATTESTATION:
        return {
          ...state,
          currentAttestation: action.currentAttestation
        };

      case ADD_ATTESTATION_SUCCESS:
        return {
          ...state,
          currentAttestation: action.currentAttestation
        };
      
      case ADD_ATTESTATION_FAIL:
        return {
          ...state,
          addAttestationErrors: action.addAttestationErrors
        };
      
      case ADD_ATTESTATION_FAIL_DESC:
        return {
          ...state,
          addAttestationErrorsDesc: action.addAttestationErrorsDesc
        };

      case UPDATE_ATTESTATION_SUCCESS:
        return {
          ...state,
          currentAttestation: action.currentAttestation
        };
      
      case UPDATE_ATTESTATION_FAIL:
        return {
          ...state,
          updateAttestationErrors: action.updateAttestationErrors
        };
      
      case UPDATE_ATTESTATION_FAIL_DESC:
        return {
          ...state,
          updateAttestationErrorsDesc: action.updateAttestationErrorsDesc
        };

      case DELETE_ATTESTATION:
            const attestationsUpdated= state.attestations.filter(c  => c.id != action.id);
  
          return {
            ...state,
            attestations: attestationsUpdated,
          };

      default:
        return state;
    }
  }
  