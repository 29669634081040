import { ADD_SPONSOR_FAIL, ADD_SPONSOR_FAIL_DESC, ADD_SPONSOR_SUCCESS, DELETE_SPONSOR, FETCH_CURRENT_SPONSOR, FETCH_SPONSORS, UPDATE_SPONSOR_FAIL, UPDATE_SPONSOR_FAIL_DESC, UPDATE_SPONSOR_SUCCESS } from "../actions/SponsorActions";

  
  const defaultState = {};
  
  export default function (state = defaultState, action) {

    const { type, payload } = action;
    
    switch (type) {

      case FETCH_SPONSORS:
        return {
          ...state,
          sponsors: action.sponsors
        };

      case FETCH_CURRENT_SPONSOR:
        return {
          ...state,
          currentSponsor: action.currentSponsor
        };

      case ADD_SPONSOR_SUCCESS:
        return {
          ...state,
          currentSponsor: action.currentSponsor
        };
      
      case ADD_SPONSOR_FAIL:
        return {
          ...state,
          addSponsorErrors: action.addSponsorErrors
        };
      
      case ADD_SPONSOR_FAIL_DESC:
        return {
          ...state,
          addSponsorErrorsDesc: action.addSponsorErrorsDesc
        };

      case UPDATE_SPONSOR_SUCCESS:
        return {
          ...state,
          currentSponsor: action.currentSponsor
        };
      
      case UPDATE_SPONSOR_FAIL:
        return {
          ...state,
          updateSponsorErrors: action.updateSponsorErrors
        };
      
      case UPDATE_SPONSOR_FAIL_DESC:
        return {
          ...state,
          updateSponsorErrorsDesc: action.updateSponsorErrorsDesc
        };

      case DELETE_SPONSOR:
            const sponsorsUpdated= state.sponsors.filter(c  => c.id != action.id);
  
          return {
            ...state,
            sponsors: sponsorsUpdated,
          };

      default:
        return state;
    }
  }
  