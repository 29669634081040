import Axios from "axios";
import authHeader from "../../helpers/auth-header";
import { EVENEMENTS_API } from "../../services/config";

export const FETCH_EVENEMENTS= "FETCH_EVENEMENTS";
export const FETCH_CURRENT_EVENEMENT= "FETCH_CURRENT_EVENEMENT";
export const FETCH_SELECTED_EVENEMENT= "FETCH_SELECTED_EVENEMENT";
export const ADD_EVENEMENT_FAIL= "ADD_EVENEMENT_FAIL";
export const ADD_EVENEMENT_FAIL_DESC= "ADD_EVENEMENT_FAIL_DESC";
export const UPDATE_EVENEMENT_FAIL= "UPDATE_EVENEMENT_FAIL";
export const UPDATE_EVENEMENT_FAIL_DESC= "UPDATE_EVENEMENT_FAIL_DESC";
export const DELETE_EVENEMENT= "DELETE_EVENEMENT";

  export const getEvents = () => (dispatch) => {

    return Axios.get(EVENEMENTS_API).then(
      (data) => {
        
        dispatch({
          type: FETCH_EVENEMENTS,
          evenements: data.data['hydra:member']
                });
        return Promise.resolve();
      },
      (error) => {

            throw new Error(error);
       
      }    
    );
    
 
};


export const getEventById = (id) => (dispatch) => {

  return Axios.get(EVENEMENTS_API+ "/" + id)
.then(
  (data) => {
   
    dispatch({
      type: FETCH_CURRENT_EVENEMENT,
      currentEvenement: data.data
    });

    return Promise.resolve();
  },

  (error) => {
      throw new Error(error)       
  }    
);

};


export const selectEvent = (id) => (dispatch) => {

  return Axios.get(EVENEMENTS_API+ "/" + id)
.then(
  (data) => {
   
    dispatch({
      type: FETCH_SELECTED_EVENEMENT,
      selectedEvent: data.data,
      selectedEventId: data.data.id
    });

    return Promise.resolve();
  },

  (error) => {
      throw new Error(error)       
  }    
);

};

export const addEvenement = (evenement, file) => (dispatch) => {

    let formData = new FormData();
    formData.append('nom', evenement.nom);
    formData.append('description', evenement.description);
    formData.append('dateDebut', evenement.dateDebut);
    formData.append('dateFin', evenement.dateFin);
    formData.append('lieu', evenement.lieu);
    formData.append('wilaya', evenement.wilaya);
    formData.append('tarif', evenement.tarif);
    
    if(file && file !="handleDelete"){
      formData.append('file', file);
    }

  return Axios.post(EVENEMENTS_API, formData,{ headers: authHeader() }).then(
    (data) => {

      let evenement= data.data;
      
      dispatch({
        type: ADD_EVENEMENT_FAIL,
        addEvenementErrors: null
              });
      return Promise.resolve();
    },
    (error) => {

      const message =error.response.data;
      const {violations} = message;
      const errorDescription = message["hydra:description"];
      if(violations){

        dispatch({
          type: ADD_EVENEMENT_FAIL,
          addEvenementErrors: violations
                });
                throw new Error(error);
      } else if(errorDescription){
        dispatch({
          type: ADD_EVENEMENT_FAIL_DESC,
          addEvenementErrorDesc: errorDescription
                });
                throw new Error(error);
      } else{
        throw new Error(error)
      }
     
    }    
  );

};

export const updateEvenement = (id, evenement, file) => (dispatch) => {

  let formData = new FormData();
  formData.append('nom', evenement.nom);
  formData.append('description', evenement.description);
  formData.append('dateDebut', evenement.dateDebut);
  formData.append('dateFin', evenement.dateFin);
  formData.append('lieu', evenement.lieu);
  formData.append('wilaya', evenement.wilaya);
  formData.append('tarif', evenement.tarif);
  
  if(file && file !="handleDelete"){
    formData.append('file', file);
  }
  
return Axios.post(EVENEMENTS_API + "/" + id, formData,{ headers: authHeader() }).then(
  (data) => {

    let evenement= data.data;
    
    dispatch({
      type: UPDATE_EVENEMENT_FAIL,
      updateEvenementErrors: null
            });
    return Promise.resolve();
  },
  (error) => {

    const message =error.response.data;
    const {violations} = message;
    const errorDescription = message["hydra:description"];
    if(violations){

      dispatch({
        type: UPDATE_EVENEMENT_FAIL,
        updateEvenementErrors: violations
              });
              throw new Error(error);
    } else if(errorDescription){
      dispatch({
        type: UPDATE_EVENEMENT_FAIL_DESC,
        updateEvenementErrorDesc: errorDescription
              });
              throw new Error(error);
    } else{
      throw new Error(error)
    }
   
  }    
);

};

export const deleteEvenement = (id) => (dispatch) => {
    
  return Axios.delete(EVENEMENTS_API+"/" + id, {headers: authHeader()}).then(
    (data) => {

      dispatch({
        type: DELETE_EVENEMENT,
        id
      });
      
      return Promise.resolve();
    },
    (error) => {
        throw new Error(error)
    }    
  );

};


export const clearMsgAddEvent = () => (dispatch) => {

  dispatch({
    type: ADD_EVENEMENT_FAIL,
    addEvenementErrors: null
          });

  dispatch({
    type: ADD_EVENEMENT_FAIL_DESC,
    addEvenementErrorDesc: null
          });

  dispatch({
    type: UPDATE_EVENEMENT_FAIL,
    updateEvenementErrors: null
          });
      
  dispatch({
    type: UPDATE_EVENEMENT_FAIL_DESC,
    updateEvenementErrorDesc: null
          });

  return Promise.resolve();

};

export const deleteImageEvenement = (evenement) => (dispatch) => {

  let formData = new FormData();
  formData.append('id', evenement);

return Axios.post(EVENEMENTS_API + "/delete_image", formData,{ headers: authHeader() }).then(
  (data) => {
    return Promise.resolve();
  },
  (error) => {

      throw new Error(error)
  }    
);

};