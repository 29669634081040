import Axios from "axios";
import authHeader from "../../helpers/auth-header.js";
import { ADHERENTS_API, PDF_CARTE_ADHERENT_API} from "../../services/config.js";
import { base64ToBlob } from "./PdfActions.jsx";

export const FETCH_ADHERENTS= "FETCH_ADHERENTS";
export const FETCH_CURRENT_ADHERENT= "FETCH_CURRENT_ADHERENT";
export const ADD_ADHERENT_SUCCESS= "ADD_ADHERENT_SUCCESS";
export const ADD_ADHERENT_FAIL= "ADD_ADHERENT_FAIL";
export const ADD_ADHERENT_FAIL_DESC= "ADD_ADHERENT_FAIL_DESC";
export const UPDATE_ADHERENT_SUCCESS= "UPDATE_ADHERENT_SUCCESS";
export const UPDATE_ADHERENT_FAIL= "UPDATE_ADHERENT_FAIL";
export const UPDATE_ADHERENT_FAIL_DESC= "UPDATE_ADHERENT_FAIL_DESC";
export const DELETE_ADHERENT= "DELETE_ADHERENT";

export const getAdherents = () => (dispatch) => {

  return Axios.get(ADHERENTS_API,{ headers: authHeader() }).then(
    (data) => {
      
      dispatch({
        type: FETCH_ADHERENTS,
        adherents: data.data['hydra:member']
              });
      return Promise.resolve();
    },
    (error) => {

          throw new Error(error);
     
    }    
  );
  
};

export const getAdherentById = (id) => (dispatch) => {

    return Axios.get(ADHERENTS_API+ "/" + id,{ headers: authHeader() })
  .then(
    (data) => {
     
      dispatch({
        type: FETCH_CURRENT_ADHERENT,
        currentAdherent: data.data
      });
  
      return Promise.resolve();
    },
  
    (error) => {
        throw new Error(error)       
    }    
  );
  
};

export const addAdherent = (adherent) => (dispatch) => {
    
      if(adherent.dateDebutAdhesion){
        adherent.dateDebutAdhesion= adherent.dateDebutAdhesion.toLocaleString("en-US", {timeZone: "Africa/Algiers"});
      }
      if(adherent.dateFinAdhesion){
        adherent.dateFinAdhesion= adherent.dateFinAdhesion.toLocaleString("en-US", {timeZone: "Africa/Algiers"});
      }

      return Axios.post(ADHERENTS_API, adherent, {headers: authHeader()}).then(
        (data) => {
    
          let adherent= data.data;
  
          dispatch({
            type: ADD_ADHERENT_SUCCESS,
            currentAdherent: adherent
                  });
          
          dispatch({
            type: ADD_ADHERENT_FAIL,
            addAdherentErrors: null
                  });
          return Promise.resolve();
        },
        (error) => {
    
          const message =error.response.data;
          const {violations} = message;
          const errorDescription = message["hydra:description"];
          if(violations){
    
            dispatch({
              type: ADD_ADHERENT_FAIL,
              addAdherentErrors: violations
                    });
                    throw new Error(error);
          } else if(errorDescription){
            dispatch({
              type: ADD_ADHERENT_FAIL_DESC,
              addAdherentErrorsDesc: errorDescription
                    });
                    throw new Error(error);
          } else{
            throw new Error(error)
          }
         
        }    
      );
    
};

export const updateAdherent = (id, adherent) => (dispatch) => {

  if(adherent.dateDebutAdhesion){
    adherent.dateDebutAdhesion= new Date(adherent.dateDebutAdhesion).toLocaleString("en-US", {timeZone: "Africa/Algiers"});
  }
  if(adherent.dateFinAdhesion){
    adherent.dateFinAdhesion= new Date(adherent.dateFinAdhesion).toLocaleString("en-US", {timeZone: "Africa/Algiers"});
  }
  
    return Axios.put(ADHERENTS_API + "/" + id, adherent, {headers: authHeader()}).then(
      (data) => {
  
        let adherent= data.data;
  
        dispatch({
          type: UPDATE_ADHERENT_SUCCESS,
          currentAdherent: adherent
                });
        
        dispatch({
          type: UPDATE_ADHERENT_FAIL,
          updateAdherentErrors: null
                });
        return Promise.resolve();
      },
      (error) => {
  
        const message =error.response.data;
        const {violations} = message;
        const errorDescription = message["hydra:description"];
        if(violations){
  
          dispatch({
            type: UPDATE_ADHERENT_FAIL,
            updateAdherentErrors: violations
                  });
                  throw new Error(error);
        } else if(errorDescription){
          dispatch({
            type: UPDATE_ADHERENT_FAIL_DESC,
            updateAdherentErrorsDesc: errorDescription
                  });
                  throw new Error(error);
        } else{
          throw new Error(error)
        }
       
      }    
    );
  
  };

  export const deleteAdherent = (id) => (dispatch) => {
    
    return Axios.delete(ADHERENTS_API+"/" + id, {headers: authHeader()}).then(
      (data) => {
  
        dispatch({
          type: DELETE_ADHERENT,
          id
        });
        
        return Promise.resolve();
      },
      (error) => {
          throw new Error(error)
      }    
    );
  
  };

  export const clearMsgAddAdherent = () => (dispatch) => {

    dispatch({
      type: ADD_ADHERENT_FAIL,
      addAdherentErrors: null
            });
  
    dispatch({
      type: ADD_ADHERENT_FAIL_DESC,
      addAdherentErrorsDesc: null
            });
  
    dispatch({
      type: UPDATE_ADHERENT_FAIL,
      updateAdherentErrors: null
            });
        
    dispatch({
      type: UPDATE_ADHERENT_FAIL_DESC,
      updateAdherentErrorsDesc: null
            });
  
    return Promise.resolve();
  
  };

  
  export const printCarteAdhPdf = (idAdherent) => (dispatch) => {
    let formData = new FormData();
    formData.append('idAdherent', idAdherent);
  
    return Axios.post(PDF_CARTE_ADHERENT_API, formData, {headers: authHeader()}).then(
      (data) => {
  
        let pdf= data.data.pdf;
  
        const pdfBlob = base64ToBlob(pdf, 'application/pdf');
        const pdfURL = URL.createObjectURL(pdfBlob);
  
        let iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = pdfURL;
  
        iframe.onload = function() {
          setTimeout(function() {
            iframe.focus();
            iframe.contentWindow.print();
            URL.revokeObjectURL(pdfURL); // Libère la mémoire utilisée par l'URL du blob
          }, 1000); // Attend une seconde pour s'assurer que le contenu de l'iframe est chargé
        };
  
        document.body.appendChild(iframe);
  
        return Promise.resolve();
      },
      (error) => {
  
          throw new Error(error)
      }    
    );
    
  };
  
  export const printCarteAdhPdfBrowser = (idAdherent) => (dispatch) => {

    let formData = new FormData();
    formData.append('idAdherent', idAdherent);
  
    return Axios.post(PDF_CARTE_ADHERENT_API, formData, {headers: authHeader()}).then(
      (data) => {
  
        let pdf= data.data.pdf;
  
        const pdfBlob = base64ToBlob(pdf, 'application/pdf');
        
        const pdfURL = URL.createObjectURL(pdfBlob);
        window.open(pdfURL, '_blank');
  
        return Promise.resolve();
      },
      (error) => {
  
          throw new Error(error)
      }    
    );
  
  }
  
  export const downloadCarteAdhPdf = (idAdherent) => (dispatch) => {
  
    let formData = new FormData();
    formData.append('idAdherent', idAdherent);

    return Axios.post(PDF_CARTE_ADHERENT_API, formData, {headers: authHeader()}).then(
      (data) => {
  
        let pdf= data.data.pdf;
  
        const pdfBlob = base64ToBlob(pdf, 'application/pdf');
        
        const linkSource = `data:application/pdf;base64,${pdf}`;
        const downloadLink = document.createElement("a");
        const fileName = "carte-adherent-" + idAdherent + ".pdf";
  
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
  
        return Promise.resolve();
      },
      (error) => {
  
          throw new Error(error)
      }    
    );
  }
  