import Axios from "axios";
import authHeader from "../../helpers/auth-header.js";
import { USERS_API } from "../../services/config.js";

export const FETCH_USER= "FETCH_USER";
export const CREATE_USER_FAIL= "CREATE_USER_FAIL";
export const CREATE_USER_FAIL_DESC= "CREATE_USER_FAIL_DESC";
export const UPDATE_USER_SUCCESS= "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL= "UPDATE_USER_FAIL";
export const UPDATE_USER_FAIL_DESC= "UPDATE_USER_FAIL_DESC";
export const DELETE_USER= "DELETE_USER";
export const FETCH_USERS= "FETCH_USERS";

export const getUsers = () => (dispatch) => {

    return Axios.get(USERS_API, { headers: authHeader() }).then(
      (data) => {
        
        dispatch({
          type: FETCH_USERS,
          users: data.data['hydra:member']
                });
        return Promise.resolve();
      },
      (error) => {
  
            throw new Error(error);
       
      }    
    );
    
};

export const getUserById = (id) => (dispatch) => {

    return Axios.get(USERS_API + "/" + id, { headers: authHeader() })
  .then(
    (data) => {
      const fetchedUser= data.data;

      dispatch({
        type: FETCH_USER,
        fetchedUser: fetchedUser
      });

      return Promise.resolve();
    },

    (error) => {
        throw new Error(error)       
    }    
  );
};

export const addUser = (user) => (dispatch) => {
    let formData = new FormData();
    formData.append('firstName', user.firstName);
    formData.append('lastName', user.lastName);
    formData.append('email', user.email);
    formData.append('username', user.username);
    formData.append('password', user.password);
    formData.append('roles[0]', ["ROLE_AGENT"]);
  
    return Axios.post(USERS_API, formData, {headers: authHeader()})
  .then(
    (response) => {
  
      let userAdded= response.data;
  
      // Set errors to null
      dispatch({
        type: CREATE_USER_FAIL,
        createUserErrors: null
              });
  
      return Promise.resolve();
    },
    (error) => {
      const message =error.response.data;
      const {violations} = message;
      const errorDescription = message["hydra:description"];
      if(violations){
  
        dispatch({
          type: CREATE_USER_FAIL,
          createUserErrors: violations
                });
                throw new Error(error);

      }else if(errorDescription){
        dispatch({
          type: CREATE_USER_FAIL_DESC,
          createUserErrorsDesc: errorDescription
                });
                throw new Error(error);
      } else{
        throw new Error(error)
      }
     
    }    
  );
  
};

export const updateUser = (id, user) => (dispatch) => {

    return Axios.put(USERS_API + "/" + id, user, {headers: authHeader()}).then(
      (data) => {
        let userUpdated= data.data;
  
        dispatch({
          type: UPDATE_USER_SUCCESS,
          fetchedUser: userUpdated
                });
        
        dispatch({
          type: UPDATE_USER_FAIL,
          updateUserErrors: null
                });
        return Promise.resolve();
      },
      (error) => {
  
        const message =error.response.data;
        const {violations} = message;
        const errorDescription = message["hydra:description"];
        if(violations){
  
          dispatch({
            type: UPDATE_USER_FAIL,
            updateUserErrors: violations
                  });
                  throw new Error(error);
        } else if(errorDescription){
          dispatch({
            type: UPDATE_USER_FAIL_DESC,
            updateUserErrorsDesc: errorDescription
                  });
                  throw new Error(error);
        } else{
          throw new Error(error)
        }
       
      }    
    );
  
};

export const deleteUser = (id) => (dispatch) => {
    
    return Axios.delete(USERS_API+"/" + id, {headers: authHeader()}).then(
      (data) => {
  
        dispatch({
          type: DELETE_USER,
          id
        });
        
        return Promise.resolve();
      },
      (error) => {
          throw new Error(error)
      }    
    );
  
};


export const clearMsgAddUser = () => (dispatch) => {

    dispatch({
      type: CREATE_USER_FAIL,
      createUserErrors: null
            });
  
    dispatch({
      type: CREATE_USER_FAIL_DESC,
      createUserErrorsDesc: null
            });
  
    dispatch({
      type: UPDATE_USER_FAIL,
      updateUserErrors: null
            });
        
    dispatch({
      type: UPDATE_USER_FAIL_DESC,
      updateUserErrorsDesc: null
            });
  
    return Promise.resolve();
  
  };