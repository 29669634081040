// @flow 
import React,{useState, useEffect, useRef} from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import { toast } from 'react-toastify';
import Pagination from '../PaginationTable';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { getAttestations, deleteAttestation, printAttesPdf, printAttesPdfBrowser, downloadAttesPdf, sendAttesPdf } from '../../../store/actions/AttestationActions';

const Attestations = ({getAttestations, 
    attestations, 
    printAttesPdf, 
    printAttesPdfBrowser, 
    downloadAttesPdf,
    sendAttesPdf,
    deleteAttestation,
    currentUser
    }) => {

    const [currentPage, setCurrentPage]= useState(1);
    const [search, setSearch]= useState("");
    const [loading, setLoading]= useState(true);
    const [attestationsListe, setAttestationsListe]= useState([]);
    const [error, setError]= useState();
    const [confirmationVisible, setConfirmationVisible]= useState();
    const [attestationForDelete, setAttestationForDelete]= useState();
    const [attestationForDeleteInfo, setAttestationForDeleteInfo]= useState();

    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    let {id}= params;


    //Get attestations
    const fetchAttestations = async () => {
        try {
            getAttestations(id);
            setLoading(false);
        } catch (error) {
            toast.error("Erreur lors du chargement des attestations");
        }
    }


    useEffect(() => {
        fetchAttestations();
     }, []);

    //Get invites au chargement
    useEffect( () => {
        if(attestations){
            if(! attestations.length==0){
                setAttestationsListe(attestations);
            }
        }
        

    }, [attestations] )


    //Change current page
    const handleChangePage = page => setCurrentPage(page);
    
    //Recherche
    const handleSearch = ({currentTarget}) => { 
        const value= currentTarget.value;
        setSearch(value);
        setCurrentPage(1);
    }

    // Rénitialiser les filtres
    const resetFilters= () => {
        setSearch("");
    }

    const itemsPerPage =10;
    
    //Filtrage des types en fonction de la recherche
    let filtredAttestations;

    if(attestationsListe && attestationsListe.length >0){         
        filtredAttestations= attestationsListe.filter(
            t =>
            (t.id && t.id.toString().includes(search)) ||
            (t.intitule && t.intitule.toLowerCase().includes(search.toLowerCase()))
        );
    }

    // Au cas ou le résultat de la recherche serait null
    useEffect( () => {
        if(filtredAttestations && filtredAttestations.length == 0 && search!= ""){
            setError("Désolé, aucun résultat n'a été trouvé");
        }else if (filtredAttestations && filtredAttestations.length>0 ){
            setError();
        }
    }, [filtredAttestations] )
    
    //Pagination
    let paginatedAttestations;
    if (filtredAttestations && filtredAttestations.length >0){
        paginatedAttestations= Pagination.getData(filtredAttestations, currentPage, itemsPerPage);
    }

    const handlePrint = async (idAttestation, idEvenement) => {
        try {
            await printAttesPdf(idAttestation, idEvenement);
        } catch(err){
            toast.error("Erreur lors du chargement du pdf");
        }
    };

    const handlePrintBrowser = async (idAttestation, idEvenement) => {
        try {
            await printAttesPdfBrowser(idAttestation, idEvenement);
        } catch(err){
            toast.error("Erreur lors du chargement du pdf");
        }
    };

    const handleDownload = async (idAttestation, idEvenement) => {
        try {
            await downloadAttesPdf(idAttestation, idEvenement);
        } catch(err){
            toast.error("Erreur lors du chargement du courriel");
        }
    };

    const handleSend = async (item, idAttestation, idEvenement) => {
        if(item.orateur && item.orateur.email){
            try {
                await sendAttesPdf(idAttestation, idEvenement);
                toast.success("Attestation envoyée par email à l'adresse " + item.orateur.email);
            } catch(err){
                toast.error("Erreur lors de l'envoi de l'émail");
            }
        } else {
            toast.error("Veuillez renseigner l'adresse e-mail de l'orateur " + (item.orateur ? item.orateur.id : null) + " dans l'onglet orateurs");
        }
    };

    const handleDelete = (item) => {
        if(currentUser.roles[0]!=="ROLE_ADMIN"){
            toast.error("Vous n'avez pas l'autorisation nécéssaire");
        }else{
            setAttestationForDelete(item.id);
            setAttestationForDeleteInfo(item.nom);
            setConfirmationVisible(true);
        }
        
    }

    const handleDeleteConfirm = async id => {
        setConfirmationVisible(false)
        try {
            await deleteAttestation(id);
            toast.success("L'attestation a bien été supprimée");
        } catch (error) {
            toast.error("Une erreur est survenue");
        }

    }

    const formatAttestationType= (type) => {
        switch (type) {
            case 'affichee':
              return "Affichée"
              break;
            case 'orale':
              return "Orale"
              break;
            case 'atelier':
              return "Atelier"
              break;
            default:
              return ""
          }
    }

    return (
        <>

            <div className="row page-titles">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active"><Link to={"#"}> attestations</Link></li>
                </ol>
            </div>

            <div className="row tableRow">
                <div className="col-lg-12">

                    <div className="card">

                        <div className="card-header">
                            <h4 className="card-title">Liste des attestations</h4>
                            <Link to={location.pathname + "/new"} className="btn btn-primary">Ajouter</Link>
                        </div>
                        
                        <div className="card-body">
                            <div className="table-responsive ticket-table">
                                <div id="ticket_wrapper" className="dataTables_wrapper no-footer">
                                    
                                    <Container>
                                        <Row>
                                            <Col md={11}>
                                                <div className="col-12 d-flex align-items-center">
                                                    <label className="me-2 mt-2">Recherche:</label>
                                                    <input 
                                                        className="form-control"
                                                        type="search" 
                                                        placeholder="Intitulé"
                                                        onChange={handleSearch}
                                                        value={search}
                                                    />
                                                </div>
                                            </Col>

                                            <Col md={1}>

                                                <Button className="btn btn-primary shadow btn-md sharp me-1 restartBtn" onClick={resetFilters}>
                                                    <i class="fas fa-undo"></i>                                                
                                                </Button>
                                            </Col>

                                        </Row>
                                    </Container>
                                        

                                    <table id="example" className="display dataTablesCard table-responsive-xl dataTable no-footer w-100">
                                        
                                    { (paginatedAttestations && paginatedAttestations.length>0) &&
                                        <thead>
                                            <tr>
                                                <th></th>
												<th>Attestation</th>
												<th>Intitulé</th>
												<th>Type</th>
												<th>Oarteur</th>
												<th>Actions</th>                                          
                                            </tr>
                                        </thead>
                                    }

                                        {!loading && (
                                        <tbody>
                                            {paginatedAttestations &&  paginatedAttestations.map((item, index)=>(
                                                <tr key={index}>

                                                    <td>
                                                        <Link className="btn shadow btn-md sharp me-1 btn-success"
                                                        onClick={() => handlePrint(item.id, item.evenement.id)}>
                                                            <i class="fas fa-print fa-2x"></i>
                                                        </Link>
                                                    </td>

                                                    <td>
                                                        <span className="badge badge-success badge-lg">{item.id}</span>
                                                    </td>
                                                    
                                                    <td>						
                                                            <span className="badge light badge-success">
                                                                <Link to={"#"} className="h5">{item.intitule}</Link>
                                                            </span>
                                                        
                                                    </td>

                                                    <td>						
                                                            <span className="badge light badge-success">
                                                                <a to={"#"} className="h5">{formatAttestationType(item.type)}</a>
                                                            </span>
                                                        
                                                    </td>

                                                    <td>						
                                                        {item.orateur ? 
                                                            <a className="badge badge-success badge-lg">{(item.orateur.titre && item.orateur.titre) + " " + (item.orateur.prenom && item.orateur.prenom) + " " + (item.orateur.nom && item.orateur.nom)}</a>
                                                        : null} 
                                                    </td>

                                                    <td>
                                                        <div className="d-flex">

                                                            <Link className="btn btn-primary shadow btn-md sharp me-1" 
                                                            onClick={() => handlePrintBrowser(item.id, item.evenement.id)}>
                                                                <i class="fas fa-file-pdf fa-lg"></i>
                                                            </Link>

                                                            <Link className="btn btn-info shadow btn-md sharp me-1" 
                                                            onClick={() => handleDownload(item.id, item.evenement.id)}>
                                                                <i class="fas fa-file-download fa-lg"></i>
                                                            </Link>

                                                            <Link className="btn btn-success shadow btn-md sharp me-1" 
                                                            onClick={() => handleSend(item, item.id, item.evenement.id)}>
                                                                <i class="fas fa-envelope fa-lg"></i>
                                                            </Link>

                                                            <Link to={location.pathname + "/" + item.id} className="btn btn-warning shadow btn-md sharp me-1"
                                                            >
                                                                <i className="fas fa-pencil-alt fa-lg"></i>
                                                            </Link>

                                                            <Link to={"#"} className="btn btn-danger shadow btn-md sharp me-1"
                                                            onClick={() => handleDelete(item)}>
                                                                <i className="fa fa-trash fa-lg"></i>
                                                            </Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                            
                                        </tbody>
                                        )}

                                        
                                        
                                    </table>

                                    {filtredAttestations ? filtredAttestations.length > itemsPerPage && ( 
                                        <Pagination currentPage={currentPage} 
                                        itemsPerPage={itemsPerPage} 
                                        length={filtredAttestations.length} 
                                        onPageChanged={handleChangePage} />) 
                                        : null}

                                    {/* <!-- Modal --> */}
                                    <Modal className="fade" show={confirmationVisible}>
                                    <Modal.Header>
                                        <Modal.Title>Supression de {attestationForDeleteInfo}</Modal.Title>
                                        <Button
                                            variant=""
                                            className="close"
                                        >
                                            <span>&times;</span>
                                        </Button>
                                    </Modal.Header>
                                    <Modal.Body>êtes-vous sûr de vouloir continuer ?</Modal.Body>
                                    <Modal.Footer>
                                        <Button
                                            onClick={() => setConfirmationVisible(false)}
                                            variant="danger light"
                                        >
                                            Annuler
                                        </Button>
                                        <Button variant="primary"  onClick={() => handleDeleteConfirm(attestationForDelete)}>
                                            Continuer
                                        </Button>
                                    </Modal.Footer>
                                    </Modal>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: "",
        attestations: state.attestationState.attestations,
        currentPdf: state.pdfState.currentPdf,
        currentUser: state.auth.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {   getAttestations, 
            printAttesPdf, 
            printAttesPdfBrowser, 
            downloadAttesPdf,
            sendAttesPdf, 
            deleteAttestation},
        dispatch,
    );
};


export default connect(mapStateToProps, mapDispatchToProps)(Attestations); 