// @flow 
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Link, useLocation } from "react-router-dom";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

import LogoutPage from './Logout';
/// Image
import profile from "../../../images/defaultImgUser.png";
import avatar from "../../../images/avatar/1.jpg";
import { Dropdown } from "react-bootstrap";
import { getImageLink } from '../../../helpers/ImageHelpers';

const Header = ({ user, onNote, toggle, onProfile, onNotification, onClick }) => {
  
  const location = useLocation();
  const [finalName, setFinalName]= useState("");

  const filterName= (name) => {
    switch (name) {
      case "events":
        return "Évènements";
        break;
      case "organisme":
        return "Informations";
        break;
      case "users":
        return "Utilisateurs";
        break;
      default:
        return name;
        break;
    }
  }
  
  useEffect( () => {
    const path = location.pathname.split("/");
    const name = path[path.length - 1].split("-");
    let tmpName= filterName(name[0]);
    setFinalName(tmpName);
  }, [location.pathname] )

  const [imgUser, setImgUser]= useState();

	useEffect( () => {
        if(user && user.image){
			setImgUser(getImageLink(user.image));
		}else {
			setImgUser(profile);
		}
    }, [user] )

  const handleSwithEvent = () => {
    window.location.href="/admin"
  }

  return (
    <div className="header">
		<div className="header-content">
			<nav className="navbar navbar-expand">
				<div className="collapse navbar-collapse justify-content-between">
					<div className="header-left">
						<div
							className="dashboard_bar"
							style={{ textTransform: "capitalize" }}
						  >
							{finalName ? 
              finalName
							: ""
              }
						</div>
					</div> 	
					<ul className="navbar-nav header-right">
          <Link className="nav-item" >
            <i className="fa fa-retweet btn-lg" style= {{color: "white"}} aria-hidden="true" onClick={() => handleSwithEvent()}></i>
          </Link>

						<Dropdown as="li" className="nav-item header-profile ">
							<Dropdown.Toggle as="a" to="#" variant="" className="nav-link i-false c-pointer">								
								<img className='headerImgUser' src={imgUser} width="20" alt=""/>
								<div className="header-info">
									<span>{user.username}<i className="fa fa-caret-down ms-3" aria-hidden="true"></i></span>
								</div>
                                
							</Dropdown.Toggle>
							<Dropdown.Menu align="right" className="mt-2">
                               <LogoutPage />
							</Dropdown.Menu>
						</Dropdown>
					</ul>
				</div>
			</nav>
		</div>
    </div>
  );
};

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        { },
        dispatch,
    );
};


export default connect(mapStateToProps, mapDispatchToProps)(Header); 
