// @flow 
import React,{useState, useEffect, useRef, useLayoutEffect} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, useParams } from 'react-router-dom';
import { Col, Container, Row, Button, Modal  } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Field from '../../components/Forms/Field';
import swal from 'sweetalert';
import { getOrganismes, addOrganisme, updateOrganisme, deleteImageLogoOrganisme, deleteImageCachetOrganisme, clearMsgAddOrganisme } from '../../../store/actions/OrganismeActions';
import {getImageLogoOrganismeLink, getImageCachetOrganismeLink} from '../../../helpers/ImageHelpers';
import Select from '../Forms/Select';

const Organisme = ({ organismes,
    addOrganisme, 
    getOrganismes,
    updateOrganisme,
    addOrganismeErrors, 
    addOrganismeErrorsDesc, 
    updateOrganismeErrors, 
    updateOrganismeDesc,
    clearMsgAddOrganisme, 
    userId,
    deleteImageLogoOrganisme,
    deleteImageCachetOrganisme,
    currentUser
    }) => {

    const [organisme, setOrganisme]= useState({});
    const [editing, setEditing]= useState(false); 
    const [loading, setLoading]= useState(true);
    const [filelogo, setFilelogo]= useState();
    const [filecachet, setFilecachet]= useState();
    const [confirmationLogoVisible, setConfirmationLogoVisible]= useState();
    const [confirmationCachetVisible, setConfirmationCachetVisible]= useState();
    const [organismeLogoForDelete, setOrganismeLogoForDelete]= useState();
    const [organismeCachetForDelete, setOrganismeCachetForDelete]= useState();

    const [credentials, setCredentials]= useState({
        nom: "",
        president: "",
        site: "",
        email: "",
        logo: "",
        cachet: "",
        chargilyEnabled: false,
        chargilyMode: "",
        chargilyPublicKey: "",
        chargilySecretKey: ""
      })

    const [errors, setErrors]= useState({
        nom: "",
        president: "",
        site: "",
        email: "",
        logo: "",
        cachet: "",
        chargilyEnabled: "",
        chargilyMode: "",
        chargilyPublicKey: "",
        chargilySecretKey: ""
    });

    const params = useParams();
    let {id}= params;

    //Get types au chargement
    useEffect( () => {

        getOrganismes();

    }, [] )

    useEffect( () => {

        if(organismes && organismes.length>0){
            setLoading(true);

            if(organismes[0].chargily_enabled){
                if(organismes[0].chargily_enabled == "true"){
                    organismes[0].chargilyEnabled= true;
                } else {
                    organismes[0].chargilyEnabled= false;
                }
            } else {
                organismes[0].chargilyEnabled= false;
            }

            organismes[0].chargilyMode= organismes[0].chargily_mode;
            organismes[0].chargilyPublicKey= organismes[0].chargily_public_key;
            organismes[0].chargilySecretKey= organismes[0].chargily_secret_key;

            setCredentials(organismes[0]);
            setOrganisme(organismes[0]);
            setEditing(true);
            setLoading(false)
        } else {
            setEditing(false);
            setLoading(false)
        }

    }, [organismes] )

    useEffect( () => {
        if(addOrganismeErrors){
               const apiErrors={};
               const violations=addOrganismeErrors;
               if(violations && Array.isArray(violations)){
                violations.forEach(({propertyPath, message}) => { 
                  apiErrors[propertyPath]= message;
                  } );
                  setErrors(apiErrors);
               }
                      
        }
      
      }, [addOrganismeErrors])

    useEffect( () => {
        if(addOrganismeErrorsDesc){
            swal("Impossible d'enregistrer le profil", addOrganismeErrorsDesc, "error")
        }
      
      }, [addOrganismeErrorsDesc])
 

    const onSignup = async (e) =>{
        e.preventDefault();

        setErrors({});
        clearMsgAddOrganisme();

            if(editing){

                try {
                    await updateOrganisme(organisme.id, credentials, filelogo, filecachet);
                    //if response
                    setErrors({});
                    swal("Le profil est à jour", "success").then(() => {
                        window.location.reload();
                    });
                }   catch (err) {
                    toast.error("Une erreur est survenue !");
                }

            } else{
                try {
                    await addOrganisme(credentials, filelogo, filecachet);
                    //if response
                    setErrors({});
                    swal("Le profil est bien enregistré", "success").then(() => {
                        window.location.reload();
                    });
                }   catch (err) {
                    toast.error("Une erreur est survenue !");
    
                }
            }

    }

    //Gestion des champs
    const handleChangeFields = ({currentTarget}) =>{
        const {value, name}= currentTarget;
        if(name === "filelogo"){
            setFilelogo(document.querySelector('input[id="filelogo"]').files[0]);
        }
        else if(name === "filecachet"){
            setFilecachet(document.querySelector('input[id="filecachet"]').files[0]);
        }
        else if(name === "chargilyEnabled") {
            setCredentials({ ...credentials, [name]: !credentials[name] });
        }
        else{
            setCredentials({ ...credentials, [name]: value });
        }
    }

    useLayoutEffect(() => {
        return () => {
               setErrors({});
               clearMsgAddOrganisme();
               setEditing(false);
        }
    }, [])

    const handleDeleteLogoImage = (item) => {
        if(currentUser.roles[0]!=="ROLE_ADMIN"){
            toast.error("Vous n'avez pas l'autorisation nécéssaire");
        }else{
            setOrganismeLogoForDelete(organisme.id);
            setConfirmationLogoVisible(true);
        }
    }

    const handleDeleteCachetImage = (item) => {
        if(currentUser.roles[0]!=="ROLE_ADMIN"){
            toast.error("Vous n'avez pas l'autorisation nécéssaire");
        }else{
            setOrganismeCachetForDelete(organisme.id);
            setConfirmationCachetVisible(true);
        }
    }

    const handleDeleteImageLogoConfirm= async () => {
        setConfirmationLogoVisible(false);
        try {
            await deleteImageLogoOrganisme(organisme.id);
            swal("Le logo est supprimé", "success").then(() => {
                window.location.reload();
            });
        }   catch (err) {
            toast.error("Une erreur est survenue !");
        }
    }

    const handleDeleteImageCachetConfirm= async () => {
        setConfirmationCachetVisible(false);
        try {
            await deleteImageCachetOrganisme(organisme.id);
            swal("Le cachet est supprimé", "success").then(() => {
                window.location.reload();
            });
        }   catch (err) {
            toast.error("Une erreur est survenue !");
        }
    }


    return (
        <>
            <div className="row page-titles">
                <ol className="breadcrumb">
                </ol>
            </div>

            <div className="row tableRow ajoutInvite">
                <div className="col-lg-12">

                    <div className="card">

                        <div className="card-header">
                            <h4 className="card-title">
                                Veuillez fournir les informations concernant l'association
                             </h4>

                        </div>

                    </div>

                </div>

                <div className='eventPage'>

        <div className="row">
                        <div className="col-lg-12">
                            <div className="card">

                                <div className="eventPageFragment">
                                    {!loading &&
                                    <Container>

                                        <Row>

                                            <Col md="12">
                                            <form onSubmit={onSignup}>
                                                
                                                <Row>

                                                <Col md="6">
                                                    <Field
                                                        name="nom"
                                                        value={credentials.nom}
                                                        onChange={handleChangeFields}
                                                        label= "Nom *"
                                                        placeholder="Nom *"
                                                        error={errors.nom}
                                                        type="text" 
                                                        required
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    <Field
                                                        name="president"
                                                        value={credentials.president}
                                                        onChange={handleChangeFields}
                                                        label= "Président *"
                                                        placeholder="Président *"
                                                        error={errors.president}
                                                        type="text"
                                                        required
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    <Field
                                                        name="site"
                                                        value={credentials.site}
                                                        onChange={handleChangeFields}
                                                        label= "Site web"
                                                        placeholder="Site web"
                                                        error={errors.site}
                                                        type="text"
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    <Field
                                                        name="email"
                                                        value={credentials.email}
                                                        onChange={handleChangeFields}
                                                        label= "Email"
                                                        placeholder="Email"
                                                        error={errors.email}
                                                        type="text"
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="chargilyEnabled"
                                                        checked= {credentials.chargilyEnabled}
                                                        onChange={handleChangeFields}
                                                        id="chargilyEnabled"
                                                    />
                                                    <label className="form-check-label" htmlFor="chargilyEnabled">
                                                        Paiement en ligne
                                                    </label>
                                                </Col>

                                                <Col md="6">
                                                    <Select name="chargilyMode"
                                                    value={credentials.chargilyMode}
                                                    label="Chargily mode"
                                                    onChange={handleChangeFields}
                                                    required={credentials.chargilyEnabled && "true"}
                                                    >
                                                    <option value="" defaultChecked>
                                                        Veuillez sélectionner une option
                                                    </option> 

                                                    <option value="test" defaultChecked>
                                                        Test
                                                    </option> 

                                                    <option value="live" defaultChecked>
                                                        Live
                                                    </option> 
                              
                                                    </Select>
                                                </Col>

                                                <Col md="6">
                                                    <Field
                                                        name="chargilyPublicKey"
                                                        value={credentials.chargilyPublicKey}
                                                        onChange={handleChangeFields}
                                                        label= "Chargily public key"
                                                        placeholder="Chargily public key"
                                                        error={errors.chargilyPublicKey}
                                                        type="text"
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    <Field
                                                        name="chargilySecretKey"
                                                        value={credentials.chargilySecretKey}
                                                        onChange={handleChangeFields}
                                                        label= "Chargily secret key"
                                                        placeholder="Chargily secret key"
                                                        error={errors.chargilySecretKey}
                                                        type="text"
                                                        />
                                                </Col>

                                                <Col md="6">
                                                <div class="uploader">
              

                                                    <label for="file-upload" id="file-drag">
                                                        <div id="start">
                                                        {credentials.logo &&
                                                            <>
                                                                <Link to={"#"} className="btn btn-danger shadow btn-md sharp me-1"
                                                                onClick={() => handleDeleteLogoImage()}>
                                                                <i className="fa fa-trash fa-lg"></i>
                                                                </Link>
                                                                <img src={getImageLogoOrganismeLink(credentials.logo)} className="svgImage" />
                                                            </>
                                                        }
                                                        
                                                            <div className="importText">
                                                                {!credentials.image ? <p>Importez le logo</p>
                                                                :
                                                                <>
                                                                    <p>Cliquez sur choisir un fichier pour changer de logo</p>
                                                                </>
                                                                }
                                                            </div>
                                                                <input 
                                                                type="file" 
                                                                id="filelogo" 
                                                                name="filelogo" 
                                                                accept="image/*" 
                                                                onChange={handleChangeFields} />

                                                        </div>
                                                    </label>
                                                 </div>
                                                </Col>

                                                <Col md="6">
                                                <div class="uploader">
              

                                                    <label for="file-upload" id="file-drag">
                                                        <div id="start">
                                                        {credentials.cachet &&
                                                            <>
                                                                <Link to={"#"} className="btn btn-danger shadow btn-md sharp me-1"
                                                                onClick={() => handleDeleteCachetImage()}>
                                                                <i className="fa fa-trash fa-lg"></i>
                                                                </Link>
                                                                <img src={getImageCachetOrganismeLink(credentials.cachet)} className="svgImage" />
                                                            </>
                                                        }
                                                        
                                                            <div className="importText">
                                                                {!credentials.image ? <p>Importez le cachet</p>
                                                                :
                                                                <>
                                                                    <p>Cliquez sur choisir un fichier pour changer de cachet</p>
                                                                </>
                                                                }
                                                            </div>
                                                                <input 
                                                                type="file" 
                                                                id="filecachet" 
                                                                name="filecachet" 
                                                                accept="image/*" 
                                                                onChange={handleChangeFields} />

                                                        </div>
                                                    </label>
                                                 </div>
                                                </Col>

                                                </Row>

                                                <div className="text-center">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary btn-lg">
                                                        Enregistrer
                                                    </button>
                                                </div>
                                            </form>
                                            </Col>

                                        </Row>

                                    </Container>
                                    }

                                    {/* <!-- Modal --> */}
                                    <Modal className="fade" show={confirmationLogoVisible}>
                                    <Modal.Header>
                                        <Modal.Title>Supression du logo</Modal.Title>
                                        <Button
                                            variant=""
                                            className="close"
                                        >
                                            <span>&times;</span>
                                        </Button>
                                    </Modal.Header>
                                    <Modal.Body>êtes-vous sûr de vouloir continuer ?</Modal.Body>
                                    <Modal.Footer>
                                        <Button
                                            onClick={() => setConfirmationLogoVisible(false)}
                                            variant="danger light"
                                        >
                                            Annuler
                                        </Button>
                                        <Button variant="primary"  onClick={() => handleDeleteImageLogoConfirm(organismeLogoForDelete)}>
                                            Continuer
                                        </Button>
                                    </Modal.Footer>
                                    </Modal>
                                
                                {/* <!-- Modal --> */}
                                <Modal className="fade" show={confirmationCachetVisible}>
                                    <Modal.Header>
                                        <Modal.Title>Supression du cachet</Modal.Title>
                                        <Button
                                            variant=""
                                            className="close"
                                        >
                                            <span>&times;</span>
                                        </Button>
                                    </Modal.Header>
                                    <Modal.Body>êtes-vous sûr de vouloir continuer ?</Modal.Body>
                                    <Modal.Footer>
                                        <Button
                                            onClick={() => setConfirmationCachetVisible(false)}
                                            variant="danger light"
                                        >
                                            Annuler
                                        </Button>
                                        <Button variant="primary"  onClick={() => handleDeleteImageCachetConfirm(organismeCachetForDelete)}>
                                            Continuer
                                        </Button>
                                    </Modal.Footer>
                                    </Modal>
                                    
                                    
                                </div>

                            </div>
                        </div>
        </div>

        </div>

            </div>

        </>
    );
};

const mapStateToProps = (state) => {
    return {
        addOrganismeErrors: state.organismeState.addOrganismeErrors,
        addOrganismeErrorsDesc: state.organismeState.addOrganismeErrorsDesc,
        organismes: state.organismeState.organismes,
        userId: state.auth.user.id,
        currentUser: state.auth.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {getOrganismes,
        addOrganisme, 
        updateOrganisme, 
        clearMsgAddOrganisme,
        deleteImageLogoOrganisme,
        deleteImageCachetOrganisme},
        dispatch,
    );
};


export default connect(mapStateToProps, mapDispatchToProps)(Organisme); 