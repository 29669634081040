// @flow 
import React,{useState, useEffect, useRef} from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import { toast } from 'react-toastify';
import Pagination from '../PaginationTable';

import WilayaOfAlgeria from "../../../helpers/Wilaya_Of_Algeria.json";
import listeSpecialites from "../../../helpers/Liste_specialites.json";
import { Badge, Button, Col, Container, Modal, Row } from 'react-bootstrap';
import Select from '../Forms/Select';
import { formatDateTime } from '../../../helpers/FormatHelpers';
import { getUsers, deleteUser } from '../../../store/actions/UserActions';

const Users = ({getUsers, 
    users, 
    deleteUser
    }) => {

    const [currentPage, setCurrentPage]= useState(1);
    const [search, setSearch]= useState("");
    const [loading, setLoading]= useState(true);
    const [usersListe, setUsersListe]= useState([]);
    const [error, setError]= useState();
    const [confirmationVisible, setConfirmationVisible]= useState();
    const [userForDelete, setUserForDelete]= useState();
    const [userForDeleteInfo, setUserForDeleteInfo]= useState();

    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    let {id}= params;


    //Get users
    const fetchUsers = async () => {
        try {
            getUsers();
            setLoading(false);
        } catch (error) {
            toast.error("Erreur lors du chargement des utilisateurs");
        }
    }


    useEffect(() => {
        fetchUsers();
     }, []);

    //Get invites au chargement
    useEffect( () => {
        if(users){
            if(! users.length==0){
                setUsersListe(users);
            }
        }
        

    }, [users] )


    //Change current page
    const handleChangePage = page => setCurrentPage(page);
    
    //Recherche
    const handleSearch = ({currentTarget}) => { 
        const value= currentTarget.value;
        setSearch(value);
        setCurrentPage(1);
    }

    // Rénitialiser les filtres
    const resetFilters= () => {
        setSearch("");
    }

    const itemsPerPage =10;
    
    //Filtrage des types en fonction de la recherche
    let filtredUsers;

    if(usersListe && usersListe.length >0){         
        filtredUsers= usersListe.filter(
            t =>
            (t.id && t.id.toString().includes(search)) ||
            (t.username && t.username.toLowerCase().includes(search.toLowerCase()))
        );
    }

    // Au cas ou le résultat de la recherche serait null
    useEffect( () => {
        if(filtredUsers && filtredUsers.length == 0 && search!= ""){
            setError("Désolé, aucun résultat n'a été trouvé");
        }else if (filtredUsers && filtredUsers.length>0 ){
            setError();
        }
    }, [filtredUsers] )
    
    //Pagination
    let paginatedUsers;
    if (filtredUsers && filtredUsers.length >0){
        paginatedUsers= Pagination.getData(filtredUsers, currentPage, itemsPerPage);
    }

    const handleDelete = (item) => {
        setUserForDelete(item.id);
        setUserForDeleteInfo(item.lastName);
        setConfirmationVisible(true);
    }

    const handleDeleteConfirm = async id => {
        setConfirmationVisible(false)
        try {
            await deleteUser(id);
            toast.success("L'utilisateur a bien été supprimé");
        } catch (error) {
            toast.error("Une erreur est survenue");
        }

    }

    return (
        <>

            <div className="row page-titles">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active"><Link to={"#"}> utilisateurs</Link></li>
                </ol>
            </div>

            <div className="row tableRow">
                <div className="col-lg-12">

                    <div className="card">

                        <div className="card-header">
                            <h4 className="card-title">Liste des utilisateurs</h4>
                            <Link to={location.pathname + "/new"} className="btn btn-primary">Ajouter</Link>
                        </div>
                        
                        <div className="card-body">
                            <div className="table-responsive ticket-table">
                                <div id="ticket_wrapper" className="dataTables_wrapper no-footer">
                                    
                                    <Container>
                                        <Row>
                                            <Col md={11}>
                                                <div className="col-12 d-flex align-items-center">
                                                    <label className="me-2 mt-2">Recherche:</label>
                                                    <input 
                                                        className="form-control"
                                                        type="search" 
                                                        placeholder="Dénomination" 
                                                        onChange={handleSearch}
                                                        value={search}
                                                    />
                                                </div>
                                            </Col>

                                            <Col md={1}>

                                                <Button className="btn btn-primary shadow btn-md sharp me-1 restartBtn" onClick={resetFilters}>
                                                    <i class="fas fa-undo"></i>                                                
                                                </Button>
                                            </Col>

                                        </Row>
                                    </Container>
                                        

                                    <table id="example" className="display dataTablesCard table-responsive-xl dataTable no-footer w-100">
                                        
                                    { (paginatedUsers && paginatedUsers.length>0) &&
                                        <thead>
                                            <tr>
												<th>User</th>
												<th>Nom</th>
												<th>Username</th>
												<th>Email</th>
												<th>Role</th>
												<th>Actions</th>                                          
                                            </tr>
                                        </thead>
                                    }

                                        {!loading && (
                                        <tbody>
                                            {paginatedUsers &&  paginatedUsers.map((item, index)=>(
                                                <tr key={index}>

                                                    <td>
                                                        <span className="badge badge-success badge-lg">{item.id}</span>
                                                    </td>
                                                    
                                                    <td>						
                                                            <span className="badge light badge-success">
                                                                <Link to={"#"} className="h5">{item.firstName + " " + item.lastName}</Link>
                                                            </span>
                                                        
                                                    </td>
                                                    <td>{item.username}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.roles[0]}</td>
                                                    <td>
                                                        <div className="d-flex">

                                                            <Link to={location.pathname + "/" + item.id} className="btn btn-warning shadow btn-md sharp me-1"
                                                            >
                                                                <i className="fas fa-pencil-alt fa-lg"></i>
                                                            </Link>

                                                            <Link to={"#"} className="btn btn-danger shadow btn-md sharp me-1"
                                                            onClick={() => handleDelete(item)}>
                                                                <i className="fa fa-trash fa-lg"></i>
                                                            </Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                            
                                        </tbody>
                                        )}

                                        
                                        
                                    </table>

                                    {filtredUsers ? filtredUsers.length > itemsPerPage && ( 
                                        <Pagination currentPage={currentPage} 
                                        itemsPerPage={itemsPerPage} 
                                        length={filtredUsers.length} 
                                        onPageChanged={handleChangePage} />) 
                                        : null}

                                    {/* <!-- Modal --> */}
                                    <Modal className="fade" show={confirmationVisible}>
                                    <Modal.Header>
                                        <Modal.Title>Supression de l'utilisateur {userForDeleteInfo}</Modal.Title>
                                        <Button
                                            variant=""
                                            className="close"
                                        >
                                            <span>&times;</span>
                                        </Button>
                                    </Modal.Header>
                                    <Modal.Body>êtes-vous sûr de vouloir continuer ?</Modal.Body>
                                    <Modal.Footer>
                                        <Button
                                            onClick={() => setConfirmationVisible(false)}
                                            variant="danger light"
                                        >
                                            Annuler
                                        </Button>
                                        <Button variant="primary"  onClick={() => handleDeleteConfirm(userForDelete)}>
                                            Continuer
                                        </Button>
                                    </Modal.Footer>
                                    </Modal>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: "",
        users: state.userState.users,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {   getUsers, 
            deleteUser},
        dispatch,
    );
};


export default connect(mapStateToProps, mapDispatchToProps)(Users); 