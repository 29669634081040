import { ADD_ORGANISATEUR_FAIL, ADD_ORGANISATEUR_FAIL_DESC, ADD_ORGANISATEUR_SUCCESS, DELETE_ORGANISATEUR, FETCH_CURRENT_ORGANISATEUR, FETCH_ORGANISATEURS, UPDATE_ORGANISATEUR_CHECK, UPDATE_ORGANISATEUR_FAIL, UPDATE_ORGANISATEUR_FAIL_DESC, UPDATE_ORGANISATEUR_SUCCESS } from "../actions/OrganisateurActions";

  
  const defaultState = {};
  
  export default function (state = defaultState, action) {

    const { type, payload } = action;
    
    switch (type) {

    case FETCH_ORGANISATEURS:
        return {
          ...state,
          organisateurs: action.organisateurs
        };

    case ADD_ORGANISATEUR_SUCCESS:
        return {
          ...state,
          currentOrganisateur: action.currentOrganisateur
        };
    
    case FETCH_CURRENT_ORGANISATEUR:
        return {
          ...state,
          currentOrganisateur: action.currentOrganisateur
        };
     
      case ADD_ORGANISATEUR_FAIL:
        return {
          ...state,
          addOrganisateurErrors: action.addOrganisateurErrors
        };
      
      case ADD_ORGANISATEUR_FAIL_DESC:
        return {
          ...state,
          addOrganisateurErrorsDesc: action.addOrganisateurErrorsDesc
        };

     case UPDATE_ORGANISATEUR_SUCCESS:
        return {
          ...state,
          currentOrganisateur: action.currentOrganisateur
        };

      case UPDATE_ORGANISATEUR_CHECK:
        let tmpOrganisateurs= state.organisateurs;
        let organisateurById= tmpOrganisateurs.find(x => x.id === action.idOrganisateur);
        organisateurById.checked= action.checked;
        return {
          ...state,
          organisateurs: tmpOrganisateurs
        };

      case UPDATE_ORGANISATEUR_FAIL:
        return {
          ...state,
          updateOrganisateurErrors: action.updateOrganisateurErrors
        };

      case UPDATE_ORGANISATEUR_FAIL_DESC:
        return {
          ...state,
          updateOrganisateurErrorsDesc: action.updateOrganisateurErrorsDesc
        };

      case DELETE_ORGANISATEUR:
            const organisateursUpdated= state.organisateurs.filter(c  => c.id != action.id);
  
          return {
            ...state,
            organisateurs: organisateursUpdated,
          };

      default:
        return state;
    }
  }
  