import { FETCH_BETTER_SPECIALITES, FETCH_BETTER_VILLES, FETCH_INVITES_CHECKED_COUNT, FETCH_INVITES_COUNT, FETCH_INVITES_PAID_COUNT, FETCH_ORATEURS_COUNT, FETCH_ORGANISATEURS_COUNT, FETCH_PRESSES_COUNT, FETCH_SPECIALITES_COUNT, FETCH_SPONSORS_COUNT, FETCH_STAT_INSCRIPTION } from "../actions/StatisticActions";

  
  const defaultState = {};
  
  export default function (state = defaultState, action) {

    const { type, payload } = action;
    
    switch (type) {

    case FETCH_INVITES_COUNT:
            return {
            ...state,
            countInvites: action.countInvites
          };

    case FETCH_INVITES_CHECKED_COUNT:
            return {
            ...state,
            countCheckedInvites: action.countCheckedInvites
          };

    case FETCH_INVITES_PAID_COUNT:
            return {
            ...state,
            countPaidInvites: action.countPaidInvites
          };

    case FETCH_ORGANISATEURS_COUNT:
            return {
            ...state,
            countOrganisateurs: action.countOrganisateurs
          };

    case FETCH_ORATEURS_COUNT:
            return {
            ...state,
            countOrateurs: action.countOrateurs
          };

    case FETCH_SPONSORS_COUNT:
            return {
            ...state,
            countSponsors: action.countSponsors
          };

    case FETCH_PRESSES_COUNT:
            return {
            ...state,
            countPresses: action.countPresses
          };

    case FETCH_SPECIALITES_COUNT:
            return {
            ...state,
            countSpecialites: action.countSpecialites
          };

    case FETCH_BETTER_SPECIALITES:
            return {
            ...state,
            betterSpecialites: action.betterSpecialites
          };

    case FETCH_BETTER_VILLES:
            return {
            ...state,
            betterVilles: action.betterVilles
          };

    case FETCH_STAT_INSCRIPTION:
            return {
            ...state,
            statInscription: action.statInscription
          };
      

      default:
        return state;
    }
  }
  