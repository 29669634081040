import { UPDATE_INSCRIPTION_SUCCESS } from "../actions/InscriptionActions";
import { ADD_INVITE_ADMIN_FAIL, ADD_INVITE_ADMIN_FAIL_DESC, ADD_INVITE_FAIL, ADD_INVITE_FAIL_DESC, ADD_INVITE_SUCCESS, DELETE_INVITE, FETCH_CURRENT_INVITE, FETCH_INVITES, UPDATE_INVITE_FAIL, UPDATE_INVITE_FAIL_DESC, UPDATE_INVITE_SUCCESS } from "../actions/InviteActions";

  
  const defaultState = {};
  
  export default function (state = defaultState, action) {

    const { type, payload } = action;
    
    switch (type) {

      case FETCH_INVITES:
        return {
          ...state,
          invites: action.invites
        };

      case FETCH_CURRENT_INVITE:
        return {
          ...state,
          currentInvite: action.currentInvite
        };

      case ADD_INVITE_SUCCESS:
        return {
          ...state,
          currentInvite: action.currentInvite
        };
      
      case ADD_INVITE_FAIL:
        return {
          ...state,
          addInviteErrors: action.addInviteErrors
        };
      
      case ADD_INVITE_FAIL_DESC:
        return {
          ...state,
          addInviteErrorDesc: action.addInviteErrorDesc
        };

      case ADD_INVITE_ADMIN_FAIL:
        return {
          ...state,
          addInviteErrors: action.addInviteErrors
        };
      
      case ADD_INVITE_ADMIN_FAIL_DESC:
        return {
          ...state,
          addInviteErrorDesc: action.addInviteErrorDesc
        };

      case UPDATE_INSCRIPTION_SUCCESS:
        let tmpInvites= state.invites;
        if(tmpInvites){
          let inviteById= tmpInvites.find(x => x.id === action.idInvite);
          inviteById.inscriptions[0].checked= action.checked;
        }
        return {
          ...state,
          invites: tmpInvites
        };

      case UPDATE_INVITE_SUCCESS:
        return {
          ...state,
          currentInvite: action.currentInvite
        };

      case UPDATE_INVITE_FAIL:
        return {
          ...state,
          updateInviteErrors: action.updateInviteErrors
        };

      case UPDATE_INVITE_FAIL_DESC:
        return {
          ...state,
          updateInviteErrorsDesc: action.updateInviteErrorsDesc
        };

      case DELETE_INVITE:
            const invitesUpdated= state.invites.filter(c  => c.id != action.id);
  
          return {
            ...state,
            invites: invitesUpdated,
          };
        

      default:
        return state;
    }
  }
  