// @flow 
import * as React from 'react';
import { Link } from 'react-router-dom';
import logo from "../../images/logo-forum.png";

const HeaderNoAuth = () => {
    return (
        <div className='headerNoAuth'>
            <Link to="/">
                <img id="logo" src={logo} alt="" />
            </Link>
        </div>
    );
};

export default HeaderNoAuth