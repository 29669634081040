// @flow 
import React,{useState, useEffect, useRef, useLayoutEffect} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Navigate, Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, Col, Container, Row, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { addEvenement, updateEvenement, clearMsgAddEvent, getEventById, deleteImageEvenement } from '../../../store/actions/EvenementActions';
import logo from "../../../images/logo-forum.png";
import Field from '../../components/Forms/Field';
import Select from "../../components/Forms/Select";
import WilayaOfAlgeria from "../../../helpers/Wilaya_Of_Algeria.json";
import listeSpecialites from "../../../helpers/Liste_specialites.json"; 
import listeTitres from "../../../helpers/Titre_medecin.json";  
import HeaderNoAuth from '../../components/HeaderNoAuth';
import RecaptchaSection from '../../components/RecaptchaSection';
import swal from 'sweetalert';
import { formatDateJs } from '../../../helpers/FormatHelpers';
import { getImageLink } from '../../../helpers/ImageHelpers';

const Event = ({getEventById, 
    addEvenement, 
    updateEvenement,
    currentEvenement, 
    addEvenementErrors, 
    addEvenementErrorsDesc, 
    updateEvenementErrors, 
    updateEvenementDesc,
    clearMsgAddEvent, 
    userId,
    getInviteById,
    currentInvite,
    currentInscription,
    deleteImageEvenement,
    currentUser
    }) => {

    const [event, setEvent]= useState({});
    const [editing, setEditing]= useState(false); 
    const [loading, setLoading]= useState(true);
    const [file, setFile]= useState();
    const [confirmationVisible, setConfirmationVisible]= useState();
    const [evenementForDelete, setEvenementForDelete]= useState();

    const [credentials, setCredentials]= useState({
        nom: "",
        description: "",
        dateDebut: null,
        dateFin: null,
        lieu: "",
        image: "",
        tarif: 0
      })

    const [errors, setErrors]= useState({
        nom: "",
        description: "",
        dateDebut: "",
        dateFin: "",
        lieu: "",
        image: "",
        tarif: ""
    });

    const optionsWilaya = WilayaOfAlgeria;
    const params = useParams();
    let {id}= params;
    let {idEvent= "new"}= params;
    const navigate = useNavigate();

    //Get types au chargement
    useEffect( () => {
        if(idEvent != "new"){
            setEditing(true);
            getEventById(idEvent);
        }else{
            setEditing(false);
        }
    }, [idEvent] )

    useEffect( () => {

        const fetchData = async () => 
        {
            if(currentEvenement && idEvent != "new"){
                setLoading(true);

                if(!currentEvenement.tarif) {
                    currentEvenement.tarif=0;
                }

                 setCredentials({...currentEvenement,
                                dateDebut: currentEvenement.date_debut ? formatDateJs(currentEvenement.date_debut): null,
                                dateFin: currentEvenement.date_fin ? formatDateJs(currentEvenement.date_fin): null});

                
            }
            setLoading(false);
        }

        fetchData();
        
    }, [currentEvenement] )

    useEffect( () => {
        if(addEvenementErrors){
               const apiErrors={};
               const violations=addEvenementErrors;
               if(violations && Array.isArray(violations)){
                violations.forEach(({propertyPath, message}) => { 
                  apiErrors[propertyPath]= message;
                  } );
                  setErrors(apiErrors);
               }
                      
        }
      
      }, [addEvenementErrors])

    useEffect( () => {
        if(addEvenementErrorsDesc){
            swal("Impossible d'ajouter l'évenement", addEvenementErrorsDesc, "error")
        }
      
      }, [addEvenementErrorsDesc])
 

    const onSignup = async (e) =>{
        e.preventDefault();

        setErrors({});
        clearMsgAddEvent();

            if(editing){

                try {

                    await updateEvenement(idEvent, credentials, file);
                    //if response
                    setErrors({});
                    swal("L'évenement est à jour", "success").then(() => {
                        window.location.reload();
                    });
                }   catch (err) {
                    toast.error("Une erreur est survenue !");
                }

            } else{
                try {
                    await addEvenement(credentials, file);
                    //if response
                    setErrors({});
                    swal("L'évenement est bien enregistré", "success").then(() => {
                        navigate('/admin').then(() => {
                            if(!currentEvenement){
                                window.location.reload();
                            }
                         })
                    });
                }   catch (err) {
                    toast.error("Une erreur est survenue !");
    
                }
            }

    }

    //Gestion des champs
    const handleChangeFields = ({currentTarget}) =>{
        const {value, name}= currentTarget;
        if(name === "file"){
            setFile(document.querySelector('input[type="file"]').files[0]);
        }
        else{
            setCredentials({ ...credentials, [name]: value });
        }
    }

    useLayoutEffect(() => {
        return () => {
               setErrors({});
               clearMsgAddEvent();
               setEditing(false);
        }
    }, [])

    const handleDeleteImage = (item) => {
        if(currentUser.roles[0]!=="ROLE_ADMIN"){
            toast.error("Vous n'avez pas l'autorisation nécéssaire");
        }else{
            setEvenementForDelete(currentEvenement.id);
            setConfirmationVisible(true);
        }
    }

    const handleDeleteImageConfirm= async () => {
        setConfirmationVisible(false)
        try {
            await deleteImageEvenement(currentEvenement.id);
            swal("L'image est supprimée", "success").then(() => {
                window.location.reload();
            });
        }   catch (err) {
            toast.error("Une erreur est survenue !");
        }
    }


    return (
        <div className={userId ? "" : "createEvent"}> 
            <div className="row page-titles">
                <ol className="breadcrumb">
                {userId ? 
                    <li className="breadcrumb-item active"><Link to={"/" + id + "/admin/events"}> Évenements</Link></li>
                    :
                null}
                    <li className="breadcrumb-item active"><Link to={"#"}> {editing ? "Modification": "Ajout"} </Link></li>
                </ol>
            </div>

            <div className="row tableRow ajoutInvite">
                <div className="col-lg-12">

                    <div className="card">

                        <div className="card-header">
                            <h4 className="card-title">
                            {editing ? "Modification | "
                            : "Ajout d'un évenement"}

                            {editing && !loading && currentEvenement &&
                            currentEvenement.nom}

                             </h4>

                                {userId ? 
                                <Link to={"/" + id + "/admin/events"} className="btn btn-primary">
                                    <i class="fas fa-undo"  style={{ marginRight: "5px",}}></i>  
                                    Retour aux évenements
                                </Link>
                                :
                                null
                                }
                                

                        </div>

                    </div>

                </div>

                <div className='eventPage'>

        <div className="row">
                        <div className="col-lg-12">
                            <div className="card">

                                <div className="eventPageFragment">
                                    {!loading &&
                                    <Container>

                                        <Row>

                                            <Col md="12">
                                            <form onSubmit={onSignup}>
                                                
                                                <Row>

                                                <Col md="6">
                                                    <Field
                                                        name="nom"
                                                        value={credentials.nom}
                                                        onChange={handleChangeFields}
                                                        label= "Nom *"
                                                        placeholder="Nom"
                                                        error={errors.nom}
                                                        type="text" 
                                                        required="true" 
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    <Field
                                                        name="description"
                                                        value={credentials.description}
                                                        onChange={handleChangeFields}
                                                        label= "Description"
                                                        placeholder="Description"
                                                        error={errors.description}
                                                        type="text"
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    <Field
                                                        name="dateDebut"
                                                        value={credentials.dateDebut}
                                                        onChange={handleChangeFields}
                                                        label= "Date de début *"
                                                        placeholder="Date de début *"
                                                        error={errors.dateDebut}
                                                        type="date" 
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    <Field
                                                        name="dateFin"
                                                        value={credentials.dateFin}
                                                        onChange={handleChangeFields}
                                                        label= "Date fin *"
                                                        placeholder="Date fin *"
                                                        error={errors.dateFin}
                                                        type="date"
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    <Field
                                                        name="lieu"
                                                        value={credentials.lieu}
                                                        onChange={handleChangeFields}
                                                        label= "Lieu * (Sans Le/La/L'....) - visible sur la section 4 du badge"
                                                        placeholder="Lieu *"
                                                        error={errors.lieu}
                                                        type="text"
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    {!loading && !editing && !currentEvenement ?
                                                        <p className='mt-4'> IMPORTANT : Veuillez intégrer le prix après avoir ajouté l'événement et mis à jour les détails sur l'association dans l'onglet 'Informations</p>
                                                    :
                                                    <Field
                                                    name="tarif"
                                                    value={credentials.tarif}
                                                    onChange={handleChangeFields}
                                                    label= "Tarif"
                                                    placeholder="Tarif"
                                                    error={errors.tarif}
                                                    type="number"
                                                    />
                                                    }
                                                </Col>

                                                <Col md="6">
                                                <div class="uploader">
              

                                                    <label for="file-upload" id="file-drag">
                                                        <div id="start">
                                                        {credentials.image &&
                                                            <>
                                                                <Link to={"#"} className="btn btn-danger shadow btn-md sharp me-1"
                                                                onClick={() => handleDeleteImage()}>
                                                                <i className="fa fa-trash fa-lg"></i>
                                                                </Link>
                                                                <img src={getImageLink(credentials.image)} className="svgImage" />
                                                            </>
                                                        }
                                                        
                                                            <div className="importText">
                                                                {!credentials.image ? <p>Importez une photo</p>
                                                                :
                                                                <>
                                                                    <p>Cliquez sur choisir un fichier pour changer de photo</p>
                                                                </>
                                                                }
                                                            </div>
                                                                <input 
                                                                type="file" 
                                                                id="file" 
                                                                name="file" 
                                                                accept="image/*" 
                                                                onChange={handleChangeFields} />

                                                        </div>
                                                    </label>
                                                 </div>
                                                </Col>

                                                </Row>

                                                <div className="text-center">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary btn-lg"
                                                    >
                                                        {editing ? "Enregistrer" : "Ajouter"}
                                                    </button>
                                                </div>
                                            </form>
                                            </Col>

                                        </Row>

                                    </Container>
                                    }

                                    {/* <!-- Modal --> */}
                                    <Modal className="fade" show={confirmationVisible}>
                                    <Modal.Header>
                                        <Modal.Title>Supression de l'image</Modal.Title>
                                        <Button
                                            variant=""
                                            className="close"
                                        >
                                            <span>&times;</span>
                                        </Button>
                                    </Modal.Header>
                                    <Modal.Body>êtes-vous sûr de vouloir continuer ?</Modal.Body>
                                    <Modal.Footer>
                                        <Button
                                            onClick={() => setConfirmationVisible(false)}
                                            variant="danger light"
                                        >
                                            Annuler
                                        </Button>
                                        <Button variant="primary"  onClick={() => handleDeleteImageConfirm(evenementForDelete)}>
                                            Continuer
                                        </Button>
                                    </Modal.Footer>
                                    </Modal>
                                    
                                    
                                </div>

                            </div>
                        </div>
        </div>

        </div>

            </div>

        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        evenements: state.evenementState.evenements,
        currentEvenement: state.evenementState.currentEvenement,
        addEvenementErrors: state.evenementState.addEvenementErrors,
        addEvenementErrorsDesc: state.evenementState.addEvenementErrorsDesc,
        userId: state.auth.user ? state.auth.user.id: null,
        currentUser: state.auth.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        { getEventById, 
        addEvenement, 
        updateEvenement, 
        clearMsgAddEvent,
        deleteImageEvenement},
        dispatch,
    );
};


export default connect(mapStateToProps, mapDispatchToProps)(Event); 