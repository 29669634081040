import Axios from "axios";
import { EVENEMENTS_API, INVITES_API } from "../../services/config";
import { addInscriptionByAdmin, addInscriptionByInvite } from "./InscriptionActions";
import authHeader from "../../helpers/auth-header.js";

export const FETCH_INVITES= "FETCH_INVITES";
export const ADD_INVITE_FAIL= "ADD_INVITE_FAIL";
export const ADD_INVITE_FAIL_DESC= "ADD_INVITE_FAIL_DESC";

// Admin
export const DELETE_INVITE= "DELETE_INVITE";
export const ADD_INVITE_ADMIN_FAIL= "ADD_INVITE_ADMIN_FAIL";
export const ADD_INVITE_ADMIN_FAIL_DESC= "ADD_INVITE_ADMIN_FAIL_DESC";
export const FETCH_CURRENT_INVITE= "FETCH_CURRENT_INVITE";
export const UPDATE_INVITE_SUCCESS= "UPDATE_INVITE_SUCCESS";
export const UPDATE_INVITE_FAIL= "UPDATE_INVITE_FAIL";
export const UPDATE_INVITE_FAIL_DESC= "UPDATE_INVITE_FAIL_DESC";
export const ADD_INVITE_SUCCESS= "ADD_INVITE_SUCCESS";

export const getInvites = (selectedEventId) => (dispatch) => {

  return Axios.get(EVENEMENTS_API + "/" + selectedEventId + "/invites", { headers: authHeader() }).then(
    (data) => {
      
      dispatch({
        type: FETCH_INVITES,
        invites: data.data['hydra:member']
              });
      return Promise.resolve();
    },
    (error) => {

          throw new Error(error);
     
    }    
  );
  

};

export const getInviteById = (id) => (dispatch) => {

  return Axios.get(INVITES_API+ "/" + id,{ headers: authHeader() })
.then(
  (data) => {
   
    dispatch({
      type: FETCH_CURRENT_INVITE,
      currentInvite: data.data
    });

    return Promise.resolve();
  },

  (error) => {
      throw new Error(error)       
  }    
);

};

export const addInvite = (invite, evenement) => (dispatch) => {
    
  if(evenement){
    invite.evenement= "/api/evenements/"+ evenement.id;
  }

  return Axios.post(INVITES_API, invite).then(
    (data) => {

      let invite= data.data;
      
      dispatch(addInscriptionByInvite(invite, evenement));
      
      dispatch({
        type: ADD_INVITE_FAIL,
        addInviteErrors: null
              });
      return Promise.resolve();
    },
    (error) => {

      const message =error.response.data;
      const {violations} = message;
      const errorDescription = message["hydra:description"];
      if(violations){

        dispatch({
          type: ADD_INVITE_FAIL,
          addInviteErrors: violations
                });
                throw new Error(error);
      } else if(errorDescription){
        dispatch({
          type: ADD_INVITE_FAIL_DESC,
          addInviteErrorDesc: errorDescription
                });
                throw new Error(error);
      } else{
        throw new Error(error)
      }
     
    }    
  );

};

export const addInviteByAdmin = (invite, evenement, user) => (dispatch) => {
    
  if(evenement){
    invite.evenement= "/api/evenements/"+ evenement.id;
  }

  let checked= invite.checked;
  let paid= invite.paid;

  return Axios.post(INVITES_API, invite).then(
    (data) => {

      let invite= data.data;
      
      dispatch(addInscriptionByAdmin(invite, checked, evenement, user, paid));
      
      dispatch({
        type: ADD_INVITE_SUCCESS,
        currentInvite: invite
              });

      dispatch({
        type: ADD_INVITE_ADMIN_FAIL,
        addInviteErrors: null
              });
      return Promise.resolve();
    },
    (error) => {
      const message =error.response.data;
      const {violations} = message;
      const errorDescription = message["hydra:description"];
      if(violations){

        dispatch({
          type: ADD_INVITE_ADMIN_FAIL,
          addInviteErrors: violations
                });
                throw new Error(error);
      } else if(errorDescription){
        dispatch({
          type: ADD_INVITE_ADMIN_FAIL_DESC,
          addInviteErrorDesc: errorDescription
                });
                throw new Error(error);
      } else{
        throw new Error(error)
      }
     
    }    
  );

};

export const updateInvite = (id, invite, evenement) => (dispatch) => {

  if(evenement){
    invite.evenement= "/api/evenements/"+ evenement.id;
  }

  delete invite.inscriptions; 
    
  return Axios.put(INVITES_API + "/" + id, invite, {headers: authHeader()}).then(
    (data) => {

      let invite= data.data;

      dispatch({
        type: UPDATE_INVITE_SUCCESS,
        currentInvite: invite
              });
      
      dispatch({
        type: UPDATE_INVITE_FAIL,
        updateInviteErrors: null
              });
      return Promise.resolve();
    },
    (error) => {

      const message =error.response.data;
      const {violations} = message;
      const errorDescription = message["hydra:description"];
      if(violations){

        dispatch({
          type: UPDATE_INVITE_FAIL,
          updateInviteErrors: violations
                });
                throw new Error(error);
      } else if(errorDescription){
        dispatch({
          type: UPDATE_INVITE_FAIL_DESC,
          updateInviteErrorsDesc: errorDescription
                });
                throw new Error(error);
      } else{
        throw new Error(error)
      }
     
    }    
  );

};

export const deleteInvite = (id) => (dispatch) => {
    
  return Axios.delete(INVITES_API+"/" + id, {headers: authHeader()}).then(
    (data) => {

      dispatch({
        type: DELETE_INVITE,
        id
      });
      
      return Promise.resolve();
    },
    (error) => {
        throw new Error(error)
    }    
  );

};


export const clearMsgAddInvite = () => (dispatch) => {

  dispatch({
    type: ADD_INVITE_FAIL,
    addInviteErrors: null
          });

  dispatch({
    type: ADD_INVITE_FAIL_DESC,
    addInviteErrorDesc: null
          });

  dispatch({
    type: UPDATE_INVITE_FAIL,
    updateInviteErrors: null
          });
      
  dispatch({
    type: UPDATE_INVITE_FAIL_DESC,
    updateInviteErrorsDesc: null
          });

  return Promise.resolve();

};
