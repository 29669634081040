// @flow 
import React,{useState, useEffect, useRef, useLayoutEffect} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Navigate, Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { getEventById } from '../../../store/actions/EvenementActions';
import { getUserById, addUser, clearMsgAddUser, updateUser  } from '../../../store/actions/UserActions';
import logo from "../../../images/logo-forum.png";
import Field from '../../components/Forms/Field';
import Select from "../../components/Forms/Select";
import WilayaOfAlgeria from "../../../helpers/Wilaya_Of_Algeria.json";
import listeSpecialites from "../../../helpers/Liste_specialites.json"; 
import listeTitres from "../../../helpers/Titre_medecin.json";  
import HeaderNoAuth from '../../components/HeaderNoAuth';
import swal from 'sweetalert';

const User = ({getEventById, 
    addUser, 
    updateUser, 
    clearMsgAddUser, 
    getUserById,
    currentEvenement, 
    fechedUser,
    createUserErrors,
    addUserrrorsDesc,
    updateUserErrors,
    updateUserErrorsDesc,
    fetchedUser
    }) => {

    const [event, setEvent]= useState({});
    const [editing, setEditing]= useState(false); 
    const [loading, setLoading]= useState(true);
    const [fetchedId, setFetchedId]= useState();

    const [credentials, setCredentials]= useState({
        firstName: "",
        lastName: "",
        username: "",
        password: "",
        confirmPassword: "",
        email: "",
      })

    const [errors, setErrors]= useState({
        firstName: "",
        lastName: "",
        username: "",
        password: "",
        confirmPassword: "",
        email: "",
    });

    const params = useParams();
    const navigate = useNavigate();
    let {id}= params;
    let {idUser= "new"}= params;

    //Get types au chargement
    useEffect( () => {
        if(idUser != "new"){
            setEditing(true);
            getUserById(idUser);
        }else{
            setEditing(false);
        }
    }, [idUser] )

    useEffect( () => {

        const fetchData = async () => 
        {
            if(fetchedUser && idUser != "new"){
                setLoading(true);
                setCredentials(fetchedUser);
                setFetchedId(idUser);
            }
            setLoading(false);
        }

        fetchData();
        
    }, [fetchedUser] )

    useEffect( () => {
        if(createUserErrors){

               const apiErrors={};
               const violations=createUserErrors;
               if(violations && Array.isArray(violations)){
                violations.forEach(({propertyPath, message}) => { 
                  apiErrors[propertyPath]= message;
                  } );
                  setErrors(apiErrors);
               }
                      
        }
      
      }, [createUserErrors])

    useEffect( () => {
        if(addUserrrorsDesc){
            swal("Impossible de vous inscrire", addUserrrorsDesc, "error")
        }
      
      }, [addUserrrorsDesc])

    useEffect( () => {
        if(updateUserErrors){
               const apiErrors={};
               const violations=updateUserErrors;
               if(violations && Array.isArray(violations)){
                violations.forEach(({propertyPath, message}) => { 
                  apiErrors[propertyPath]= message;
                  } );
                  setErrors(apiErrors);
               }
                      
        }
      
      }, [updateUserErrors])

    useEffect( () => {
        if(updateUserErrorsDesc){
            swal("Impossible de vous inscrire", updateUserErrorsDesc, "error")
        }
      
      }, [updateUserErrorsDesc])
 
    useEffect( () => {  

        if(currentEvenement){
               setEvent(currentEvenement); 
        } 

     }, [currentEvenement])

    //Récupération d'un event
    const fetchEvent= async id  => {
        try {
               await getEventById(id);
               if(!editing){
                setLoading(false);
               }
        } catch (error) {
               toast.error("Impossible de charger l'évenement");  
               Navigate("/");
        }
    }

    // Chargement de l'event au chargement du composent ou changement de l'id  
    useEffect( () => {
        setLoading(true);
        fetchEvent(id);
    }, [id])

    const onSignup = async (e) =>{
        e.preventDefault();

        setErrors({});
        clearMsgAddUser();

        if(credentials.password != credentials.confirmPassword){
            toast.error("Une erreur est survenue !");
            setErrors({errors, emailConfirm: "Les deux adresses email doivent être identiques"});
        }else {

            if(editing){

                try {

                    let user= credentials;
                    await updateUser(idUser, user);
                    //if response
                    setErrors({});
                    swal("L'utilisateur est à jour", "success");
                    navigate("/" + id + "/admin/users");
                }   catch (err) {
                    toast.error("Une erreur est survenue !");
                }

            } else{
                try {

                    let user= credentials;
                    await addUser(user);
                    //if response
                    setErrors({});
                    swal("L'utilisateur est bien enregistré", "success");
                }   catch (err) {
                    toast.error("Une erreur est survenue !");
    
                }
            }
            
        }

    }

    //Gestion des champs
    const handleChangeFields = ({currentTarget}) =>{
        const {value, name}= currentTarget;
        setCredentials({ ...credentials, [name]: value });
    }

    useLayoutEffect(() => {
        return () => {
               setErrors({});
               clearMsgAddUser();
               setEditing(false);
        }
    }, [])


    return (
        <>
            <div className="row page-titles">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active"><Link to={"/" + id + "/admin/users"}> Utilisateurs</Link></li>
                    <li className="breadcrumb-item active"><Link to={"#"}> {editing ? "Modification": "Ajout"} </Link></li>
                </ol>
            </div>

            <div className="row tableRow ajoutInvite">
                <div className="col-lg-12">

                    <div className="card">

                        <div className="card-header">
                            <h4 className="card-title">
                            {editing ? "Modification | "
                            : "Ajout d'un utilisateur"}

                            {editing && !loading && fetchedUser &&
                            fetchedUser.lastName}

                             </h4>

                                <Link to={"/" + id + "/admin/users"} className="btn btn-primary">
                                    <i class="fas fa-undo"  style={{ marginRight: "5px",}}></i>  
                                    Retour à la liste
                                </Link>

                        </div>

                    </div>

                </div>

                <div className='eventPage'>

        <div className="row">
                        <div className="col-lg-12">
                            <div className="card">

                                <div className="eventPageFragment">
                                    {!loading &&
                                    <Container>

                                        <Row>

                                            <Col md="12">
                                            <form onSubmit={onSignup}>
                                                
                                                <Row>

                                                <Col md="6">
                                                    <Field
                                                        name="lastName"
                                                        value={credentials.lastName}
                                                        onChange={handleChangeFields}
                                                        label= "Nom *"
                                                        placeholder="Nom"
                                                        error={errors.lastName}
                                                        type="text"
                                                        required="true" 
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    <Field
                                                        name="firstName"
                                                        value={credentials.firstName}
                                                        onChange={handleChangeFields}
                                                        label= "Prénom *"
                                                        placeholder="Prénom"
                                                        error={errors.firstName}
                                                        type="text"
                                                        required="true" 
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    <Field
                                                        name="username"
                                                        value={credentials.username}
                                                        onChange={handleChangeFields}
                                                        label= "Nom d'utilisateur *"
                                                        placeholder="Nom d'utilisateur"
                                                        error={errors.username}
                                                        type="text"
                                                        required="true" 
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    <Field
                                                        name="email"
                                                        value={credentials.email}
                                                        onChange={handleChangeFields}
                                                        label= "Email *"
                                                        placeholder="Email"
                                                        error={errors.email}
                                                        type="email"
                                                        required="true" 
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    <Field
                                                        name="password"
                                                        value={credentials.password}
                                                        onChange={handleChangeFields}
                                                        label= "Mot de passe *"
                                                        placeholder="Mot de passe"
                                                        error={errors.password}
                                                        type="password"
                                                        required="true" 
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    <Field
                                                        name="confirmPassword"
                                                        value={credentials.confirmPassword}
                                                        onChange={handleChangeFields}
                                                        label= "Confirmer mot de passe *"
                                                        placeholder="Confirmer mot de passe"
                                                        error={errors.confirmPassword}
                                                        type="password"
                                                        required="true" 
                                                        />
                                                </Col>
                                                

                                                </Row>

                                                <div className="text-center">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary btn-lg"
                                                    >
                                                        {editing ? "Enregistrer" : "Ajouter"}
                                                    </button>
                                                </div>
                                            </form>
                                            </Col>

                                        </Row>

                                    </Container>
                                    }
                                    
                                    
                                </div>

                            </div>
                        </div>
        </div>

        </div>

            </div>

        </>
    );
};

const mapStateToProps = (state) => {
    return {
        evenements: state.evenementState.evenements,
        currentEvenement: state.evenementState.currentEvenement,
        createUserErrors: state.userState.createUserErrors,
        createUserErrorsDesc: state.userState.createUserErrorsDesc,
        updateUserErrors: state.userState.updateUserErrors,
        updateUserErrorsDesc: state.userState.updateUserErrorsDesc,
        fetchedUser: state.userState.fetchedUser
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        { getEventById, 
        addUser, 
        updateUser, 
        clearMsgAddUser, 
        getUserById },
        dispatch,
    );
};


export default connect(mapStateToProps, mapDispatchToProps)(User); 