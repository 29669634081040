// @flow 
import React,{useState, useEffect, useRef} from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import { toast } from 'react-toastify';
import Pagination from '../PaginationTable';
import { getAdherents, deleteAdherent} from '../../../store/actions/AdherentActions';

import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { formatDate } from '../../../helpers/FormatHelpers';

const Adherents = ({getAdherents, 
    adherents, 
    deleteAdherent
    }) => {

    const [currentPage, setCurrentPage]= useState(1);
    const [search, setSearch]= useState("");
    const [loading, setLoading]= useState(true);
    const [adherentsListe, setAdherentsListe]= useState([]);
    const [error, setError]= useState();
    const [confirmationVisible, setConfirmationVisible]= useState();
    const [adherentForDelete, setAdherentForDelete]= useState();
    const [adherentForDeleteInfo, setAdherentForDeleteInfo]= useState();

    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    let {id}= params;


    //Get adherents
    const fetchAdherents = async () => {
        try {
            getAdherents(id);
            setLoading(false);
        } catch (error) {
            toast.error("Erreur lors du chargement des adherents");
        }
    }


    useEffect(() => {
        fetchAdherents();
     }, []);

    //Get adherents au chargement
    useEffect( () => {
        if(adherents){
            if(! adherents.length==0){
                setAdherentsListe(adherents);
            }
        }
        

    }, [adherents] )


    //Change current page
    const handleChangePage = page => setCurrentPage(page);
    
    //Recherche
    const handleSearch = ({currentTarget}) => { 
        const value= currentTarget.value;
        setSearch(value);
        setCurrentPage(1);
    }

    // Rénitialiser les filtres
    const resetFilters= () => {
        setSearch("");
    }

    const itemsPerPage =10;
    
    //Filtrage des types en fonction de la recherche
    let filtredAdherents;

    if(adherentsListe && adherentsListe.length >0){         
        filtredAdherents= adherentsListe.filter(
            t =>
            (t.id && t.id.toString().includes(search)) ||
            (t.nom && t.nom.toLowerCase().includes(search.toLowerCase())) ||
            (t.prenom && t.prenom.toLowerCase().includes(search.toLowerCase())) ||
            (t.tel && t.tel.toLowerCase().includes(search.toLowerCase())) ||
            (t.email && t.email.toLowerCase().includes(search.toLowerCase()))
        );
    }

    // Au cas ou le résultat de la recherche serait null
    useEffect( () => {
        if(filtredAdherents && filtredAdherents.length == 0 && search!= ""){
            setError("Désolé, aucun résultat n'a été trouvé");
        }else if (filtredAdherents && filtredAdherents.length>0 ){
            setError();
        }
    }, [filtredAdherents] )
    
    //Pagination
    let paginatedAdherents;
    if (filtredAdherents && filtredAdherents.length >0){
        paginatedAdherents= Pagination.getData(filtredAdherents, currentPage, itemsPerPage);
    }

    const handleDelete = (item) => {
        setAdherentForDelete(item.id);
        setAdherentForDeleteInfo(item.nom);
        setConfirmationVisible(true);
    }

    const handleDeleteConfirm = async id => {
        setConfirmationVisible(false)
        try {
            await deleteAdherent(id);
            toast.success("L'adherent a bien été supprimé");
        } catch (error) {
            toast.error("Une erreur est survenue");
        }

    }

    const checkValidiyAdhestion= (adherent) => {
        if(adherent.date_fin_adhesion){
            const dateFinAdhesion = new Date(adherent.date_fin_adhesion);
            const dateAujourdhui = new Date();
            return dateAujourdhui <= dateFinAdhesion;
        }else {
            return false
        }
    }

    return (
        <>

            <div className="row page-titles">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active"><Link to={"#"}> Adhérents</Link></li>
                </ol>
            </div>

            <div className="row tableRow">
                <div className="col-lg-12">

                    <div className="card">

                        <div className="card-header">
                            <h4 className="card-title">Liste des adherents</h4>
                            <Link to={location.pathname + "/new"} className="btn btn-primary">Ajouter</Link>
                        </div>
                        
                        <div className="card-body">
                            <div className="table-responsive ticket-table">
                                <div id="ticket_wrapper" className="dataTables_wrapper no-footer">
                                    
                                    <Container>
                                        <Row>
                                            <Col md={11}>
                                                <div className="col-12 d-flex align-items-center">
                                                    <label className="me-2 mt-2">Recherche:</label>
                                                    <input 
                                                        className="form-control"
                                                        type="search" 
                                                        placeholder="Nom | Prénom | Tel | Email ..." 
                                                        onChange={handleSearch}
                                                        value={search}
                                                    />
                                                </div>
                                            </Col>

                                            <Col md={1}>

                                                <Button className="btn btn-primary shadow btn-md sharp me-1 restartBtn" onClick={resetFilters}>
                                                    <i class="fas fa-undo"></i>                                                
                                                </Button>
                                            </Col>

                                        </Row>
                                    </Container>
                                        

                                    <table id="example" className="display dataTablesCard table-responsive-xl dataTable no-footer w-100">
                                        
                                    { (paginatedAdherents && paginatedAdherents.length>0) &&
                                        <thead>
                                            <tr className="text-center">
												<th>Adherent</th>
												<th>Statut</th>
												<th>Nom</th>
												<th>Tel</th>
												<th>Email</th>
												<th>Montant cotisation</th>
												<th>Adresse</th>
												<th>Début adhésion</th>
												<th>Fin adhésion</th>
												<th>Actions</th>                                          
                                            </tr>
                                        </thead>
                                    }

                                        {!loading && (
                                        <tbody>
                                            {paginatedAdherents &&  paginatedAdherents.map((item, index)=>(
                                                <tr key={index} className="text-center">

                                                    <td>
                                                        <span className={checkValidiyAdhestion(item) ? "badge badge-success badge-lg" : "badge badge-danger badge-lg"}>{item.id}</span>
                                                    </td>

                                                    <td>{checkValidiyAdhestion(item) ?
                                                     <span className="badge badge-success badge-lg">Actif</span>
                                                     : 
                                                     item.date_fin_adhesion ? 
                                                        <span className="badge badge-danger badge-lg">Expiré</span>
                                                        :
                                                        <span className="badge badge-danger badge-lg">Date non mentionnée</span>
                                                     }
                                                     </td>
                                                    
                                                    <td>						
                                                            <span className={checkValidiyAdhestion(item) ? "badge light badge-success" : "badge light badge-danger"}>
                                                                <Link to={"#"} className="h5">{item.prenom + " " + item.nom}</Link>
                                                            </span>
                                                        
                                                    </td>
                                                    <td>{item.tel && item.tel}</td>
                                                    <td>{item.email && item.email}</td>
                                                    <td>{item.montant_cotisation && item.montant_cotisation + " DA"} </td>
                                                    <td>{item.adresse && item.adresse}</td>
                                                    <td>{item.date_debut_adhesion && formatDate(item.date_debut_adhesion)}</td>
                                                    <td>{item.date_fin_adhesion && formatDate(item.date_fin_adhesion)}</td>
                                                    
                                                    <td>
                                                        <div className="d-flex">

                                                            <Link to={location.pathname + "/" + item.id} className="btn btn-warning shadow btn-md sharp me-1"
                                                            >
                                                                <i className="fas fa-pencil-alt fa-lg"></i>
                                                            </Link>

                                                            <Link to={"#"} className="btn btn-danger shadow btn-md sharp me-1"
                                                            onClick={() => handleDelete(item)}>
                                                                <i className="fa fa-trash fa-lg"></i>
                                                            </Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                            
                                        </tbody>
                                        )}

                                        
                                        
                                    </table>

                                    {filtredAdherents ? filtredAdherents.length > itemsPerPage && ( 
                                        <Pagination currentPage={currentPage} 
                                        itemsPerPage={itemsPerPage} 
                                        length={filtredAdherents.length} 
                                        onPageChanged={handleChangePage} />) 
                                        : null}

                                    {/* <!-- Modal --> */}
                                    <Modal className="fade" show={confirmationVisible}>
                                    <Modal.Header>
                                        <Modal.Title>Supression de l'adherent {adherentForDeleteInfo}</Modal.Title>
                                        <Button
                                            variant=""
                                            className="close"
                                        >
                                            <span>&times;</span>
                                        </Button>
                                    </Modal.Header>
                                    <Modal.Body>êtes-vous sûr de vouloir continuer ?</Modal.Body>
                                    <Modal.Footer>
                                        <Button
                                            onClick={() => setConfirmationVisible(false)}
                                            variant="danger light"
                                        >
                                            Annuler
                                        </Button>
                                        <Button variant="primary"  onClick={() => handleDeleteConfirm(adherentForDelete)}>
                                            Continuer
                                        </Button>
                                    </Modal.Footer>
                                    </Modal>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: "",
        adherents: state.adherentState.adherents,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {   getAdherents, 
            deleteAdherent},
        dispatch,
    );
};


export default connect(mapStateToProps, mapDispatchToProps)(Adherents); 