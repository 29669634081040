// @flow 
import React,{useState, useEffect, useRef} from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import { toast } from 'react-toastify';
import Pagination from '../PaginationTable';
import { getAteliers, deleteAtelier } from '../../../store/actions/AtelierActions';

import { Badge, Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { formatDate, formatDateTime } from '../../../helpers/FormatHelpers';

const Ateliers = ({getAteliers, 
    ateliers, 
    deleteAtelier
    }) => {

    const [currentPage, setCurrentPage]= useState(1);
    const [search, setSearch]= useState("");
    const [loading, setLoading]= useState(true);
    const [ateliersListe, setAteliersListe]= useState([]);
    const [error, setError]= useState();
    const [confirmationVisible, setConfirmationVisible]= useState();
    const [atelierForDelete, setAtelierForDelete]= useState();
    const [atelierForDeleteInfo, setAtelierForDeleteInfo]= useState();

    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    let {id}= params;


    //Get ateliers
    const fetchAteliers = async () => {
        try {
            getAteliers(id);
            setLoading(false);
        } catch (error) {
            toast.error("Erreur lors du chargement des ateliers");
        }
    }


    useEffect(() => {
        fetchAteliers();
     }, []);

    //Get ateliers au chargement
    useEffect( () => {
        if(ateliers){
            if(! ateliers.length==0){
                setAteliersListe(ateliers);
            }
        }
        

    }, [ateliers] )


    //Change current page
    const handleChangePage = page => setCurrentPage(page);
    
    //Recherche
    const handleSearch = ({currentTarget}) => { 
        const value= currentTarget.value;
        setSearch(value);
        setCurrentPage(1);
    }

    // Rénitialiser les filtres
    const resetFilters= () => {
        setSearch("");
    }

    const itemsPerPage =10;
    
    //Filtrage des types en fonction de la recherche
    let filtredAteliers;

    if(ateliersListe && ateliersListe.length >0){         
        filtredAteliers= ateliersListe.filter(
            t =>
            (t.id && t.id.toString().includes(search)) ||
            (t.nom && t.nom.toLowerCase().includes(search.toLowerCase())) ||
            (t.description && t.description.toLowerCase().includes(search.toLowerCase()))
        );
    }

    // Au cas ou le résultat de la recherche serait null
    useEffect( () => {
        if(filtredAteliers && filtredAteliers.length == 0 && search!= ""){
            setError("Désolé, aucun résultat n'a été trouvé");
        }else if (filtredAteliers && filtredAteliers.length>0 ){
            setError();
        }
    }, [filtredAteliers] )
    
    //Pagination
    let paginatedAteliers;
    if (filtredAteliers && filtredAteliers.length >0){
        paginatedAteliers= Pagination.getData(filtredAteliers, currentPage, itemsPerPage);
    }

    const handleDelete = (item) => {
        setAtelierForDelete(item.id);
        setAtelierForDeleteInfo(item.nom);
        setConfirmationVisible(true);
    }

    const handleDeleteConfirm = async id => {
        setConfirmationVisible(false)
        try {
            await deleteAtelier(id);
            toast.success("L'atelier a bien été supprimé");
        } catch (error) {
            toast.error("Une erreur est survenue");
        }

    }

    return (
        <>

            <div className="row page-titles">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active"><Link to={"#"}> Ateliers</Link></li>
                </ol>
            </div>

            <div className="row tableRow">
                <div className="col-lg-12">

                    <div className="card">

                        <div className="card-header">
                            <h4 className="card-title">Liste des ateliers</h4>
                            <Link to={location.pathname + "/new"} className="btn btn-primary">Ajouter</Link>
                        </div>
                        
                        <div className="card-body">
                            <div className="table-responsive ticket-table">
                                <div id="ticket_wrapper" className="dataTables_wrapper no-footer">
                                    
                                    <Container>
                                        <Row>
                                            <Col md={11}>
                                                <div className="col-12 d-flex align-items-center">
                                                    <label className="me-2 mt-2">Recherche:</label>
                                                    <input 
                                                        className="form-control"
                                                        type="search" 
                                                        placeholder="Nom" 
                                                        onChange={handleSearch}
                                                        value={search}
                                                    />
                                                </div>
                                            </Col>

                                            <Col md={1}>

                                                <Button className="btn btn-primary shadow btn-md sharp me-1 restartBtn" onClick={resetFilters}>
                                                    <i class="fas fa-undo"></i>                                                
                                                </Button>
                                            </Col>

                                        </Row>
                                    </Container>
                                        

                                    <table id="example" className="display dataTablesCard table-responsive-xl dataTable no-footer w-100">
                                        
                                    { (paginatedAteliers && paginatedAteliers.length>0) &&
                                        <thead>
                                            <tr>
												<th>Atelier</th>
												<th>Nom</th>
												<th>Déscription</th>
												<th>Date début</th>
												<th>Date fin</th>
												<th>Présents</th>
												<th>Inscrits</th>
												<th>Actions</th>                                          
                                            </tr>
                                        </thead>
                                    }

                                        {!loading && (
                                        <tbody>
                                            {paginatedAteliers &&  paginatedAteliers.map((item, index)=>(
                                                <tr key={index}>

                                                    <td>
                                                        <span className="badge badge-success badge-lg">{item.id}</span>
                                                    </td>
                                                    
                                                    <td>						
                                                            <span className="badge light badge-success">
                                                                <Link to={"#"} className="h5">{item.nom}</Link>
                                                            </span>
                                                        
                                                    </td>
                                                    <td>{item.description && item.description}</td>
                                                    <td>{item.date_debut && formatDateTime(item.date_debut)}</td>
                                                    <td>{item.date_fin && formatDateTime(item.date_fin)}</td>
                                                    <td>
                                                        <span className="badge badge-success badge-lg">
                                                        {item.inscriptions ?
                                                        item.inscriptions.filter(inscription => inscription.checked === true).length 
                                                        : 
                                                        0}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="badge badge-success badge-lg">
                                                            {item.inscriptions ?
                                                                item.inscriptions.length 
                                                                
                                                            : 
                                                            0}
                                                        </span>
                                                     </td>
                                                    <td>
                                                        <div className="d-flex">

                                                            <Link to={location.pathname + "/" + item.id} className="btn btn-warning shadow btn-md sharp me-1"
                                                            >
                                                                <i className="fas fa-pencil-alt fa-lg"></i>
                                                            </Link>

                                                            <Link to={"#"} className="btn btn-danger shadow btn-md sharp me-1"
                                                            onClick={() => handleDelete(item)}>
                                                                <i className="fa fa-trash fa-lg"></i>
                                                            </Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                            
                                        </tbody>
                                        )}

                                        
                                        
                                    </table>

                                    {filtredAteliers ? filtredAteliers.length > itemsPerPage && ( 
                                        <Pagination currentPage={currentPage} 
                                        itemsPerPage={itemsPerPage} 
                                        length={filtredAteliers.length} 
                                        onPageChanged={handleChangePage} />) 
                                        : null}

                                    {/* <!-- Modal --> */}
                                    <Modal className="fade" show={confirmationVisible}>
                                    <Modal.Header>
                                        <Modal.Title>Supression de l'atelier {atelierForDeleteInfo}</Modal.Title>
                                        <Button
                                            variant=""
                                            className="close"
                                        >
                                            <span>&times;</span>
                                        </Button>
                                    </Modal.Header>
                                    <Modal.Body>êtes-vous sûr de vouloir continuer ?</Modal.Body>
                                    <Modal.Footer>
                                        <Button
                                            onClick={() => setConfirmationVisible(false)}
                                            variant="danger light"
                                        >
                                            Annuler
                                        </Button>
                                        <Button variant="primary"  onClick={() => handleDeleteConfirm(atelierForDelete)}>
                                            Continuer
                                        </Button>
                                    </Modal.Footer>
                                    </Modal>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: "",
        ateliers: state.atelierState.ateliers,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {   getAteliers, 
            deleteAtelier},
        dispatch,
    );
};


export default connect(mapStateToProps, mapDispatchToProps)(Ateliers); 