export const API_URL = process.env.REACT_APP_API_URl;

export const USERS_API= API_URL + "users";
export const LOGIN_API= API_URL + "login_check";
export const EVENEMENTS_API= API_URL + "evenements";
export const INVITES_API= API_URL + "invites";
export const ORGANISATEURS_API= API_URL + "organisateurs";
export const INSCRIPTIONS_API= API_URL + "inscriptions";
export const SPONSORS_API= API_URL + "sponsors";
export const PRESSES_API= API_URL + "presses";
export const ATTESTATIONS_API= API_URL + "attestations";
export const PARTENAIRES_API= API_URL + "partenaires";
export const ORGANISMES_API= API_URL + "organismes";
export const ATELIERS_API= API_URL + "ateliers";
export const PDF_GENERATION_API= API_URL + "pdf-generator";
export const PDF_ORG_GENERATION_API= API_URL + "pdf-org-generator";
export const PDF_SPO_GENERATION_API= API_URL + "pdf-spo-generator";
export const PDF_PRE_GENERATION_API= API_URL + "pdf-pre-generator";
export const PDF_ATTESTATION_GENERATION_API= API_URL + "pdf-attestation-generator";
export const PDF_ATTESTATION_SEND_API= API_URL + "pdf-attestation-send";
export const PDF_CARTE_ADHERENT_API= API_URL + "pdf-carte-adherent-generator";
export const CHECKOUT_STATUS_API= API_URL + "checkoutstatus";
export const INSCRIPTION_ATELIERS_API= API_URL + "inscriptionateliers";
export const ADHERENTS_API= API_URL + "adherents";

// Checkout
export const CREATE_CHECKOUT_API= API_URL + "checkout/create";


// Statistics
export const COUNT_INVITES_API= API_URL + "count/invites";
export const COUNT_CHECKED_INVITES_API= API_URL + "count/checkedInvites";
export const COUNT_PAID_INVITES_API= API_URL + "count/paidInvites";
export const COUNT_ORGANISATEURS_API= API_URL + "count/organisateurs";
export const COUNT_ORATEURS_API= API_URL + "count/orateurs";
export const COUNT_SPONSORS_API= API_URL + "count/sponsors";
export const COUNT_PRESSES_API= API_URL + "count/presses";
export const COUNT_SPECIALITES_API= API_URL + "count/specialites";
export const COUNT_BEST_SPECIALITE_API= API_URL + "specialites/better";
export const COUNT_BEST_VILLE_API= API_URL + "villes/better";
export const STAT_INSCRIPTIONS_API= API_URL + "inscriptiondays";
