import { FETCH_PDF_SUCCESS } from "../actions/PdfActions";

  
  const defaultState = {};
  
  export default function (state = defaultState, action) {

    const { type, payload } = action;
    
    switch (type) {
      
      case FETCH_PDF_SUCCESS:
        return {
          ...state,
          currentPdf: action.currentPdf
        };


      default:
        return state;
    }
  }
  