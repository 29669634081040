import Axios from "axios";
import { PDF_GENERATION_API } from "../../services/config";
import authHeader from "../../helpers/auth-header.js";

export const FETCH_PDF_SUCCESS= "FETCH_PDF_SUCCESS";

export const printPdf = (idInscription, idEvenement, idInvite) => (dispatch) => {

  let formData = new FormData();
  formData.append('idInscription', idInscription);
  formData.append('idEvenement', idEvenement);
  formData.append('idInvite', idInvite);

  return Axios.post(PDF_GENERATION_API, formData, {headers: authHeader()}).then(
    (data) => {

      
      dispatch({
        type: FETCH_PDF_SUCCESS,
        currentPdf: data.data
      });


      let pdf= data.data.pdf;

      const pdfBlob = base64ToBlob(pdf, 'application/pdf');
      const pdfURL = URL.createObjectURL(pdfBlob);

      let iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = pdfURL;

      iframe.onload = function() {
        setTimeout(function() {
          iframe.focus();
          iframe.contentWindow.print();
          URL.revokeObjectURL(pdfURL); // Libère la mémoire utilisée par l'URL du blob
        }, 1000); // Attend une seconde pour s'assurer que le contenu de l'iframe est chargé
      };

      document.body.appendChild(iframe);

      return Promise.resolve();
    },
    (error) => {

        throw new Error(error)
    }    
  );
  
};

export const printPdfBrowser = (idInscription, idEvenement, idInvite) => (dispatch) => {

  let formData = new FormData();
  formData.append('idInscription', idInscription);
  formData.append('idEvenement', idEvenement);
  formData.append('idInvite', idInvite);

  return Axios.post(PDF_GENERATION_API, formData, {headers: authHeader()}).then(
    (data) => {

      
      dispatch({
        type: FETCH_PDF_SUCCESS,
        currentPdf: data.data
      });


      let pdf= data.data.pdf;

      const pdfBlob = base64ToBlob(pdf, 'application/pdf');
      
      const pdfURL = URL.createObjectURL(pdfBlob);
      window.open(pdfURL, '_blank');

      return Promise.resolve();
    },
    (error) => {

        throw new Error(error)
    }    
  );

}

export const downloadPdf = (idInscription, idEvenement, idInvite) => (dispatch) => {

  let formData = new FormData();
  formData.append('idInscription', idInscription);
  formData.append('idEvenement', idEvenement);
  formData.append('idInvite', idInvite);

  return Axios.post(PDF_GENERATION_API, formData, {headers: authHeader()}).then(
    (data) => {

      
      dispatch({
        type: FETCH_PDF_SUCCESS,
        currentPdf: data.data
      });


      let pdf= data.data.pdf;

      const pdfBlob = base64ToBlob(pdf, 'application/pdf');
      
      const linkSource = `data:application/pdf;base64,${pdf}`;
      const downloadLink = document.createElement("a");
      const fileName = "badge-" + idInscription + ".pdf";

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();

      return Promise.resolve();
    },
    (error) => {

        throw new Error(error)
    }    
  );
}


export function base64ToBlob(base64Data, contentType) {
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
}
