import { CREATE_USER_FAIL, CREATE_USER_FAIL_DESC, DELETE_USER, FETCH_USER, FETCH_USERS, UPDATE_USER_FAIL, UPDATE_USER_FAIL_DESC, UPDATE_USER_SUCCESS } from "../actions/UserActions";

  
  const defaultState = {};
  
  export default function (state = defaultState, action) {

    const { type, payload } = action;
    
    switch (type) {

    case FETCH_USERS:
        return {
          ...state,
          users: action.users
        };

      case FETCH_USER:
        return {
          ...state,
          fetchedUser: action.fetchedUser
        };

      case CREATE_USER_FAIL:
        return {
          ...state,
          createUserErrors: action.createUserErrors
        };

    case CREATE_USER_FAIL_DESC:
        return {
          ...state,
          createUserErrorsDesc: action.createUserErrorsDesc
        };
      

      case UPDATE_USER_SUCCESS:
        return {
          ...state,
          fetchedUser: action.fetchedUser
        };
      
      case UPDATE_USER_FAIL:
        return {
          ...state,
          updateUserErrors: action.updateUserErrors
        };
      
      case UPDATE_USER_FAIL_DESC:
        return {
          ...state,
          updateUserErrorsDesc: action.updateUserErrorsDesc
        };

    case DELETE_USER:
            const usersUpdated= state.users.filter(c  => c.id != action.id);
  
          return {
            ...state,
            users: usersUpdated,
          };

      default:
        return state;
    }
  }
  