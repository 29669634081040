import Axios from "axios";
import authHeader from "../../helpers/auth-header.js";
import { EVENEMENTS_API, PDF_PRE_GENERATION_API, PRESSES_API } from "../../services/config";
import { base64ToBlob } from "./PdfActions.jsx";

export const FETCH_PRESSES= "FETCH_PRESSES";
export const FETCH_CURRENT_PRESSE= "FETCH_CURRENT_PRESSE";
export const ADD_PRESSE_SUCCESS= "ADD_PRESSE_SUCCESS";
export const ADD_PRESSE_FAIL= "ADD_PRESSE_FAIL";
export const ADD_PRESSE_FAIL_DESC= "ADD_PRESSE_FAIL_DESC";
export const UPDATE_PRESSE_SUCCESS= "UPDATE_PRESSE_SUCCESS";
export const UPDATE_PRESSE_FAIL= "UPDATE_PRESSE_FAIL";
export const UPDATE_PRESSE_FAIL_DESC= "UPDATE_PRESSE_FAIL_DESC";
export const DELETE_PRESSE= "DELETE_PRESSE";
export const FETCH_PDF_SUCCESS= "FETCH_PDF_SUCCESS";

export const getPresses = (selectedEventId) => (dispatch) => {

  return Axios.get(EVENEMENTS_API + "/" + selectedEventId + "/presses", { headers: authHeader() }).then(
    (data) => {
      
      dispatch({
        type: FETCH_PRESSES,
        presses: data.data['hydra:member']
              });
      return Promise.resolve();
    },
    (error) => {

          throw new Error(error);
     
    }    
  );
  
};

export const getPresseById = (id) => (dispatch) => {

    return Axios.get(PRESSES_API+ "/" + id,{ headers: authHeader() })
  .then(
    (data) => {
     
      dispatch({
        type: FETCH_CURRENT_PRESSE,
        currentPresse: data.data
      });
  
      return Promise.resolve();
    },
  
    (error) => {
        throw new Error(error)       
    }    
  );
  
};

export const addPresse = (presse, evenement, userId) => (dispatch) => {
    
      presse.evenement= "/api/evenements/" + evenement.id;
      presse.user= "/api/users/" + userId;
      presse.nombreBadges=  Number(presse.nombreBadges);
    
      return Axios.post(PRESSES_API, presse, {headers: authHeader()}).then(
        (data) => {
    
          let presse= data.data;
  
          dispatch({
            type: ADD_PRESSE_SUCCESS,
            currentPresse: presse
                  });
          
          dispatch({
            type: ADD_PRESSE_FAIL,
            addPresseErrors: null
                  });
          return Promise.resolve();
        },
        (error) => {
    
          const message =error.response.data;
          const {violations} = message;
          const errorDescription = message["hydra:description"];
          if(violations){
    
            dispatch({
              type: ADD_PRESSE_FAIL,
              addPresseErrors: violations
                    });
                    throw new Error(error);
          } else if(errorDescription){
            dispatch({
              type: ADD_PRESSE_FAIL_DESC,
              addPresseErrorsDesc: errorDescription
                    });
                    throw new Error(error);
          } else{
            throw new Error(error)
          }
         
        }    
      );
    
};

export const updatePresse = (id, presse, evenement, userId) => (dispatch) => {

    presse.evenement= "/api/evenements/" + evenement.id;
    presse.user= "/api/users/" + userId;
    presse.nombreBadges=  Number(presse.nombreBadges);

    return Axios.put(PRESSES_API + "/" + id, presse, {headers: authHeader()}).then(
      (data) => {
  
        let presse= data.data;
  
        dispatch({
          type: UPDATE_PRESSE_SUCCESS,
          currentPresse: presse
                });
        
        dispatch({
          type: UPDATE_PRESSE_FAIL,
          updatePresseErrors: null
                });
        return Promise.resolve();
      },
      (error) => {
  
        const message =error.response.data;
        const {violations} = message;
        const errorDescription = message["hydra:description"];
        if(violations){
  
          dispatch({
            type: UPDATE_PRESSE_FAIL,
            updatePresseErrors: violations
                  });
                  throw new Error(error);
        } else if(errorDescription){
          dispatch({
            type: UPDATE_PRESSE_FAIL_DESC,
            updatePresseErrorsDesc: errorDescription
                  });
                  throw new Error(error);
        } else{
          throw new Error(error)
        }
       
      }    
    );
  
  };

  export const deletePresse = (id) => (dispatch) => {
    
    return Axios.delete(PRESSES_API+"/" + id, {headers: authHeader()}).then(
      (data) => {
  
        dispatch({
          type: DELETE_PRESSE,
          id
        });
        
        return Promise.resolve();
      },
      (error) => {
          throw new Error(error)
      }    
    );
  
  };

  export const clearMsgAddPresse = () => (dispatch) => {

    dispatch({
      type: ADD_PRESSE_FAIL,
      addPresseErrors: null
            });
  
    dispatch({
      type: ADD_PRESSE_FAIL_DESC,
      addPresseErrorsDesc: null
            });
  
    dispatch({
      type: UPDATE_PRESSE_FAIL,
      updatePresseErrors: null
            });
        
    dispatch({
      type: UPDATE_PRESSE_FAIL_DESC,
      updatePresseErrorsDesc: null
            });
  
    return Promise.resolve();
  
  };

  export const printPrePdf = (idPresse, idEvenement) => (dispatch) => {
    let formData = new FormData();
    formData.append('idPresse', idPresse);
    formData.append('idEvenement', idEvenement);
  
    return Axios.post(PDF_PRE_GENERATION_API, formData, {headers: authHeader()}).then(
      (data) => {
  
        
        dispatch({
          type: FETCH_PDF_SUCCESS,
          currentPdf: data.data
        });
  
  
        let pdf= data.data.pdf;
  
        const pdfBlob = base64ToBlob(pdf, 'application/pdf');
        const pdfURL = URL.createObjectURL(pdfBlob);
  
        let iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = pdfURL;
  
        iframe.onload = function() {
          setTimeout(function() {
            iframe.focus();
            iframe.contentWindow.print();
            URL.revokeObjectURL(pdfURL); // Libère la mémoire utilisée par l'URL du blob
          }, 1000); // Attend une seconde pour s'assurer que le contenu de l'iframe est chargé
        };
  
        document.body.appendChild(iframe);
  
        return Promise.resolve();
      },
      (error) => {
  
          throw new Error(error)
      }    
    );
    
  };
  
  export const printPrePdfBrowser = (idPresse, idEvenement) => (dispatch) => {

    let formData = new FormData();
    formData.append('idPresse', idPresse);
    formData.append('idEvenement', idEvenement);
  
    return Axios.post(PDF_PRE_GENERATION_API, formData, {headers: authHeader()}).then(
      (data) => {
  
        
        dispatch({
          type: FETCH_PDF_SUCCESS,
          currentPdf: data.data
        });
  
  
        let pdf= data.data.pdf;
  
        const pdfBlob = base64ToBlob(pdf, 'application/pdf');
        
        const pdfURL = URL.createObjectURL(pdfBlob);
        window.open(pdfURL, '_blank');
  
        return Promise.resolve();
      },
      (error) => {
  
          throw new Error(error)
      }    
    );
  
  }
  
  export const downloadPrePdf = (idPresse, idEvenement) => (dispatch) => {
  
    let formData = new FormData();
    formData.append('idPresse', idPresse);
    formData.append('idEvenement', idEvenement);

    return Axios.post(PDF_PRE_GENERATION_API, formData, {headers: authHeader()}).then(
      (data) => {
  
        
        dispatch({
          type: FETCH_PDF_SUCCESS,
          currentPdf: data.data
        });
  
  
        let pdf= data.data.pdf;
  
        const pdfBlob = base64ToBlob(pdf, 'application/pdf');
        
        const linkSource = `data:application/pdf;base64,${pdf}`;
        const downloadLink = document.createElement("a");
        const fileName = "badge-pre-" + idPresse + ".pdf";
  
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
  
        return Promise.resolve();
      },
      (error) => {
  
          throw new Error(error)
      }    
    );
  }