import React from 'react';

const Field = ({name, 
                label, 
                value, 
                onChange, 
                placeholder= "", 
                type= "text", 
                error= "",
                required= false}) => (
                    <div className='form-group'>
                    <label className="mb-2" htmlFor={name}>
                         <strong>{label}</strong>
                    </label>
                    {type == "textarea"?
                    <textarea
                    value={value}
                    onChange={onChange}
                    type={type}
                    placeholder={placeholder || label}
                    name={name}
                    id={name}
                    className={'form-control' + ( error && " is-invalid")} />
                    :
                    <input
                    value={value}
                    onChange={onChange}
                    type={type}
                    placeholder={label ? "":  placeholder}
                    name={name}
                    id={name}
                    className={'form-control' + ( error && " is-invalid")}
                    required= {required ? true: false} />
                    }
                    {error && 
                    <div className="text-danger fs-12">{error}</div>
                    }
            </div>
     );

 
export default Field;