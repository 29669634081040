import Axios from "axios";
import authHeader from "../../helpers/auth-header.js";
import { ATTESTATIONS_API, EVENEMENTS_API, PDF_ATTESTATION_GENERATION_API, PDF_ATTESTATION_SEND_API } from "../../services/config.js";
import { base64ToBlob } from "./PdfActions.jsx";

export const FETCH_ATTESTATIONS= "FETCH_ATTESTATIONS";
export const FETCH_CURRENT_ATTESTATION= "FETCH_CURRENT_ATTESTATION";
export const ADD_ATTESTATION_SUCCESS= "ADD_ATTESTATION_SUCCESS";
export const ADD_ATTESTATION_FAIL= "ADD_ATTESTATION_FAIL";
export const ADD_ATTESTATION_FAIL_DESC= "ADD_ATTESTATION_FAIL_DESC";
export const UPDATE_ATTESTATION_SUCCESS= "UPDATE_ATTESTATION_SUCCESS";
export const UPDATE_ATTESTATION_FAIL= "UPDATE_ATTESTATION_FAIL";
export const UPDATE_ATTESTATION_FAIL_DESC= "UPDATE_ATTESTATION_FAIL_DESC";
export const DELETE_ATTESTATION= "DELETE_ATTESTATION";
export const FETCH_PDF_SUCCESS= "FETCH_PDF_SUCCESS";

export const getAttestations = (selectedEventId) => (dispatch) => {

  return Axios.get(EVENEMENTS_API + "/" + selectedEventId + "/attestations", { headers: authHeader() }).then(
    (data) => {
      
      dispatch({
        type: FETCH_ATTESTATIONS,
        attestations: data.data['hydra:member']
              });
      return Promise.resolve();
    },
    (error) => {

          throw new Error(error);
     
    }    
  );
  
};

export const getAttestationById = (id) => (dispatch) => {

    return Axios.get(ATTESTATIONS_API+ "/" + id,{ headers: authHeader() })
  .then(
    (data) => {
     
      dispatch({
        type: FETCH_CURRENT_ATTESTATION,
        currentAttestation: data.data
      });
  
      return Promise.resolve();
    },
  
    (error) => {
        throw new Error(error)       
    }    
  );
  
};

export const addAttestation = (attestation, evenement, userId) => (dispatch) => {
    
      attestation.evenement= "/api/evenements/" + evenement.id;
      attestation.user= "/api/users/" + userId;
      attestation.nombreBadges=  Number(attestation.nombreBadges);
    
      return Axios.post(ATTESTATIONS_API, attestation, {headers: authHeader()}).then(
        (data) => {
    
          let attestation= data.data;
  
          dispatch({
            type: ADD_ATTESTATION_SUCCESS,
            currentAttestation: attestation
                  });
          
          dispatch({
            type: ADD_ATTESTATION_FAIL,
            addAttestationErrors: null
                  });
          return Promise.resolve();
        },
        (error) => {
    
          const message =error.response.data;
          const {violations} = message;
          const errorDescription = message["hydra:description"];
          if(violations){
    
            dispatch({
              type: ADD_ATTESTATION_FAIL,
              addAttestationErrors: violations
                    });
                    throw new Error(error);
          } else if(errorDescription){
            dispatch({
              type: ADD_ATTESTATION_FAIL_DESC,
              addAttestationErrorsDesc: errorDescription
                    });
                    throw new Error(error);
          } else{
            throw new Error(error)
          }
         
        }    
      );
    
};

export const updateAttestation = (id, attestation, evenement, userId) => (dispatch) => {

    attestation.evenement= "/api/evenements/" + evenement.id;
    attestation.user= "/api/users/" + userId;
    attestation.nombreBadges=  Number(attestation.nombreBadges);

    return Axios.put(ATTESTATIONS_API + "/" + id, attestation, {headers: authHeader()}).then(
      (data) => {
  
        let attestation= data.data;
  
        dispatch({
          type: UPDATE_ATTESTATION_SUCCESS,
          currentAttestation: attestation
                });
        
        dispatch({
          type: UPDATE_ATTESTATION_FAIL,
          updateAttestationErrors: null
                });
        return Promise.resolve();
      },
      (error) => {
  
        const message =error.response.data;
        const {violations} = message;
        const errorDescription = message["hydra:description"];
        if(violations){
  
          dispatch({
            type: UPDATE_ATTESTATION_FAIL,
            updateAttestationErrors: violations
                  });
                  throw new Error(error);
        } else if(errorDescription){
          dispatch({
            type: UPDATE_ATTESTATION_FAIL_DESC,
            updateAttestationErrorsDesc: errorDescription
                  });
                  throw new Error(error);
        } else{
          throw new Error(error)
        }
       
      }    
    );
  
  };

  export const deleteAttestation = (id) => (dispatch) => {
    
    return Axios.delete(ATTESTATIONS_API+"/" + id, {headers: authHeader()}).then(
      (data) => {
  
        dispatch({
          type: DELETE_ATTESTATION,
          id
        });
        
        return Promise.resolve();
      },
      (error) => {
          throw new Error(error)
      }    
    );
  
  };

  export const clearMsgAddAttestation = () => (dispatch) => {

    dispatch({
      type: ADD_ATTESTATION_FAIL,
      addAttestationErrors: null
            });
  
    dispatch({
      type: ADD_ATTESTATION_FAIL_DESC,
      addAttestationErrorsDesc: null
            });
  
    dispatch({
      type: UPDATE_ATTESTATION_FAIL,
      updateAttestationErrors: null
            });
        
    dispatch({
      type: UPDATE_ATTESTATION_FAIL_DESC,
      updateAttestationErrorsDesc: null
            });
  
    return Promise.resolve();
  
  };

  export const printAttesPdf = (idAttestation, idEvenement) => (dispatch) => {
    let formData = new FormData();
    formData.append('idAttestation', idAttestation);
    formData.append('idEvenement', idEvenement);
  
    return Axios.post(PDF_ATTESTATION_GENERATION_API, formData, {headers: authHeader()}).then(
      (data) => {
  
        
        dispatch({
          type: FETCH_PDF_SUCCESS,
          currentPdf: data.data
        });
  
  
        let pdf= data.data.pdf;
  
        const pdfBlob = base64ToBlob(pdf, 'application/pdf');
        const pdfURL = URL.createObjectURL(pdfBlob);
  
        let iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = pdfURL;
  
        iframe.onload = function() {
          setTimeout(function() {
            iframe.focus();
            iframe.contentWindow.print();
            URL.revokeObjectURL(pdfURL); // Libère la mémoire utilisée par l'URL du blob
          }, 1000); // Attend une seconde pour s'assurer que le contenu de l'iframe est chargé
        };
  
        document.body.appendChild(iframe);
  
        return Promise.resolve();
      },
      (error) => {
  
          throw new Error(error)
      }    
    );
    
  };
  
  export const printAttesPdfBrowser = (idAttestation, idEvenement) => (dispatch) => {

    let formData = new FormData();
    formData.append('idAttestation', idAttestation);
    formData.append('idEvenement', idEvenement);
  
    return Axios.post(PDF_ATTESTATION_GENERATION_API, formData, {headers: authHeader()}).then(
      (data) => {
  
        
        dispatch({
          type: FETCH_PDF_SUCCESS,
          currentPdf: data.data
        });
  
  
        let pdf= data.data.pdf;
  
        const pdfBlob = base64ToBlob(pdf, 'application/pdf');
        
        const pdfURL = URL.createObjectURL(pdfBlob);
        window.open(pdfURL, '_blank');
  
        return Promise.resolve();
      },
      (error) => {
  
          throw new Error(error)
      }    
    );
  
  }
  
  export const downloadAttesPdf = (idAttestation, idEvenement) => (dispatch) => {
  
    let formData = new FormData();
    formData.append('idAttestation', idAttestation);
    formData.append('idEvenement', idEvenement);

    return Axios.post(PDF_ATTESTATION_GENERATION_API, formData, {headers: authHeader()}).then(
      (data) => {
  
        
        dispatch({
          type: FETCH_PDF_SUCCESS,
          currentPdf: data.data
        });
  
  
        let pdf= data.data.pdf;
  
        const pdfBlob = base64ToBlob(pdf, 'application/pdf');
        
        const linkSource = `data:application/pdf;base64,${pdf}`;
        const downloadLink = document.createElement("a");
        const fileName = "attestation-" + idAttestation + ".pdf";
  
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
  
        return Promise.resolve();
      },
      (error) => {
  
          throw new Error(error)
      }    
    );
  }

  export const sendAttesPdf = (idAttestation, idEvenement) => (dispatch) => {
  
    let formData = new FormData();
    formData.append('idAttestation', idAttestation);
    formData.append('idEvenement', idEvenement);

    return Axios.post(PDF_ATTESTATION_SEND_API, formData, {headers: authHeader()}).then(
      (data) => {
        
        return Promise.resolve();
      },
      (error) => {
  
          throw new Error(error)
      }    
    );
  }