export const API_URL = process.env.REACT_APP_BACK_URL;

export const getImageLink = (image) => {
    return API_URL+ image;
}

export const getImagePartenaireLink = (image) => {
    return API_URL + "/images/partenaire/" + image;
}

export const getImageSponsorLink = (image) => {
    return API_URL + "/images/sponsors/" + image;
}

export const getImageLogoOrganismeLink = (image) => {
    return API_URL + "/images/organisme/logo/" + image;
}

export const getImageCachetOrganismeLink = (image) => {
    return API_URL + "/images/organisme/cachet/" + image;
}