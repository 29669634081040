import Axios from "axios";
import { EVENEMENTS_API, ORGANISATEURS_API, PDF_ORG_GENERATION_API } from "../../services/config";
import authHeader from "../../helpers/auth-header.js";
import { base64ToBlob } from "./PdfActions";

export const FETCH_ORGANISATEURS= "FETCH_ORGANISATEURS";
export const ADD_ORGANISATEUR_SUCCESS= "ADD_ORGANISATEUR_SUCCESS";
export const ADD_ORGANISATEUR_FAIL= "ADD_ORGANISATEUR_FAIL";
export const ADD_ORGANISATEUR_FAIL_DESC= "ADD_ORGANISATEUR_FAIL_DESC";
export const UPDATE_ORGANISATEUR_SUCCESS= "UPDATE_ORGANISATEUR_SUCCESS";
export const UPDATE_ORGANISATEUR_FAIL= "UPDATE_ORGANISATEUR_FAIL";
export const UPDATE_ORGANISATEUR_FAIL_DESC= "UPDATE_ORGANISATEUR_FAIL_DESC";
export const FETCH_CURRENT_ORGANISATEUR= "FETCH_CURRENT_ORGANISATEUR";
export const DELETE_ORGANISATEUR= "DELETE_ORGANISATEUR";
export const FETCH_PDF_SUCCESS= "FETCH_PDF_SUCCESS";
export const UPDATE_ORGANISATEUR_CHECK= "UPDATE_ORGANISATEUR_CHECK";

export const getOrganisateurs = (selectedEventId) => (dispatch) => {

  return Axios.get(EVENEMENTS_API + "/" + selectedEventId + "/organisateurs", { headers: authHeader() }).then(
    (data) => {
      
      dispatch({
        type: FETCH_ORGANISATEURS,
        organisateurs: data.data['hydra:member']
              });
      return Promise.resolve();
    },
    (error) => {

          throw new Error(error);
     
    }    
  );
  

};

export const getOrganisateurById = (id) => (dispatch) => {

    return Axios.get(ORGANISATEURS_API+ "/" + id,{ headers: authHeader() })
  .then(
    (data) => {
     
      dispatch({
        type: FETCH_CURRENT_ORGANISATEUR,
        currentOrganisateur: data.data
      });
  
      return Promise.resolve();
    },
  
    (error) => {
        throw new Error(error)       
    }    
  );
  
  };

export const addOrganisateur = (organisateur, evenement) => (dispatch) => {
    
  let dateInscription= new Date().toLocaleString("en-US", {timeZone: "Africa/Algiers"});

    if(evenement){
        organisateur.evenement= "/api/evenements/"+ evenement.id;
    }

    organisateur.evenement= "/api/evenements/" + evenement.id;
    organisateur.dateInscription= dateInscription;
    organisateur.dateValidation= organisateur.checked ? dateInscription  : null
    organisateur.type= "organisateur";
  
    return Axios.post(ORGANISATEURS_API, organisateur, {headers: authHeader()}).then(
      (data) => {
  
        let organisateur= data.data;

        dispatch({
          type: ADD_ORGANISATEUR_SUCCESS,
          currentOrganisateur: organisateur
                });
        
        dispatch({
          type: ADD_ORGANISATEUR_FAIL,
          addOrganisateurErrors: null
                });
        return Promise.resolve();
      },
      (error) => {
  
        const message =error.response.data;
        const {violations} = message;
        const errorDescription = message["hydra:description"];
        if(violations){
  
          dispatch({
            type: ADD_ORGANISATEUR_FAIL,
            addOrganisateurErrors: violations
                  });
                  throw new Error(error);
        } else if(errorDescription){
          dispatch({
            type: ADD_ORGANISATEUR_FAIL_DESC,
            addOrganisateurErrorsDesc: errorDescription
                  });
                  throw new Error(error);
        } else{
          throw new Error(error)
        }
       
      }    
    );
  
  };

  export const addOrateur = (organisateur, evenement) => (dispatch) => {
    
    let dateInscription= new Date().toLocaleString("en-US", {timeZone: "Africa/Algiers"});
  
      if(evenement){
          organisateur.evenement= "/api/evenements/"+ evenement.id;
      }
  
      organisateur.evenement= "/api/evenements/" + evenement.id;
      organisateur.dateInscription= dateInscription;
      organisateur.dateValidation= organisateur.checked ? dateInscription  : null
      organisateur.type= "orateur";
    
      return Axios.post(ORGANISATEURS_API, organisateur, {headers: authHeader()}).then(
        (data) => {
    
          let organisateur= data.data;

          dispatch({
            type: ADD_ORGANISATEUR_SUCCESS,
            currentOrganisateur: organisateur
                  });
          
          dispatch({
            type: ADD_ORGANISATEUR_FAIL,
            addOrganisateurErrors: null
                  });
          return Promise.resolve();
        },
        (error) => {
    
          const message =error.response.data;
          const {violations} = message;
          const errorDescription = message["hydra:description"];
          if(violations){
    
            dispatch({
              type: ADD_ORGANISATEUR_FAIL,
              addOrganisateurErrors: violations
                    });
                    throw new Error(error);
          } else if(errorDescription){
            dispatch({
              type: ADD_ORGANISATEUR_FAIL_DESC,
              addOrganisateurErrorsDesc: errorDescription
                    });
                    throw new Error(error);
          } else{
            throw new Error(error)
          }
         
        }    
      );
    
    };


  export const updateOrganisateur = (id, organisateur, evenement) => (dispatch) => {

    if(evenement){
        organisateur.evenement= "/api/evenements/"+ evenement.id;
    }

    if(organisateur.checked){
      let dateValidation= new Date().toLocaleString("en-US", {timeZone: "Africa/Algiers"});
      organisateur.dateValidation= dateValidation;
      organisateur.checked= true;
    }else {
      organisateur.dateValidation= null;
      organisateur.checked= null;
    }
  
    return Axios.put(ORGANISATEURS_API + "/" + id, organisateur, {headers: authHeader()}).then(
      (data) => {
  
        let organisateur= data.data;
  
        dispatch({
          type: UPDATE_ORGANISATEUR_SUCCESS,
          currentOrganisateur: organisateur
                });
        
        dispatch({
          type: UPDATE_ORGANISATEUR_FAIL,
          updateOrganisateurErrors: null
                });
        return Promise.resolve();
      },
      (error) => {
  
        const message =error.response.data;
        const {violations} = message;
        const errorDescription = message["hydra:description"];
        if(violations){
  
          dispatch({
            type: UPDATE_ORGANISATEUR_FAIL,
            updateOrganisateurErrors: violations
                  });
                  throw new Error(error);
        } else if(errorDescription){
          dispatch({
            type: UPDATE_ORGANISATEUR_FAIL_DESC,
            updateOrganisateurErrorsDesc: errorDescription
                  });
                  throw new Error(error);
        } else{
          throw new Error(error)
        }
       
      }    
    );
  
  };

  export const updateCheckInOrg = (id, tmpChecked) => (dispatch) => {

    let dateValidation;
    let checked;
    if(tmpChecked){
        dateValidation= new Date().toLocaleString("en-US", {timeZone: "Africa/Algiers"});
        checked= true
    }else {
        dateValidation= null;
        checked= false;
    }
  
    return Axios.put(ORGANISATEURS_API + "/" + id, {"dateValidation": dateValidation, "checked": checked },
     {headers: authHeader()}).then(
      (data) => {
  
        let organisateur= data.data;
  
        dispatch({
          type: UPDATE_ORGANISATEUR_CHECK,
          idOrganisateur: organisateur.id,
          checked: organisateur.checked
                });
        
        dispatch({
          type: UPDATE_ORGANISATEUR_FAIL,
          updateOrganisateurErrors: null
                });
        return Promise.resolve();
      },
      (error) => {
  
        const message =error.response.data;
        const {violations} = message;
        const errorDescription = message["hydra:description"];
        if(violations){
  
          dispatch({
            type: UPDATE_ORGANISATEUR_FAIL,
            updateOrganisateurErrors: violations
                  });
                  throw new Error(error);
        } else if(errorDescription){
          dispatch({
            type: UPDATE_ORGANISATEUR_FAIL_DESC,
            updateOrganisateurErrorsDesc: errorDescription
                  });
                  throw new Error(error);
        } else{
          throw new Error(error)
        }
       
      }    
    );
  
  };

  export const deleteOrganisateur = (id) => (dispatch) => {
    
    return Axios.delete(ORGANISATEURS_API+"/" + id, {headers: authHeader()}).then(
      (data) => {
  
        dispatch({
          type: DELETE_ORGANISATEUR,
          id
        });
        
        return Promise.resolve();
      },
      (error) => {
          throw new Error(error)
      }    
    );
  
  };


  export const clearMsgAddOrg = () => (dispatch) => {

    dispatch({
      type: ADD_ORGANISATEUR_FAIL,
      addOrganisateurErrors: null
            });
  
    dispatch({
      type: ADD_ORGANISATEUR_FAIL_DESC,
      addOrganisateurErrorsDesc: null
            });
  
    dispatch({
      type: UPDATE_ORGANISATEUR_FAIL,
      updateOrganisateurErrors: null
            });
        
    dispatch({
      type: UPDATE_ORGANISATEUR_FAIL_DESC,
      updateOrganisateurErrorsDesc: null
            });
  
    return Promise.resolve();
  
  };

  export const printOrgPdf = (idOrganisateur, idEvenement) => (dispatch) => {

    let formData = new FormData();
    formData.append('idOrganisateur', idOrganisateur);
    formData.append('idEvenement', idEvenement);
  
    return Axios.post(PDF_ORG_GENERATION_API, formData, {headers: authHeader()}).then(
      (data) => {
  
        
        dispatch({
          type: FETCH_PDF_SUCCESS,
          currentPdf: data.data
        });
  
  
        let pdf= data.data.pdf;
  
        const pdfBlob = base64ToBlob(pdf, 'application/pdf');
        const pdfURL = URL.createObjectURL(pdfBlob);
  
        let iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = pdfURL;
  
        iframe.onload = function() {
          setTimeout(function() {
            iframe.focus();
            iframe.contentWindow.print();
            URL.revokeObjectURL(pdfURL); // Libère la mémoire utilisée par l'URL du blob
          }, 1000); // Attend une seconde pour s'assurer que le contenu de l'iframe est chargé
        };
  
        document.body.appendChild(iframe);
  
        return Promise.resolve();
      },
      (error) => {
  
          throw new Error(error)
      }    
    );
    
  };
  
  export const printOrgPdfBrowser = (idOrganisateur, idEvenement) => (dispatch) => {

    let formData = new FormData();
    formData.append('idOrganisateur', idOrganisateur);
    formData.append('idEvenement', idEvenement);
  
    return Axios.post(PDF_ORG_GENERATION_API, formData, {headers: authHeader()}).then(
      (data) => {
  
        
        dispatch({
          type: FETCH_PDF_SUCCESS,
          currentPdf: data.data
        });
  
  
        let pdf= data.data.pdf;
  
        const pdfBlob = base64ToBlob(pdf, 'application/pdf');
        
        const pdfURL = URL.createObjectURL(pdfBlob);
        window.open(pdfURL, '_blank');
  
        return Promise.resolve();
      },
      (error) => {
  
          throw new Error(error)
      }    
    );
  
  }
  
  export const downloadOrgPdf = (idOrganisateur, idEvenement) => (dispatch) => {
  
    let formData = new FormData();
    formData.append('idOrganisateur', idOrganisateur);
    formData.append('idEvenement', idEvenement);

    return Axios.post(PDF_ORG_GENERATION_API, formData, {headers: authHeader()}).then(
      (data) => {
  
        
        dispatch({
          type: FETCH_PDF_SUCCESS,
          currentPdf: data.data
        });
  
  
        let pdf= data.data.pdf;
  
        const pdfBlob = base64ToBlob(pdf, 'application/pdf');
        
        const linkSource = `data:application/pdf;base64,${pdf}`;
        const downloadLink = document.createElement("a");
        const fileName = "badge-org-" + idOrganisateur + ".pdf";
  
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
  
        return Promise.resolve();
      },
      (error) => {
  
          throw new Error(error)
      }    
    );
  }