import Axios from "axios";
import authHeader from "../../helpers/auth-header.js";
import { EVENEMENTS_API, PDF_SPO_GENERATION_API, SPONSORS_API } from "../../services/config";
import { base64ToBlob } from "./PdfActions.jsx";

export const FETCH_SPONSORS= "FETCH_SPONSORS";
export const FETCH_CURRENT_SPONSOR= "FETCH_CURRENT_SPONSOR";
export const ADD_SPONSOR_SUCCESS= "ADD_SPONSOR_SUCCESS";
export const ADD_SPONSOR_FAIL= "ADD_SPONSOR_FAIL";
export const ADD_SPONSOR_FAIL_DESC= "ADD_SPONSOR_FAIL_DESC";
export const UPDATE_SPONSOR_SUCCESS= "UPDATE_SPONSOR_SUCCESS";
export const UPDATE_SPONSOR_FAIL= "UPDATE_SPONSOR_FAIL";
export const UPDATE_SPONSOR_FAIL_DESC= "UPDATE_SPONSOR_FAIL_DESC";
export const DELETE_SPONSOR= "DELETE_SPONSOR";
export const FETCH_PDF_SUCCESS= "FETCH_PDF_SUCCESS";

export const getSponsors = (selectedEventId) => (dispatch) => {

  return Axios.get(EVENEMENTS_API + "/" + selectedEventId + "/sponsors", { headers: authHeader() }).then(
    (data) => {
      
      dispatch({
        type: FETCH_SPONSORS,
        sponsors: data.data['hydra:member']
              });
      return Promise.resolve();
    },
    (error) => {

          throw new Error(error);
     
    }    
  );
  
};

export const getSponsorById = (id) => (dispatch) => {

    return Axios.get(SPONSORS_API+ "/" + id,{ headers: authHeader() })
  .then(
    (data) => {
     
      dispatch({
        type: FETCH_CURRENT_SPONSOR,
        currentSponsor: data.data
      });
  
      return Promise.resolve();
    },
  
    (error) => {
        throw new Error(error)       
    }    
  );
  
};

export const addSponsor = (sponsor, evenement, userId, file) => (dispatch) => {
    
      let formData = new FormData();
      formData.append('nom', sponsor.nom);
      formData.append('nombreBadges', sponsor.nombreBadges);

      if(sponsor.isVisibleBadge){
        if(sponsor.isVisibleBadge== true || sponsor.isVisibleBadge== "on" || sponsor.isVisibleBadge== "true"){
          formData.append('isVisibleBadge', "true");
        } else {
          formData.append('isVisibleBadge', "false");
        }
      } else {
        formData.append('isVisibleBadge', "false");
      }

      formData.append('evenement', "/api/evenements/" + evenement.id);
      formData.append('user', "/api/users/" + userId);

      if(file && file !="handleDelete"){
        formData.append('file', file);
      }

      return Axios.post(SPONSORS_API, formData, {headers: authHeader()}).then(
        (data) => {
    
          let sponsor= data.data;
  
          dispatch({
            type: ADD_SPONSOR_SUCCESS,
            currentSponsor: sponsor
                  });
          
          dispatch({
            type: ADD_SPONSOR_FAIL,
            addSponsorErrors: null
                  });
          return Promise.resolve();
        },
        (error) => {
    
          const message =error.response.data;
          const {violations} = message;
          const errorDescription = message["hydra:description"];
          if(violations){
    
            dispatch({
              type: ADD_SPONSOR_FAIL,
              addSponsorErrors: violations
                    });
                    throw new Error(error);
          } else if(errorDescription){
            dispatch({
              type: ADD_SPONSOR_FAIL_DESC,
              addSponsorErrorsDesc: errorDescription
                    });
                    throw new Error(error);
          } else{
            throw new Error(error)
          }
         
        }    
      );
    
};

export const updateSponsor = (id, sponsor, evenement, userId, file) => (dispatch) => {

    let formData = new FormData();
    formData.append('nom', sponsor.nom);
    formData.append('nombreBadges', sponsor.nombreBadges);

    if(sponsor.isVisibleBadge){
      if(sponsor.isVisibleBadge== true || sponsor.isVisibleBadge== "on" || sponsor.isVisibleBadge== "true"){
        formData.append('isVisibleBadge', "true");
      } else {
        formData.append('isVisibleBadge', "false");
      }
    } else {
      formData.append('isVisibleBadge', "false");
    }

    formData.append('evenement', "/api/evenements/" + evenement.id);
    formData.append('user', "/api/users/" + userId);

    if(file && file !="handleDelete"){
      formData.append('file', file);
    }

    return Axios.post(SPONSORS_API + "/" + id, formData, {headers: authHeader()}).then(
      (data) => {
  
        let sponsor= data.data;
  
        dispatch({
          type: UPDATE_SPONSOR_SUCCESS,
          currentSponsor: sponsor
                });
        
        dispatch({
          type: UPDATE_SPONSOR_FAIL,
          updateSponsorErrors: null
                });
        return Promise.resolve();
      },
      (error) => {
  
        const message =error.response.data;
        const {violations} = message;
        const errorDescription = message["hydra:description"];
        if(violations){
  
          dispatch({
            type: UPDATE_SPONSOR_FAIL,
            updateSponsorErrors: violations
                  });
                  throw new Error(error);
        } else if(errorDescription){
          dispatch({
            type: UPDATE_SPONSOR_FAIL_DESC,
            updateSponsorErrorsDesc: errorDescription
                  });
                  throw new Error(error);
        } else{
          throw new Error(error)
        }
       
      }    
    );
  
  };

  export const deleteSponsor = (id) => (dispatch) => {
    
    return Axios.delete(SPONSORS_API+"/" + id, {headers: authHeader()}).then(
      (data) => {
  
        dispatch({
          type: DELETE_SPONSOR,
          id
        });
        
        return Promise.resolve();
      },
      (error) => {
          throw new Error(error)
      }    
    );
  
  };

  export const clearMsgAddSponsor = () => (dispatch) => {

    dispatch({
      type: ADD_SPONSOR_FAIL,
      addSponsorErrors: null
            });
  
    dispatch({
      type: ADD_SPONSOR_FAIL_DESC,
      addSponsorErrorsDesc: null
            });
  
    dispatch({
      type: UPDATE_SPONSOR_FAIL,
      updateSponsorErrors: null
            });
        
    dispatch({
      type: UPDATE_SPONSOR_FAIL_DESC,
      updateSponsorErrorsDesc: null
            });
  
    return Promise.resolve();
  
  };

  export const printSpoPdf = (idSponsor, idEvenement) => (dispatch) => {

    let formData = new FormData();
    formData.append('idSponsor', idSponsor);
    formData.append('idEvenement', idEvenement);
  
    return Axios.post(PDF_SPO_GENERATION_API, formData, {headers: authHeader()}).then(
      (data) => {
  
        
        dispatch({
          type: FETCH_PDF_SUCCESS,
          currentPdf: data.data
        });
  
  
        let pdf= data.data.pdf;
  
        const pdfBlob = base64ToBlob(pdf, 'application/pdf');
        const pdfURL = URL.createObjectURL(pdfBlob);
  
        let iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = pdfURL;
  
        iframe.onload = function() {
          setTimeout(function() {
            iframe.focus();
            iframe.contentWindow.print();
            URL.revokeObjectURL(pdfURL); // Libère la mémoire utilisée par l'URL du blob
          }, 1000); // Attend une seconde pour s'assurer que le contenu de l'iframe est chargé
        };
  
        document.body.appendChild(iframe);
  
        return Promise.resolve();
      },
      (error) => {
  
          throw new Error(error)
      }    
    );
    
  };
  
  export const printSpoPdfBrowser = (idSponsor, idEvenement) => (dispatch) => {

    let formData = new FormData();
    formData.append('idSponsor', idSponsor);
    formData.append('idEvenement', idEvenement);
  
    return Axios.post(PDF_SPO_GENERATION_API, formData, {headers: authHeader()}).then(
      (data) => {
  
        
        dispatch({
          type: FETCH_PDF_SUCCESS,
          currentPdf: data.data
        });
  
  
        let pdf= data.data.pdf;
  
        const pdfBlob = base64ToBlob(pdf, 'application/pdf');
        
        const pdfURL = URL.createObjectURL(pdfBlob);
        window.open(pdfURL, '_blank');
  
        return Promise.resolve();
      },
      (error) => {
  
          throw new Error(error)
      }    
    );
  
  }
  
  export const downloadSpoPdf = (idSponsor, idEvenement) => (dispatch) => {
  
    let formData = new FormData();
    formData.append('idSponsor', idSponsor);
    formData.append('idEvenement', idEvenement);

    return Axios.post(PDF_SPO_GENERATION_API, formData, {headers: authHeader()}).then(
      (data) => {
  
        
        dispatch({
          type: FETCH_PDF_SUCCESS,
          currentPdf: data.data
        });
  
  
        let pdf= data.data.pdf;
  
        const pdfBlob = base64ToBlob(pdf, 'application/pdf');
        
        const linkSource = `data:application/pdf;base64,${pdf}`;
        const downloadLink = document.createElement("a");
        const fileName = "badge-spo-" + idSponsor + ".pdf";
  
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
  
        return Promise.resolve();
      },
      (error) => {
  
          throw new Error(error)
      }    
    );
  }