import React from 'react';

const Pagination = ({currentPage, itemsPerPage, length, onPageChanged}) => {

    const pagesCount= Math.ceil(length / itemsPerPage);
    const pages=[];
    for(let i= 1; i< pagesCount +1; i++){
        pages.push(i);
    }

    return ( 
        <div>
            <ul className="pagination pagination-sm">
                <li className={"page-item" +  (currentPage === 1 && " disabled")}>
                <button className="page-link" onClick={() => onPageChanged(currentPage - 1)}>&laquo;</button>
                </li>

                {pages.map( page => 
                     <li key={page} className={"page-item" + (page === currentPage && " active")}>
                     <button className="page-link" 
                     onClick={() => onPageChanged(page)}>
                         {page}
                    </button>
                     </li>
                     )}
               
                
               <li className={"page-item" +  (currentPage === pagesCount && " disabled")}>
                <burron className="page-link" onClick={() => onPageChanged(currentPage +1)}>&raquo;</burron>
                </li>
            </ul>
        </div>
     );
};
 
Pagination.getData= (items, currentPage, itemsPerPage) => { 
    // D'ou l'ont part (start) pendant combien (itemsperpages)
    const start = currentPage * itemsPerPage -itemsPerPage;
    return items.slice(start, start + itemsPerPage);
}
 
export default Pagination;