import { ADD_PRESSE_FAIL, ADD_PRESSE_FAIL_DESC, ADD_PRESSE_SUCCESS, DELETE_PRESSE, FETCH_CURRENT_PRESSE, FETCH_PRESSES, UPDATE_PRESSE_FAIL, UPDATE_PRESSE_FAIL_DESC, UPDATE_PRESSE_SUCCESS } from "../actions/PresseActions";

  
  const defaultState = {};
  
  export default function (state = defaultState, action) {

    const { type, payload } = action;
    
    switch (type) {

      case FETCH_PRESSES:
        return {
          ...state,
          presses: action.presses
        };

      case FETCH_CURRENT_PRESSE:
        return {
          ...state,
          currentPresse: action.currentPresse
        };

      case ADD_PRESSE_SUCCESS:
        return {
          ...state,
          currentPresse: action.currentPresse
        };
      
      case ADD_PRESSE_FAIL:
        return {
          ...state,
          addPresseErrors: action.addPresseErrors
        };
      
      case ADD_PRESSE_FAIL_DESC:
        return {
          ...state,
          addPresseErrorsDesc: action.addPresseErrorsDesc
        };

      case UPDATE_PRESSE_SUCCESS:
        return {
          ...state,
          currentPresse: action.currentPresse
        };
      
      case UPDATE_PRESSE_FAIL:
        return {
          ...state,
          updatePresseErrors: action.updatePresseErrors
        };
      
      case UPDATE_PRESSE_FAIL_DESC:
        return {
          ...state,
          updatePresseErrorsDesc: action.updatePresseErrorsDesc
        };

      case DELETE_PRESSE:
            const pressesUpdated= state.presses.filter(c  => c.id != action.id);
  
          return {
            ...state,
            presses: pressesUpdated,
          };

      default:
        return state;
    }
  }
  