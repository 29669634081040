import React from 'react';

const Checkbox = ({name, 
                label,
                value,
                checked= false,
                onChange, 
                placeholder= "", 
                required= false,
                error= ""}) => (
                    
            <>
                <input type="checkbox" id={name} name={name} onChange={onChange} checked= {checked} />
                <labele for={name}>{value}</labele>
                <p className="invalid-feedback">
                    {error}
                </p>
            </>
     );

 
export default Checkbox;