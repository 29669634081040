import React from 'react';

const Select = ({name, value, error= "", label, onChange, children, required= false}) => (
        <div className="form-group">
                        <label className="mb-2" htmlFor={name}>
                                <strong>{label}</strong>
                        </label>
                        <select 
                        name={name} 
                        id={name} 
                        onChange={onChange} 
                        value={value}  
                        className={'form-control' + ( error && " is-invalid")}
                        autocomplete="on"
                        required= {required}>
                        {children}
                        </select>
                        <p className="invalid-feedback">
                        {error}
                        </p>
        </div>
);


export default Select;