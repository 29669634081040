// @flow 
import React, { Fragment, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PageTitle from '../../layouts/PageTitle';

import defaultImg from "../../../images/imgEvenetDefault-c.jpg";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getEvents } from '../../../store/actions/EvenementActions';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { formatDate, formatDateEvent } from '../../../helpers/FormatHelpers';
import { getImageLink } from '../../../helpers/ImageHelpers';
import Pagination from '../../components/Pagination';
import HeaderNoAuth from '../../components/HeaderNoAuth';
import FooterPublic from '../../layouts/FooterPublic';

const EvenementsListe = ({getEvents, evenements}) => {
    
    const [currentPage, setCurrentPage]= useState(1);
    const [search, setSearch]= useState("");
    const [loading, setLoading]= useState(true);
    const [evenementsListe, setEvenementsListe]= useState([]);
    const [isDisplayEvent, setIsDisplayEvent]= useState(true);
    const [error, setError]= useState();
    const currentDate = new Date();

    //Get types chambres
    const fetchEvents = async () => {
        try {
            getEvents();
            setLoading(false);
        } catch (error) {
            toast.error("Erreur lors du chargement des événements");
        }
    }


    useEffect(() => {
        fetchEvents();
     }, []);

      //Get types au chargement
    useEffect( () => {
        
        if(evenements){
            if(evenements.length==0){
                setIsDisplayEvent(false);
            }else{
                setEvenementsListe(evenements);
            }
        }
        

    }, [evenements] )

     //Change current page
     const handleChangePage = page => setCurrentPage(page);
    
     //Recherche
     const handleSearch = ({currentTarget}) => { 
         const value= currentTarget.value;
         setSearch(value);
         setCurrentPage(1);
     }
 
     const itemsPerPage =8;
     
     //Filtrage des types en fonction de la recherche
     let filtredEvents;
 
     if(evenementsListe && evenementsListe.length >0){         
          filtredEvents= evenementsListe.filter(
             t =>
             (t.id && t.id.toString().includes(search)) ||
             (t.nom && t.nom.toLowerCase().includes(search.toLowerCase())) ||
             (t.description && t.description.toLowerCase().includes(search.toLowerCase())) ||
             (t.type && t.type.toLowerCase().includes(search.toLowerCase()))
                 
         );
     }
 
     // Au cas ou le résultat de la recherche serait null
     useEffect( () => {
         if(filtredEvents && filtredEvents.length == 0 && search!= ""){
             setError("Désolé, aucun résultat n'a été trouvé");
         }else if (filtredEvents && filtredEvents.length>0 ){
             setError();
         }
     }, [filtredEvents] )
     
     //Pagination
     let paginatedEvents;
     if (filtredEvents && filtredEvents.length >0){
     paginatedEvents= Pagination.getData(filtredEvents, currentPage, itemsPerPage);
     }
 

    return (
        <>
        <HeaderNoAuth />
        <div className='eventPage'>

        <div className="row">
                        <div className="col-lg-12">

                            <div className="card">

                                <div className="eventPageFragment eventListe">
                                    {isDisplayEvent ?
                                    <Fragment>

                                        <Row>
                                        {!loading && (
                                            paginatedEvents && paginatedEvents.map( event =>(
                                                <Col xl='12'>
                                                    <Card>
                                                       
                                                        <Card.Header>
                                                        <Card.Title>{event.nom}</Card.Title>
                                                        </Card.Header>
                                                        <Card.Body>
                                                        <Card.Text>
                                                        {event.lieu &&  "Lieu: " + event.lieu}
                                                        </Card.Text>
                                                        <Card.Text className=' text-dark'>
                                                            <>
                                                                {formatDateEvent(event.date_debut, event.date_fin)} <br></br>
                                                                <span style={{ color: "#C62828"}}>{event.tarif &&  "Tarif d'accès: " + event.tarif + " DA"}</span>
                                                            </>
                                                        </Card.Text>
                                                        <div className='cardActions mt-3'>
                                                            {currentDate.getTime() - new Date(event.date_debut).getTime() > 45 * 24 * 60 * 60 * 1000 ? (
                                                                <p>Inscriptions clôturées</p>
                                                            ): (
                                                                <Link to={"/" + event.id + "/"} className="btn btn-primary btn-lg">S'inscrire</Link>
                                                            )}
                                                        </div>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                
                                            )
                                            )
                                            



                                        )}

                                        </Row>

                                        {/* {loading && (<TableLoader></TableLoader>) } */}

                                        {filtredEvents ? filtredEvents.length > itemsPerPage && ( 
                                        <Pagination currentPage={currentPage} 
                                        itemsPerPage={itemsPerPage} 
                                        length={filtredEvents.length} 
                                        onPageChanged={handleChangePage} />) 
                                        : null}

                                    </Fragment>
                                    :
                                    <h4>Aucun événement existant</h4>
                                    }
                                </div>

                            </div>
                        </div>
        </div>

        </div>

        <FooterPublic />

        </>

    );
};

Pagination.getData= (items, currentPage, itemsPerPage) => { 
    // D'ou l'ont part (start) pendant combien (itemsperpages)
    const start = currentPage * itemsPerPage -itemsPerPage;
    return items.slice(start, start + itemsPerPage);
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: "",
        evenements: state.evenementState.evenements

    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        { getEvents },
        dispatch,
    );
};


export default connect(mapStateToProps, mapDispatchToProps)(EvenementsListe); 
