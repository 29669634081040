import Axios from "axios";
import { COUNT_BEST_SPECIALITE_API, COUNT_BEST_VILLE_API, COUNT_CHECKED_INVITES_API, COUNT_INVITES_API, COUNT_ORATEURS_API, COUNT_ORGANISATEURS_API, COUNT_PAID_INVITES_API, COUNT_PRESSES_API, COUNT_SPECIALITES_API, COUNT_SPONSORS_API, STAT_INSCRIPTIONS_API } from "../../services/config";
import authHeader from "../../helpers/auth-header";

export const FETCH_INVITES_COUNT= "FETCH_INVITES_COUNT";
export const FETCH_INVITES_CHECKED_COUNT= "FETCH_INVITES_CHECKED_COUNT";
export const FETCH_INVITES_PAID_COUNT= "FETCH_INVITES_PAID_COUNT";
export const FETCH_ORGANISATEURS_COUNT= "FETCH_ORGANISATEURS_COUNT";
export const FETCH_ORATEURS_COUNT= "FETCH_ORATEURS_COUNT";
export const FETCH_SPONSORS_COUNT= "FETCH_SPONSORS_COUNT";
export const FETCH_PRESSES_COUNT= "FETCH_PRESSES_COUNT";
export const FETCH_SPECIALITES_COUNT= "FETCH_SPECIALITES_COUNT";
export const FETCH_BETTER_SPECIALITES= "FETCH_BETTER_SPECIALITES";
export const FETCH_BETTER_VILLES= "FETCH_BETTER_VILLES";
export const FETCH_STAT_INSCRIPTION= "FETCH_STAT_INSCRIPTION";

export const getCountInvites = (idEvenement) => (dispatch, getState) => {

    let formData = new FormData();
    formData.append('idEvenement', idEvenement);

    return Axios.post(COUNT_INVITES_API, formData, { headers: authHeader() }).then(
      (data) => {
  
        dispatch({
          type: FETCH_INVITES_COUNT,
          countInvites: data.data
        });
  
        return Promise.resolve();
      },
      (error) => {
  
        throw new Error(error);
       
      }
    
    );
  
};

export const getCountCheckedInvites = (idEvenement) => (dispatch, getState) => {

    let formData = new FormData();
    formData.append('idEvenement', idEvenement);

    return Axios.post(COUNT_CHECKED_INVITES_API, formData, { headers: authHeader() }).then(
      (data) => {
  
        dispatch({
          type: FETCH_INVITES_CHECKED_COUNT,
          countCheckedInvites: data.data
        });
  
        return Promise.resolve();
      },
      (error) => {
  
        throw new Error(error);
       
      }
    
    );
  
  };

  export const getCountPaidInvites = (idEvenement) => (dispatch, getState) => {

    let formData = new FormData();
    formData.append('idEvenement', idEvenement);

    return Axios.post(COUNT_PAID_INVITES_API, formData, { headers: authHeader() }).then(
      (data) => {
  
        dispatch({
          type: FETCH_INVITES_PAID_COUNT,
          countPaidInvites: data.data
        });
  
        return Promise.resolve();
      },
      (error) => {
  
        throw new Error(error);
       
      }
    
    );
  
  };
  
  export const getCountOrganisateurs = (idEvenement) => (dispatch, getState) => {

    let formData = new FormData();
    formData.append('idEvenement', idEvenement);

    return Axios.post(COUNT_ORGANISATEURS_API, formData, { headers: authHeader() }).then(
      (data) => {
  
        dispatch({
          type: FETCH_ORGANISATEURS_COUNT,
          countOrganisateurs: data.data
        });
  
        return Promise.resolve();
      },
      (error) => {
  
        throw new Error(error);
       
      }
    
    );
  
};

export const getCountOrateurs = (idEvenement) => (dispatch, getState) => {

  let formData = new FormData();
  formData.append('idEvenement', idEvenement);

  return Axios.post(COUNT_ORATEURS_API, formData, { headers: authHeader() }).then(
    (data) => {

      dispatch({
        type: FETCH_ORATEURS_COUNT,
        countOrateurs: data.data
      });

      return Promise.resolve();
    },
    (error) => {

      throw new Error(error);
     
    }
  
  );

};

export const getCountSponsors = (idEvenement) => (dispatch, getState) => {

  let formData = new FormData();
  formData.append('idEvenement', idEvenement);

  return Axios.post(COUNT_SPONSORS_API, formData, { headers: authHeader() }).then(
    (data) => {

      dispatch({
        type: FETCH_SPONSORS_COUNT,
        countSponsors: data.data
      });

      return Promise.resolve();
    },
    (error) => {

      throw new Error(error);
     
    }
  
  );

};

export const getCountPresses = (idEvenement) => (dispatch, getState) => {

  let formData = new FormData();
  formData.append('idEvenement', idEvenement);

  return Axios.post(COUNT_PRESSES_API, formData, { headers: authHeader() }).then(
    (data) => {

      dispatch({
        type: FETCH_PRESSES_COUNT,
        countPresses: data.data
      });

      return Promise.resolve();
    },
    (error) => {

      throw new Error(error);
     
    }
  
  );

};

export const getCountSpecialites = (idEvenement) => (dispatch, getState) => {

  let formData = new FormData();
  formData.append('idEvenement', idEvenement);

  return Axios.post(COUNT_SPECIALITES_API, formData, { headers: authHeader() }).then(
    (data) => {

      dispatch({
        type: FETCH_SPECIALITES_COUNT,
        countSpecialites: data.data
      });

      return Promise.resolve();
    },
    (error) => {

      throw new Error(error);
     
    }
  
  );

};

export const getBetterSpecialites = (idEvenement) => (dispatch) => {

  let formData = new FormData();
  formData.append('idEvenement', idEvenement);

  return Axios.post(COUNT_BEST_SPECIALITE_API, formData, { headers: authHeader() }).then(
    (data) => {
      
      dispatch({
          type: FETCH_BETTER_SPECIALITES,
          betterSpecialites: data.data['hydra:member']
                });

      return Promise.resolve();
    },
    (error) => {

          throw new Error(error);
     
    }    
  );

};

export const getBetterVilles = (idEvenement) => (dispatch) => {

  let formData = new FormData();
  formData.append('idEvenement', idEvenement);

  return Axios.post(COUNT_BEST_VILLE_API, formData, { headers: authHeader() }).then(
    (data) => {
      
      dispatch({
          type: FETCH_BETTER_VILLES,
          betterVilles: data.data['hydra:member']
                });

      return Promise.resolve();
    },
    (error) => {

          throw new Error(error);
     
    }    
  );
  }

  export const getStatInscription = (idEvenement) => (dispatch) => {

  let formData = new FormData();
  formData.append('idEvenement', idEvenement);

  return Axios.post(STAT_INSCRIPTIONS_API, formData, { headers: authHeader() }).then(
    (data) => {
      
      dispatch({
          type: FETCH_STAT_INSCRIPTION,
          statInscription: data.data['hydra:member']
                });

      return Promise.resolve();
    },
    (error) => {

          throw new Error(error);
     
    }    
  );


};