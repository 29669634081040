// @flow 
import React,{useState, useEffect, useRef, useLayoutEffect} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Navigate, Link, useParams, useLocation } from 'react-router-dom';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { getEventById } from '../../../store/actions/EvenementActions';
import { getSponsorById, addSponsor, updateSponsor, clearMsgAddSponsor } from '../../../store/actions/SponsorActions';
import logo from "../../../images/logo-forum.png";
import Field from '../../components/Forms/Field';
import Select from "../../components/Forms/Select";
import WilayaOfAlgeria from "../../../helpers/Wilaya_Of_Algeria.json";
import listeSpecialites from "../../../helpers/Liste_specialites.json"; 
import listeTitres from "../../../helpers/Titre_medecin.json";  
import HeaderNoAuth from '../../components/HeaderNoAuth';
import swal from 'sweetalert';
import { printSpoPdf, printSpoPdfBrowser, downloadSpoPdf } from '../../../store/actions/SponsorActions';
import { getImageSponsorLink } from '../../../helpers/ImageHelpers';

const Sponsor = ({getEventById, 
    addSponsor, 
    updateSponsor, 
    clearMsgAddSponsor, 
    getSponsorById,
    currentEvenement, 
    userId,
    printSpoPdf,
    printSpoPdfBrowser,
    downloadSpoPdf,
    currentSponsor,
    addSponsorErrors,
    addSponsorErrorsDesc,
    updateSponsorErrors,
    updateSponsorErrorsDesc
    }) => {

    const [event, setEvent]= useState({});
    const [editing, setEditing]= useState(false); 
    const [loading, setLoading]= useState(true);
    const [isPrintable, setIsprintable]= useState(false);
    const [file, setFile]= useState();

    const [credentials, setCredentials]= useState({
        nom: "",
        nombreBadges: 1,
        isVisibleBadge: false,
        image: ""
      })

    const [errors, setErrors]= useState({
        nom: "",
        nombreBadges: "",
        isVisibleBadge: "",
        image: ""
    });

    const optionsWilaya = WilayaOfAlgeria;
    const params = useParams();
    let {id}= params;
    let {idSponsor= "new"}= params;

    //Get types au chargement
    useEffect( () => {
        if(idSponsor != "new"){
            setEditing(true);
            getSponsorById(idSponsor);
            setIsprintable(true);
        }else{
            setEditing(false);
        }
    }, [idSponsor] )

    useEffect( () => {

        const fetchData = async () => 
        {
            if(currentSponsor && idSponsor != "new"){
                setLoading(true);
                currentSponsor.nombreBadges= currentSponsor.nombre_badges;

                if(currentSponsor.isVisibleBadge){
                    if(currentSponsor.isVisibleBadge == "true"){
                        currentSponsor.isVisibleBadge= true;
                    } else {
                        currentSponsor.isVisibleBadge= false;
                    }
                } else {
                    currentSponsor.isVisibleBadge= false;
                }

                setCredentials(currentSponsor);
            }
            setLoading(false);
        }

        fetchData();
        
    }, [currentSponsor] )

    useEffect( () => {
        if(addSponsorErrors){
               const apiErrors={};
               const violations=addSponsorErrors;
               if(violations && Array.isArray(violations)){
                violations.forEach(({propertyPath, message}) => { 
                  apiErrors[propertyPath]= message;
                  } );
                  setErrors(apiErrors);
               }
                      
        }
      
      }, [addSponsorErrors])

    useEffect( () => {
        if(addSponsorErrorsDesc){
            swal("Impossible de vous inscrire", addSponsorErrorsDesc, "error")
        }
      
      }, [addSponsorErrorsDesc])

    useEffect( () => {
        if(updateSponsorErrors){
               const apiErrors={};
               const violations=updateSponsorErrors;
               if(violations && Array.isArray(violations)){
                violations.forEach(({propertyPath, message}) => { 
                  apiErrors[propertyPath]= message;
                  } );
                  setErrors(apiErrors);
               }
                      
        }
      
      }, [updateSponsorErrors])

    useEffect( () => {
        if(updateSponsorErrorsDesc){
            swal("Impossible de vous inscrire", updateSponsorErrorsDesc, "error")
        }
      
      }, [updateSponsorErrorsDesc])
 
    useEffect( () => {  

        if(currentEvenement){
               setEvent(currentEvenement); 
        } 

     }, [currentEvenement])

    //Récupération d'un event
    const fetchEvent= async id  => {
        try {
               await getEventById(id);
               if(!editing){
                setLoading(false);
               }
        } catch (error) {
               toast.error("Impossible de charger l'évenement");  
               Navigate("/");
        }
    }

    // Chargement de l'event au chargement du composent ou changement de l'id  
    useEffect( () => {
        setLoading(true);
        fetchEvent(id);
    }, [id])

    const onSignup = async (e) =>{
        e.preventDefault();

        setErrors({});
        clearMsgAddSponsor();

            if(editing){

                try {

                    let sponsor= credentials;
                    await updateSponsor(idSponsor, sponsor, event, userId, file);
                    //if response
                    setErrors({});
                    swal("Le sponsor est à jour", "success");
                }   catch (err) {
                    toast.error("Une erreur est survenue !");
                }

            } else{
                try {

                    let sponsor= credentials;
                    await addSponsor(sponsor, event, userId, file);
                    //if response
                    setErrors({});
                    swal("Le sponsor est bien enregistré", "success");
                    setIsprintable(true);
                }   catch (err) {
                    toast.error("Une erreur est survenue !");
    
                }
            }

    }

    //Gestion des champs
    const handleChangeFields = ({currentTarget}) =>{
        const {value, name}= currentTarget;
        if(name === "file"){
            setFile(document.querySelector('input[type="file"]').files[0]);
        } else if(name === "isVisibleBadge") {
            setCredentials({ ...credentials, [name]: !credentials[name] });
        } else {
            setCredentials({ ...credentials, [name]: value });
        }
    }

    useLayoutEffect(() => {
        return () => {
               setErrors({});
               clearMsgAddSponsor();
               setEditing(false);
        }
    }, [])

    const handlePrint = async () => {
        try {
                await printSpoPdf(currentSponsor.id, currentSponsor.evenement.id);
        } catch(err){
            toast.error("Erreur lors du chargement du pdf");
        }
        
    };

    const handlePrintBrowser = async () => {
        try {
                await printSpoPdfBrowser(currentSponsor.id, currentSponsor.evenement.id);
        } catch(err){
            toast.error("Erreur lors du chargement du pdf");
        }
    };

    const handleDownload = async () => {
        try {
                await downloadSpoPdf(currentSponsor.id, currentSponsor.evenement.id);
        } catch(err){
            toast.error("Erreur lors du chargement du pdf");
        }
    };

    return (
        <>
            <div className="row page-titles">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active"><Link to={"/" + id + "/admin/sponsors"}> Sponsors</Link></li>
                    <li className="breadcrumb-item active"><Link to={"#"}> {editing ? "Modification": "Ajout"} </Link></li>
                </ol>
            </div>

            <div className="row tableRow ajoutInvite">
                <div className="col-lg-12">

                    <div className="card">

                        <div className="card-header">
                            <h4 className="card-title">
                            {editing ? "Modification | "
                            : "Ajout de sponsor"}

                            {editing && !loading && currentSponsor &&
                            currentSponsor.nom}

                             </h4>

                                {isPrintable &&
                                <div className="printBtn">
                                    <Link className="btn shadow btn-md sharp me-1 btn-success"
                                    onClick={() => handlePrint()}>
                                        <i class="fas fa-print fa-lg"></i>
                                    </Link>
                                    <Link className="btn btn-primary shadow btn-md sharp me-1" 
                                    onClick={() => handlePrintBrowser()}>
                                        <i class="fas fa-file-pdf fa-lg"></i>
                                    </Link>

                                    <Link className="btn btn-info shadow btn-md sharp me-1" 
                                    onClick={() => handleDownload()}>
                                        <i class="fas fa-file-download fa-lg"></i>
                                    </Link>
                                </div>
                                }

                                <Link to={"/" + id + "/admin/sponsors"} className="btn btn-primary">
                                    <i class="fas fa-undo"  style={{ marginRight: "5px",}}></i>  
                                    Retour à la liste
                                </Link>

                        </div>

                    </div>

                </div>

                <div className='eventPage'>

        <div className="row">
                        <div className="col-lg-12">
                            <div className="card">

                                <div className="eventPageFragment">
                                    {!loading &&
                                    <Container>

                                        <Row>

                                            <Col md="12">
                                            <form onSubmit={onSignup}>
                                                
                                                <Row>

                                                <Col md="6">
                                                    <Field
                                                        name="nom"
                                                        value={credentials.nom}
                                                        onChange={handleChangeFields}
                                                        label= "Dénomination *"
                                                        placeholder="Dénomination"
                                                        error={errors.nom}
                                                        type="text"
                                                        required="true" 
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    <Field
                                                        name="nombreBadges"
                                                        value={credentials.nombreBadges}
                                                        onChange={handleChangeFields}
                                                        label= "Nombre de badges *"
                                                        placeholder="Nombre de badges"
                                                        error={errors.nombreBadges}
                                                        type="number"
                                                        required="true" 
                                                        />
                                                </Col>

                                                <Col md="6">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    name="isVisibleBadge"
                                                    checked= {credentials.isVisibleBadge}
                                                    onChange={handleChangeFields}
                                                    id="isVisibleBadge"
                                                />
                                                <label className="form-check-label" htmlFor="isVisibleBadge">
                                                    Visible sur le badge ?
                                                </label>
                                                </Col>

                                                <Col md="6">
                                                <div class="uploader">
              

                                                    <label for="file-upload" id="file-drag">
                                                        <div id="start">
                                                        {credentials.image &&
                                                            <img src={getImageSponsorLink(credentials.image)} className="svgImage" />
                                                        }
                                                        
                                                            <div className="importText">
                                                                {!credentials.image ? <p>Importez le logo</p>
                                                                :
                                                                <>
                                                                    <p>Cliquez sur choisir un fichier pour changer de logo</p>
                                                                </>
                                                                }
                                                            </div>
                                                                <input 
                                                                type="file" 
                                                                id="file" 
                                                                name="file" 
                                                                accept="image/*" 
                                                                onChange={handleChangeFields} />

                                                        </div>
                                                    </label>
                                                 </div>
                                                </Col>

                                                </Row>

                                                <div className="text-center">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary btn-lg"
                                                    >
                                                        {editing ? "Enregistrer" : "Ajouter"}
                                                    </button>
                                                </div>
                                            </form>
                                            </Col>

                                        </Row>

                                    </Container>
                                    }
                                    
                                    
                                </div>

                            </div>
                        </div>
        </div>

        </div>

            </div>

        </>
    );
};

const mapStateToProps = (state) => {
    return {
        evenements: state.evenementState.evenements,
        currentEvenement: state.evenementState.currentEvenement,
        addSponsorErrors: state.sponsorState.addSponsorErrors,
        addSponsorErrorsDesc: state.sponsorState.addSponsorErrorsDesc,
        updateSponsorErrors: state.sponsorState.updateSponsorErrors,
        updateSponsorErrorsDesc: state.sponsorState.updateSponsorErrorsDesc,
        userId: state.auth.user.id,
        currentSponsor: state.sponsorState.currentSponsor
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        { getEventById, 
        addSponsor, 
        updateSponsor, 
        clearMsgAddSponsor, 
        getSponsorById,
        printSpoPdf,
        printSpoPdfBrowser,
        downloadSpoPdf },
        dispatch,
    );
};


export default connect(mapStateToProps, mapDispatchToProps)(Sponsor); 