import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const PaginationTable = ({currentPage, itemsPerPage, length, onPageChanged, maxButtons=10}) => {

    var screen_width = document.documentElement.clientWidth;
    if(screen_width< 600){
        maxButtons=3;
    }
    
    const pagesCount= Math.ceil(length / itemsPerPage);
    const pages=[];
    /*
    for(let i= 1; i< pagesCount +1; i++){
        pages.push(i);
    }
    */
    if(currentPage > maxButtons+1){
        pages.push(1);
        pages.push("...");
    }
    for(let i= currentPage; i< currentPage+maxButtons; i++){
        if(i < pagesCount +1){
            pages.push(i);
        }
    }

    if(currentPage < pagesCount-10){
        pages.push("..");
        pages.push(pagesCount);
    }

    return ( 
        <div className='dataTables_paginate paging_simple_numbers mt-5' id='example5_paginate'>

                <Button className={( currentPage !== 1 && 'paginate_button previous')} onClick={() => onPageChanged(currentPage - 1)} disabled={currentPage === 1}>
					Prec
				</Button>

                <span>
					{pages.map((page, i) => (
					    <Button key={i} className={"paginate_button"  + (page !== currentPage && " btn-success")} 
                        onClick={page != "..." && page != ".." ? () => onPageChanged(page): 
                        page === "..." ?
                        () => onPageChanged(currentPage-maxButtons):
                        () => onPageChanged(currentPage+maxButtons)
                        }>
							{page}
						</Button>
					))}
			    </span>

                <Button className={(!(currentPage === pagesCount) && 'paginate_button next')} onClick={() => onPageChanged(currentPage +1)} disabled={currentPage === pagesCount}>
					Suiv
				</Button>
                
        </div>
     );
};
 
PaginationTable.getData= (items, currentPage, itemsPerPage) => { 
    // D'ou l'ont part (start) pendant combien (itemsperpages)
    const start = currentPage * itemsPerPage -itemsPerPage;
    return items.slice(start, start + itemsPerPage);
}
 
export default PaginationTable;