export const MenuList = [
    //Dashboard
    {
        title: 'Tableau de bord',	
        to: "",
        classsChange: 'mm-collapse',		
        iconStyle: <i className="flaticon-381-add-1" />,
    },
    {
        title : "Visiteurs",
        classsChange: 'mm-collapse',
        to:'visiteurs',
        iconStyle: <i className="flaticon-381-id-card-2" />,
    },
    {
        title : "Organisateurs",
        classsChange: 'mm-collapse',
        to:'organisateurs',
        iconStyle: <i className="flaticon-381-user" />,
    },
    {
        title : "Orateurs",
        classsChange: 'mm-collapse',
        to:'orateurs',
        iconStyle: <i className="flaticon-381-microphone" />,
    },
    {
        title : "Sponsors",
        classsChange: 'mm-collapse',
        to:'sponsors',
        iconStyle: <i className="flaticon-381-briefcase" />,
    },
    {
        title: 'Presse',	
        classsChange: 'mm-collapse',
        to:'presses',
        iconStyle: <i className="flaticon-381-video-player" />,
    },
    {
        title: 'Évenements',	
        classsChange: 'mm-collapse',
        to:'events',
        iconStyle: <i className="flaticon-381-newspaper" />,
    },
    {
        title: "Partenaire",	
        classsChange: 'mm-collapse',
        to:'partenaire',
        iconStyle: <i className="flaticon-381-location-2" />,
    },
    {
        title: "Ateliers",	
        classsChange: 'mm-collapse',
        to:'ateliers',
        iconStyle: <i className="flaticon-381-television" />,
    },
    {
        title: "Attestations",	
        classsChange: 'mm-collapse',
        to:'attestations',
        iconStyle: <i className="flaticon-381-bookmark" />,
    },
    {
        title: 'Utilisateurs',	
        classsChange: 'mm-collapse',
        to: 'users',
        iconStyle: <i className="flaticon-381-user-9" />,
    },
    {
        title: "Adhérents",	
        classsChange: 'mm-collapse',
        to:'adherents',
        iconStyle: <i className="flaticon-381-resume" />,
    },
    {
        title: "Informations",	
        classsChange: 'mm-collapse',
        to:'organisme',
        iconStyle: <i className="flaticon-381-layer" />,
    }
    
]